import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next'; // Import the translation hook
import DefaultLayout from '@/layout/DefaultLayout';
import Breadcrumb from '@/components/Breadcrumb';
import { default as DefaultInput } from '@/components/Input';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useQuery } from '@tanstack/react-query';
import { getCompanyId } from '@/services/AuthService';
import { Team, getTeam, saveTeam } from '@/services/TeamService';
import Spinner from '@/components/Spinner';
import { useNavigate, useParams } from 'react-router-dom';
import Dropdown from '@/components/DropDown';
import Select from 'react-select';

import {
  Employee,
  EmployeesResponse,
  getEmployees,
  getEmployeesByTeamId,
  updateEmployeeTeam,
} from '@/services/EmployeeService';
import MutliSelectDropdown from '@/components/MutliSelectDropdown';
import { updateUserRole } from '@/services/UserService';
import { UserRoles } from '@/enums/UsersRole';
import { Branch, getBranches } from '@/services/BranchService';
import { Departement } from '@/services/DepartementService';
import Branches from '../Branch';
import { toast } from 'react-hot-toast';
import FormButtons from '@/components/shared/FormButtons';

// Translations for Yup validation

interface FormValues extends Team {
  title: string;
  branchId?: string;
}
interface Options {
  label: string;
  value: string;
}
//5e9ace0a-f403-4bf2-beeb-4559e682e419
function TeamForm() {
  const { t } = useTranslation(); // Hook for translations
  const [selectedEmployees, setSelectedEmployees] = useState<Options[]>([]);
  const { id } = useParams();
  const editForm = id !== 'new';
  const [departements, setDepartements] = React.useState<Departement[]>([]);
  const [branches, setBranches] = React.useState<Branch[]>([]);
  const [employeesWithNoTeam, setEmployeesWithNoTeam] = useState<Employee[]>(
    []
  );
  const [employeesWithTeam, setEmployeesWithTeam] = useState<Employee[]>([]);
  const [employeesOptions, setEmployeesOptions] = useState<Options[]>([]);
  const [formikValuesState, setFormikValuesState] = useState<FormValues>({
    title: '',
    id: '',
    teamLeaderId: '',
    departmentId: null,
    companyId: '',
    teamLeadReplacementId: '',
  });
  const [loading, setLoading] = useState(false);
  const formRef = useRef(null); // Ref for accessing Formik methods
  const navigate = useNavigate();
  // Function to validate teamLeaderId field

  const teamFormValidationSchema = Yup.object().shape({
    title: Yup.string().required('teamForm.titlerequired'),
    teamLeaderId: Yup.string().required(
      'validationMessages.teamLeaderIsRequired'
    ),
  });

  const {
    data: teamData,
    isLoading,
    refetch: refetchTeam,
    isSuccess: isTeamFetchSucccess,
  } = useQuery<Team | undefined>(['team', id], async () => await getTeam(id!), {
    enabled: editForm,
    refetchOnWindowFocus: false,
    staleTime: 8000,
    keepPreviousData: true,
  });
  const {
    data: employeesDataWithNoTeam,
    isLoading: isLoadingEmployees,
    refetch: refetchEmployees,
    isSuccess,
  } = useQuery<EmployeesResponse | undefined>(
    ['employeesWithOutTeam'],
    async () =>
      await getEmployees({
        fromvalue: 0,
        takevalue: 0,
        search: '',
        notTeamMember: true,
      }),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  const {
    data: employeesDataWithTeam,
    isLoading: isLoadingEmployeesWithTeam,
    refetch: refetchEmployeesWithTeam,
    isSuccess: isEmWithTeamSuccess,
  } = useQuery<EmployeesResponse | undefined>(
    ['employeesWithTeam'],
    async () =>
      await getEmployees({
        fromvalue: 0,
        takevalue: 0,
        search: '',
        notTeamMember: false,
      }),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );
  useEffect(() => {
    if (isSuccess && isEmWithTeamSuccess) {
      setEmployeesWithNoTeam(employeesDataWithNoTeam?.employees!);
      setEmployeesWithTeam(employeesDataWithTeam?.employees!);
    }
  }, [isSuccess, isEmWithTeamSuccess]);

  const handleSaveTeam = async (
    values: FormValues,
    { resetForm, validateForm }: any
  ) => {
    setLoading(true);
    const condition = selectedEmployees.filter(
      (x) => x.value === values.teamLeaderId
    );

    if (condition.length > 0) {
      let errorMsg = t('validationMessages.teamLeaderNotInEmployees');
      toast.error(errorMsg, {
        duration: 6000,
      });
      setLoading(false);
      return;
    }
    try {
      const companyId = getCompanyId();
      let team: Team = {
        ...values,
        companyId: companyId!,
        employeeIds: selectedEmployees.map((em) => em.value),
      };

      const teamId = await saveTeam(team);
      //? endopot  list of ids of meployees + team leaderid
      //? if team leader change -> move old to employees
      //? aspnet roles , maay -many
      // change the roles for Employees to employees

      if (teamId) {
        if (editForm) {
          toast.success(
            t('SuccessUpdateMessage', {
              name: t('ToastMsg.Team'),
            })
          );
        } else {
          toast.success(
            t('SuccessMessage', {
              name: t('ToastMsg.Team'),
            })
          );
        }
      }
      if (teamId && editForm) {
        refetchTeam();
      }
      navigate(-1);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
      resetForm();
    }
  };
  // const {
  //   data: branchesData,
  //   isLoading: branchesLoading,
  //   isSuccess: branchesSuccess,
  //   refetch: branchRefetch,
  // } = useQuery(
  //   ['branches'],
  //   async () => await getBranches({ fromvalue: 0, takevalue: 0 }),
  //   {
  //     onSuccess: (data) => {
  //       if (editForm && teamData) {
  //         const selectedBranch = data!.branches.find(
  //           (x) => x.id === teamData.branchId
  //         );

  //         if (selectedBranch) {
  //           setDepartements(selectedBranch.departments ?? []);
  //         } else {
  //           setDepartements([]);
  //         }
  //       }
  //       setBranches(data!.branches);
  //       return data?.branches;
  //     },
  //     staleTime: 0, // Always fetch fresh data

  //     keepPreviousData: true,
  //   }
  // );
  // const handleBranchChange = (event: any, handleChange: any) => {
  //   const selectedBranchId = event.target.value;
  //   const selectedBranchObj = branchesData?.branches.find(
  //     (branch) => branch.id === selectedBranchId
  //   );

  //   if (selectedBranchObj) {
  //     setDepartements(selectedBranchObj.departments! as Departement[]);
  //   } else {
  //     setDepartements([]);
  //   }

  //   handleChange({
  //     target: {
  //       name: 'branchId',
  //       value: selectedBranchId || {},
  //     },
  //   });
  // };

  useEffect(() => {
    if (
      editForm &&
      teamData &&
      // branchesData &&
      // !branchesLoading &&
      employeesDataWithNoTeam
    ) {
      let selectedOptions = teamData
        ?.employees!.filter(
          (x) =>
            x.id !== teamData.teamLeaderId &&
            x.id !== teamData.teamLeadReplacementId
        )
        .map((employee) => ({
          value: employee.id!,
          label: employee.email,
        }));

      setSelectedEmployees(selectedOptions!);
      // Update form values with team data
      setFormikValuesState((prev) => ({ ...prev, ...teamData }));
      setEmployeesWithNoTeam([
        ...teamData.employees!,
        ...employeesDataWithNoTeam?.employees!,
      ]);
      //branchRefetch();
    }
  }, [
    editForm,
    teamData,
    isTeamFetchSucccess,
    // branchesData,
    employeesDataWithNoTeam,
  ]);

  return (
    <DefaultLayout>
      <Breadcrumb
        pageName={editForm ? t('teamForm.editTeam') : t('teamForm.addTeam')}
      />

      {/* Use translation keys for page names */}
      {loading ? (
        <Spinner />
      ) : (
        <Formik
          ref={formRef}
          initialValues={formikValuesState}
          validationSchema={teamFormValidationSchema}
          onSubmit={handleSaveTeam}
          enableReinitialize={editForm}
        >
          {({
            values,
            errors,
            handleChange,
            handleSubmit,
            resetForm,
            setFieldValue,
            validateForm,
            validateField,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="group relative z-0 mb-6 w-full">
                <DefaultInput
                  type="text"
                  name="title"
                  id="title"
                  placeholder=" "
                  onChange={handleChange}
                  errors={errors}
                  value={values.title}
                  label={t('teamForm.title')}
                />
              </div>

              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="group relative z-0 mb-6 w-full">
                  <Dropdown
                    id="teamLeaderId"
                    name="teamLeaderId"
                    optionValue="id"
                    optionLabel="email"
                    onChange={(e) => {
                      setFieldValue('teamLeaderId', e.target.value);
                    }}
                    options={employeesWithTeam ?? []}
                    label={t('teamForm.SelectATeamLeader')}
                    labelClassName="w-[50%]"
                    error={errors.teamLeaderId}
                    value={values.teamLeaderId}
                  />
                </div>
                <div className="group relative z-0 mb-6 w-full">
                  <Dropdown
                    id="teamLeadReplacementId"
                    name="teamLeadReplacementId"
                    optionValue="id"
                    optionLabel="email"
                    labelClassName="w-[50%]"
                    onChange={(e) => {
                      setFieldValue('teamLeadReplacementId', e.target.value);
                    }}
                    options={employeesWithTeam ?? []}
                    label={t('teamForm.selectateamLeadReplacement')}
                    error={errors.teamLeadReplacementId}
                    value={values.teamLeadReplacementId}
                  />
                </div>
              </div>
              {/* <div className="grid hidden md:grid-cols-2 md:gap-6">
                <div className="group relative z-0 mb-6 w-full">
                  <Dropdown
                    id="branchId"
                    name="branchId"
                    optionValue="id"
                    optionLabel="title"
                    onChange={(e) => handleBranchChange(e, handleChange)}
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                    options={branchesData?.branches ?? []}
                    label={t('Employee.branches')}
                    error={errors.branchId}
                    value={values.branchId}
                  />
                </div>
                <div className="group relative z-0 mb-6 w-full">
                  <Dropdown
                    id="departmentId"
                    name="departmentId"
                    optionValue="id"
                    optionLabel="title"
                    onChange={(e) => {
                      setFieldValue('departmentId', e.target.value);
                    }}
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                    options={departements ?? []}
                    label={t('Employee.department')}
                    error={errors.departmentId}
                    value={values.departmentId}
                  />
                </div>
              </div> */}
              {/* Multi-Select for Employees */}
              <div className="group relative z-0 mb-6 w-full">
                <label
                  htmlFor="employees"
                  className={`mb-2 block text-sm font-medium 
                    
                       text-gray-900 dark:text-white
                  `}
                >
                  {t('teamForm.employees')}
                </label>
                <Select
                  id="employees"
                  name="employees"
                  classNames={{
                    control: () => `block w-full rounded-lg border
                    border-gray-300   text-sm
                     text-gray-900 focus:border-blue-500
                      focus:ring-blue-500 dark:border-gray-600
                       dark:bg-gray-700 dark:text-white
                        dark:placeholder-gray-400
                         dark:focus:border-blue-500
                          dark:focus:ring-blue-500 
                          font-bold text-lg dark:text-white
                          `,
                    menu: () => 'bg-white dark:bg-gray-700',
                  }}
                  options={
                    employeesWithNoTeam
                      .filter(
                        (x) =>
                          x.id !== values.teamLeaderId &&
                          x.id !== values.teamLeadReplacementId
                      )
                      .map((employee) => ({
                        value: employee.id,
                        label: employee.email,
                      })) ?? []
                  }
                  isMulti
                  value={selectedEmployees}
                  onChange={(selectedOptions, { action }) => {
                    if (
                      action === 'select-option' ||
                      action === 'remove-value'
                    ) {
                      setSelectedEmployees(selectedOptions as Options[]);
                      setFieldValue(
                        'employees',
                        (selectedOptions as Options[]).map(
                          (option) => option.value
                        )
                      );
                    }
                  }}
                />
              </div>
              <FormButtons resetForm={resetForm} loading={loading} />
            </form>
          )}
        </Formik>
      )}
    </DefaultLayout>
  );
}

export default TeamForm;
