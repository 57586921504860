import React from 'react';
import LogoAnimated from '@/images/logo/animated-logo.svg';
export interface SpinnerProps {
  height?: string;
  width?: string;
  className?: string;
}

const Spinner = ({ height = '10rem', width = '10rem' }: SpinnerProps) => {
  return (
    <div
      role="status"
      className="flex h-full w-full items-center justify-center"
    >
      <LogoAnimated
        style={{
          height: height,
          width: width,
        }}
      />
      <span className="sr-only">Loading...</span>
    </div>
  );
};

export default Spinner;
