import React, { useMemo, useState } from 'react';
import DefaultLayout from '@/layout/DefaultLayout';
import Breadcrumb from '@/components/Breadcrumb';
import DynamicTable, { SelectColumnFilter } from '@/components/DynamicTable';
import Pagination from '@/components/Pagination';
import Spinner from '@/components/Spinner';
import {
  DeleteButton,
  EditButton,
  PrimaryButton,
} from '@/components/shared/Button';
import DeleteConfirmationModal from '@/components/shared/DeleteConfirmationModal';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { UserRoles } from '@/enums/UsersRole';
import {
  EmployeesResponse,
  getEmployeesByTeamLeaderId,
  getEmployees,
} from '@/services/EmployeeService';
import { useNavigate } from 'react-router-dom';
import { siteName } from '@/variables/Urls';

function JobDescriptions() {
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState<string>('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState('');
  // Other necessary hooks...
  const navigate = useNavigate();
  const isDevelopment = import.meta.env.MODE === 'development';

  const { t } = useTranslation();
  const AvatarCell = ({ cell: { value } }: any) => {
    var url = isDevelopment ? siteName + value : value;

    return (
      <div className="w-10">
        <img
          className="h-10 w-10 rounded-full object-cover"
          src={url}
          alt="Profile Avatar"
        />
      </div>
    );
  };

  const ActionButtonsCell = ({ value }: any) => {
    return (
      <div>
        <PrimaryButton
          type="button"
          onClick={() => {
            navigate(`${value}`);
          }}
        >
          {t('JobDescriptions.Details')}
        </PrimaryButton>
      </div>
    );
  };

  const handleCancelDelete = () => {
    setIsModalVisible(false);
  };

  const columns = useMemo(
    () => [
      {
        Header: t('Employees.actionsHeader'),
        accessor: 'jobDescriptionId',
        Cell: ({ cell: { value, row } }: any) => (
          <ActionButtonsCell value={value} />
        ),
      },
      {
        accessor: 'profileImgUrl',
        Cell: AvatarCell,
      },
      {
        Header: t('Employees.firstNameHeader'),
        accessor: 'firstName',
      },
      {
        Header: t('Employees.lastNameHeader'),
        accessor: 'lastName',
        Filter: SelectColumnFilter,
      },

      {
        Header: t('Employees.emailHeader'),
        accessor: 'email',
        Filter: SelectColumnFilter,
      },
      {
        Header: t('Employees.jobTitleHeader'),
        accessor: 'jobTitle',
      },
      {
        Header: t('Employees.departementNameHeader'),
        accessor: 'departementName',
      },
      {
        Header: t('Employees.branchNameHeader'),
        accessor: 'branchName',
      },
      {
        Header: t('Employees.TeamName'),
        accessor: 'teamName',
      },
      {
        Header: t('Employees.TeamLeaderEmail'),
        accessor: 'teamLeaderEmail',
        Cell: ({ cell: { value, row } }: any) => (
          <input
            type="text"
            className="min-w-40 bg-transparent dark:bg-transparent dark:text-bodydark"
            value={value}
            readOnly
          />
        ),
      },
      {
        Header: t('Employees.TeamLeaderName'),
        accessor: 'teamLeaderFullName',
        Cell: ({ cell: { value, row } }: any) => (
          <input
            type="text"
            className="min-w-40 bg-transparent dark:bg-transparent dark:text-bodydark"
            value={value}
            readOnly
          />
        ),
      },
    ],
    [t]
  );
  const {
    isLoading,
    isError,
    data: employees,
  } = useQuery<EmployeesResponse | undefined>(
    ['employees', pageIndex, pageSize, search],
    () => {
      // Fetch all employees
      return getEmployees({
        fromvalue: pageIndex,
        takevalue: pageSize,
        search: search,
      });
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
    }
  );

  const pageCount = useMemo(() => {
    return Math.ceil((employees?.count ?? 0) / pageSize);
  }, [pageSize, employees]);
  const nextPage = () => setPageIndex(pageIndex + 1);
  const previousPage = () => setPageIndex(pageIndex - 1);
  const onPageChange = (newPageIndex: number) => setPageIndex(newPageIndex);
  const onPageSizeChange = (newPageSize: number) => {
    setPageIndex(0);
    setPageSize(newPageSize);
  };

  return (
    <DefaultLayout>
      <Breadcrumb
        pageName={t('JobDescriptions.pageName')}
        addbtn={false}
        addRouter="new"
      />
      {isLoading ? (
        <div className="flex items-center justify-center">
          <Spinner height="3rem" width="3rem" />
        </div>
      ) : (
        <div className="flex flex-col">
          <DynamicTable
            dataCount={employees?.count}
            data={employees?.employees ?? []}
            columns={columns}
            setSearch={setSearch}
            exportPdf={false}
          />

          <Pagination
            pageIndex={pageCount === 0 ? -1 : pageIndex}
            pageCount={pageCount}
            pageSize={pageSize}
            onNextPage={nextPage}
            onPreviousPage={previousPage}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
          />
        </div>
      )}
    </DefaultLayout>
  );
}

export default JobDescriptions;
