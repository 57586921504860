import React, { useEffect, useState } from 'react';
import DefaultLayout from '@/layout/DefaultLayout';
import Breadcrumb from '@/components/Breadcrumb';
import { default as DefaultInput } from '@/components/Input';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Spinner from '@/components/Spinner';
import { toast } from 'react-hot-toast';
import { useQuery } from '@tanstack/react-query';
import {
  DeliverableCodificationDto,
  DeliverableDto,
  getDeliverable,
  saveDeliverable,
} from '@/services/DeliverablesService';
import { EmployeesResponse, getEmployees } from '@/services/EmployeeService';
import Dropdown from '@/components/DropDown';
import ReactDatePickerDefault from '@/components/ReactDatePickerDefault';
import MultiSelect from '@/components/MultiSelect';
import moment from 'moment';
import {
  DeliverableCategory,
  DeliverableCategoryOptions,
} from '@/enums/DeliverableCategory';
import { Priority, PriorityOptions } from '@/enums/Priority';
import {
  DeliverableStatus,
  DeliverableStatusOptions,
} from '@/enums/DeliverableStatus';
import { PlusCircleIcon } from '@heroicons/react/solid';
import DeliverableCodificationsModal from './DeliverableCodificationsModal';
import TextArea from '@/components/TextArea';
import {
  getProjectTeamMembersByProjectId,
  ProjectTeamMemberResponse,
} from '@/services/ProjectTeamMemberService';
import FormButtons from '@/components/shared/FormButtons';

const validationSchema = Yup.object().shape({
  deliverableNumber: Yup.string().required('DeliverableForm.numberRequired'),
  description: Yup.string().required('DeliverableForm.descriptionRequired'),
  responsibleAuthor: Yup.string().required(
    'DeliverableForm.responsibleAuthorRequired'
  ),
  category: Yup.string().required('DeliverableForm.categoryRequired'),
  plannedStartDate: Yup.date().required('DeliverableForm.startDateRequired'),
  plannedEndDate: Yup.date().required('DeliverableForm.endDateRequired'),
  actualStartDate: Yup.date(),
  actualEndDate: Yup.date(),
  estimatedDuration: Yup.number().required(
    'DeliverableForm.estimatedDurationRequired'
  ),
  actualDuration: Yup.number().required(
    'DeliverableForm.actualDurationRequired'
  ),
  progressStatus: Yup.string().required('DeliverableForm.statusRequired'),
  dependencies: Yup.string().required('DeliverableForm.dependenciesRequired'),
  priority: Yup.string().required('DeliverableForm.priorityRequired'),
  estimatedHours: Yup.number().required(
    'DeliverableForm.estimatedHoursRequired'
  ),
  estimatedCost: Yup.number().required('DeliverableForm.estimatedCostRequired'),
  actualHours: Yup.number().required('DeliverableForm.actualHoursRequired'),
  actualCost: Yup.number().required('DeliverableForm.actualCostRequired'),
  status: Yup.string().required('DeliverableForm.statusRequired'),
  risks: Yup.string().required('DeliverableForm.risksRequired'),
  actionPlan: Yup.string().required('DeliverableForm.actionPlanRequired'),
  draftState: Yup.string().required('DeliverableForm.draftStateRequired'),
  verificationState: Yup.string().required(
    'DeliverableForm.verificationStateRequired'
  ),
  approvalState: Yup.string().required('DeliverableForm.approvalStateRequired'),
  observations: Yup.string().required('DeliverableForm.observationsRequired'),
  verifiersIds: Yup.string().required('DeliverableForm.verifiersIdsRequired'),
  approversIds: Yup.string().required('DeliverableForm.approversIdsRequired'),
  codificationJoinerSymbol: Yup.string().required(
    'DeliverableForm.codificationJoinerSymbolRequired'
  ),
});

interface FormValues extends DeliverableDto {}

function DeliverableForm() {
  const { deliverableId, id } = useParams();
  const editForm = deliverableId !== 'new';
  const navigate = useNavigate();

  const { t } = useTranslation();

  const [formikValuesState, setFormikValuesState] = useState<FormValues>({
    deliverableNumber: '',
    description: '',
    responsibleAuthor: '',
    category: DeliverableCategory.AcceptanceDocuments,
    plannedStartDate: new Date(),
    plannedEndDate: new Date(),
    actualStartDate: new Date(),
    actualEndDate: new Date(),
    estimatedDuration: 0,
    actualDuration: 0,
    progressStatus: '',
    comments: '',
    dependencies: '',
    priority: Priority.Medium,
    estimatedHours: 0,
    estimatedCost: 0,
    actualHours: 0,
    actualCost: 0,
    status: DeliverableStatus.InProgress,
    risks: '',
    actionPlan: '',
    draftState: DeliverableStatus.InProgress,
    verificationState: DeliverableStatus.InProgress,
    approvalState: DeliverableStatus.InProgress,
    observations: '',
    verifiersIds: '',
    approversIds: '',
    codificationJoinerSymbol: '',
    deliverableCodifications: [],
    projectId: id!,
    id: '',
  });
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [codingsDeliverables, setCodingsDeliverables] = useState<
    DeliverableCodificationDto[]
  >([]);

  const {
    data: deliverableData,
    isLoading,
    refetch: refetchDeliverable,
  } = useQuery<DeliverableDto | undefined>(
    ['deliverable', deliverableId],
    async () => await getDeliverable(deliverableId!),
    {
      enabled: editForm,
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
      onSuccess: (deliverable) => {
        if (deliverable) {
          setFormikValuesState((prev) => ({ ...prev, ...deliverable }));
        }
      },
    }
  );

  useEffect(() => {
    if (editForm && deliverableData) {
      setFormikValuesState((prev) => ({
        ...prev,
        ...deliverableData,

        plannedStartDate: moment.utc(deliverableData.plannedStartDate).toDate(),
        plannedEndDate: moment.utc(deliverableData.plannedEndDate).toDate(),
        actualStartDate: moment.utc(deliverableData.actualStartDate).toDate(),
        actualEndDate: moment.utc(deliverableData.actualEndDate).toDate(),
      }));

      setCodingsDeliverables(deliverableData.deliverableCodifications);
    }
  }, [editForm, deliverableData]);

  const handleSaveDeliverable = async (
    values: FormValues,
    { resetForm }: any
  ) => {
    setLoading(true);

    try {
      const deliverable: DeliverableDto = {
        ...values,
        deliverableCodifications: codingsDeliverables,
        plannedStartDate: moment.utc(values.plannedStartDate).toDate(),
        plannedEndDate: moment.utc(values.plannedEndDate).toDate(),
        actualStartDate: moment.utc(values.actualStartDate).toDate(),
        actualEndDate: moment.utc(values.actualEndDate).toDate(),
      };

      const data = await saveDeliverable(deliverable);
      if (data) {
        if (editForm) {
          toast.success(
            t('SuccessUpdateMessage', {
              name: t('ToastMsg.Deliverable'),
            })
          );
        } else {
          toast.success(
            t('SuccessMessage', {
              name: t('ToastMsg.Deliverable'),
            })
          );
        }
      }
      if (data && editForm) {
        refetchDeliverable();
      }
      navigate(-1);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
      resetForm();
    }
  };

  const { data: projectTeamMemebers, isLoading: isLoadingEmployees } = useQuery<
    ProjectTeamMemberResponse | undefined
  >(
    ['projectTeamMemebers', id],
    async () =>
      await getProjectTeamMembersByProjectId({
        page: 0,
        take: 0,
        search: '',
        projectId: id!,
      }),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  return (
    <DefaultLayout>
      <Breadcrumb
        pageName={
          editForm
            ? t('DeliverableForm.editDeliverable')
            : t('DeliverableForm.addDeliverable')
        }
      />
      {loading && isLoadingEmployees ? (
        <Spinner />
      ) : (
        <Formik
          initialValues={formikValuesState}
          validationSchema={validationSchema}
          onSubmit={handleSaveDeliverable}
          enableReinitialize={editForm}
        >
          {({
            values,
            errors,
            setFieldValue,
            handleChange,
            handleSubmit,
            resetForm,
          }) => (
            console.warn('this si err', errors),
            (
              <form onSubmit={handleSubmit}>
                <div className="grid gap-4 md:grid-cols-2">
                  <div className="group relative z-0 mb-6 flex w-full items-center ">
                    <div className="w-full">
                      <DefaultInput
                        labelClassName="w-[50%]"
                        type="text"
                        name="deliverableNumber"
                        id="deliverableNumber"
                        label={t('DeliverableForm.deliverableNumberLabel')}
                        value={values.deliverableNumber}
                        onChange={handleChange}
                        error={errors.deliverableNumber}
                        readOnly={true}
                      />
                    </div>
                    <button
                      type="button"
                      className="absolute right-0 top-5"
                      onClick={() => setModalOpen(true)}
                    >
                      <PlusCircleIcon className="h-4 w-4 text-purple-500" />
                    </button>
                  </div>
                  <div className="group relative z-0 mb-6 w-full">
                    <DefaultInput
                      type="text"
                      name="codificationJoinerSymbol"
                      id="codificationJoinerSymbol"
                      labelClassName="w-[50%]"
                      label={t('DeliverableForm.codificationJoinerSymbolLabel')}
                      value={values.codificationJoinerSymbol}
                      onChange={(e) => {
                        if (codingsDeliverables.length > 0) {
                          setFieldValue(
                            'deliverableNumber',
                            codingsDeliverables
                              .map((cd) => cd.appreviation)
                              .join(e.target.value)
                          );
                        }
                        setFieldValue(
                          'codificationJoinerSymbol',
                          e.target.value
                        );
                      }}
                      error={errors.codificationJoinerSymbol}
                    />
                  </div>
                </div>
                <div className="grid gap-4 md:grid-cols-2">
                  <div className="group relative z-0 mb-6 w-full">
                    <DefaultInput
                      labelClassName="w-[50%]"
                      type="text"
                      name="description"
                      id="description"
                      label={t('DeliverableForm.descriptionLabel')}
                      value={values.description}
                      onChange={handleChange}
                      error={errors.description}
                    />
                  </div>
                  <div className="group relative z-0 mb-6 w-full">
                    <Dropdown
                      name="category"
                      id="category"
                      label={t('DeliverableForm.categoryLabel')}
                      value={values.category}
                      onChange={handleChange}
                      error={errors.category}
                      optionValue="id"
                      translatedNameSpace="DeliverableCategory"
                      isTranslated={true}
                      optionTransalatedKey="name"
                      options={DeliverableCategoryOptions ?? []}
                    />
                  </div>
                </div>
                <div className="grid gap-4 md:grid-cols-2">
                  <div className="group relative  mb-6 w-full">
                    <ReactDatePickerDefault
                      name="plannedStartDate"
                      id="plannedStartDate"
                      label={t('DeliverableForm.plannedStartDateLabel')}
                      value={values.plannedStartDate}
                      onChange={(date: Date) =>
                        setFieldValue('plannedStartDate', date)
                      }
                      error={errors.plannedStartDate}
                    />
                  </div>
                  <div className="group relative  mb-6 w-full">
                    <ReactDatePickerDefault
                      name="plannedEndDate"
                      id="plannedEndDate"
                      label={t('DeliverableForm.plannedEndDateLabel')}
                      value={values.plannedEndDate}
                      onChange={(date: Date) =>
                        setFieldValue('plannedEndDate', date)
                      }
                      error={errors.plannedEndDate}
                    />
                  </div>
                </div>
                <div className="grid gap-4 md:grid-cols-2">
                  <div className="group relative  mb-6 w-full">
                    <ReactDatePickerDefault
                      name="actualStartDate"
                      id="actualStartDate"
                      label={t('DeliverableForm.actualStartDateLabel')}
                      value={values.actualStartDate}
                      onChange={(date: Date) =>
                        setFieldValue('actualStartDate', date)
                      }
                      error={errors.actualStartDate}
                    />
                  </div>
                  <div className="group relative  mb-6 w-full">
                    <ReactDatePickerDefault
                      name="actualEndDate"
                      id="actualEndDate"
                      label={t('DeliverableForm.actualEndDateLabel')}
                      value={values.actualEndDate}
                      onChange={(date: Date) =>
                        setFieldValue('actualEndDate', date)
                      }
                      error={errors.actualEndDate}
                    />
                  </div>
                </div>
                <div className="grid gap-4 md:grid-cols-2">
                  <div className="group relative z-0 mb-6 w-full">
                    <DefaultInput
                      labelClassName="w-[50%]"
                      type="number"
                      name="estimatedDuration"
                      id="estimatedDuration"
                      label={t('DeliverableForm.estimatedDurationLabel')}
                      value={values.estimatedDuration}
                      onChange={handleChange}
                      error={errors.estimatedDuration}
                    />
                  </div>
                  <div className="group relative z-0 mb-6 w-full">
                    <DefaultInput
                      labelClassName="w-[50%]"
                      type="number"
                      name="actualDuration"
                      id="actualDuration"
                      label={t('DeliverableForm.actualDurationLabel')}
                      value={values.actualDuration}
                      onChange={handleChange}
                      error={errors.actualDuration}
                    />
                  </div>
                </div>
                <div className="grid gap-4 md:grid-cols-2">
                  <div className="group relative z-0 mb-6 w-full">
                    <DefaultInput
                      labelClassName="w-[50%]"
                      type="text"
                      name="progressStatus"
                      id="progressStatus"
                      label={t('DeliverableForm.progressStatusLabel')}
                      value={values.progressStatus}
                      onChange={handleChange}
                      error={errors.progressStatus}
                    />
                  </div>
                  <div className="group relative z-0 mb-6 w-full">
                    <DefaultInput
                      labelClassName="w-[50%]"
                      type="text"
                      name="responsibleAuthor"
                      id="responsibleAuthor"
                      label={t('DeliverableForm.responsibleAuthorLabel')}
                      value={values.responsibleAuthor}
                      onChange={handleChange}
                      error={errors.responsibleAuthor}
                    />
                  </div>
                </div>
                <div className="grid gap-4 md:grid-cols-2">
                  <div className="group relative z-0 mb-6 w-full">
                    <DefaultInput
                      labelClassName="w-[50%]"
                      type="text"
                      name="dependencies"
                      id="dependencies"
                      label={t('DeliverableForm.dependenciesLabel')}
                      value={values.dependencies}
                      onChange={handleChange}
                      error={errors.dependencies}
                    />
                  </div>
                  <div className="group relative z-0 mb-6 w-full">
                    <Dropdown
                      name="priority"
                      id="priority"
                      label={t('DeliverableForm.priorityLabel')}
                      value={values.priority}
                      onChange={handleChange}
                      error={errors.priority}
                      optionValue="id"
                      optionLabel="name"
                      translatedNameSpace="Priority"
                      isTranslated={true}
                      optionTransalatedKey="name"
                      options={PriorityOptions ?? []}
                    />
                  </div>
                </div>
                <div className="grid gap-4 md:grid-cols-2">
                  <div className="group relative z-0 mb-6 w-full">
                    <DefaultInput
                      labelClassName="w-[50%]"
                      type="number"
                      name="estimatedHours"
                      id="estimatedHours"
                      label={t('DeliverableForm.estimatedHoursLabel')}
                      value={values.estimatedHours}
                      onChange={handleChange}
                      error={errors.estimatedHours}
                    />
                  </div>
                  <div className="group relative z-0 mb-6 w-full">
                    <DefaultInput
                      labelClassName="w-[50%]"
                      type="number"
                      name="estimatedCost"
                      id="estimatedCost"
                      label={t('DeliverableForm.estimatedCostLabel')}
                      value={values.estimatedCost}
                      onChange={handleChange}
                      error={errors.estimatedCost}
                    />
                  </div>
                </div>
                <div className="grid gap-4 md:grid-cols-2">
                  <div className="group relative z-0 mb-6 w-full">
                    <DefaultInput
                      labelClassName="w-[50%]"
                      type="number"
                      name="actualHours"
                      id="actualHours"
                      label={t('DeliverableForm.actualHoursLabel')}
                      value={values.actualHours}
                      onChange={handleChange}
                      error={errors.actualHours}
                    />
                  </div>
                  <div className="group relative z-0 mb-6 w-full">
                    <DefaultInput
                      labelClassName="w-[50%]"
                      type="number"
                      name="actualCost"
                      id="actualCost"
                      label={t('DeliverableForm.actualCostLabel')}
                      value={values.actualCost}
                      onChange={handleChange}
                      error={errors.actualCost}
                    />
                  </div>
                </div>
                <div className="grid gap-4 md:grid-cols-2">
                  <div className="group relative z-0 mb-6 w-full">
                    {/* <DefaultInput
                     labelClassName="w-[50%]"
                    type="text"
                    name="status"
                    id="status"
                    label={t('DeliverableForm.statusLabel')}
                    value={values.status}
                    onChange={handleChange}
                    error={errors.status}
                  /> */}
                    <Dropdown
                      name="status"
                      id="status"
                      label={t('DeliverableForm.statusLabel')}
                      value={values.status}
                      translatedNameSpace="DeliverableStatus"
                      isTranslated={true}
                      optionTransalatedKey="name"
                      optionValue="id"
                      labelClassName="pl-0"
                      error={errors.status}
                      options={DeliverableStatusOptions ?? []}
                    />
                  </div>
                  <div className="group relative z-0 mb-6 w-full">
                    <DefaultInput
                      labelClassName="w-[50%]"
                      type="text"
                      name="risks"
                      id="risks"
                      label={t('DeliverableForm.risksLabel')}
                      value={values.risks}
                      onChange={handleChange}
                      error={errors.risks}
                    />
                  </div>
                </div>
                <div className="grid gap-4 md:grid-cols-2">
                  <div className="group relative z-0 mb-6 w-full">
                    <DefaultInput
                      labelClassName="w-[50%]"
                      type="text"
                      name="actionPlan"
                      id="actionPlan"
                      label={t('DeliverableForm.actionPlanLabel')}
                      value={values.actionPlan}
                      onChange={handleChange}
                      error={errors.actionPlan}
                    />
                  </div>
                  <div className="group relative z-0 mb-6 w-full">
                    {/* <DefaultInput
                      labelClassName="w-[50%]"
                      type="text"
                      name="draftState"
                      id="draftState"
                      label={t('DeliverableForm.draftStateLabel')}
                      value={values.draftState}
                      onChange={handleChange}
                      error={errors.draftState}
                    /> */}

                    <Dropdown
                      name="draftState"
                      id="draftState"
                      label={t('DeliverableForm.draftStateLabel')}
                      value={values.draftState}
                      translatedNameSpace="DeliverableStatus"
                      isTranslated={true}
                      optionTransalatedKey="name"
                      optionValue="id"
                      labelClassName="pl-0"
                      error={errors.draftState}
                      options={DeliverableStatusOptions ?? []}
                    />
                  </div>
                </div>
                <div className="grid gap-4 md:grid-cols-2">
                  <div className="group relative z-0 mb-6 w-full">
                    {/* <DefaultInput
                     labelClassName="w-[50%]"
                    type="text"
                    name="verificationState"
                    id="verificationState"
                    label={t('DeliverableForm.verificationStateLabel')}
                    value={values.verificationState}
                    onChange={handleChange}
                    error={errors.verificationState}
                  /> */}
                    <Dropdown
                      name="verificationState"
                      id="verificationState"
                      label={t('DeliverableForm.verificationStateLabel')}
                      value={values.verificationState}
                      translatedNameSpace="DeliverableStatus"
                      isTranslated={true}
                      optionTransalatedKey="name"
                      optionValue="id"
                      labelClassName="pl-0"
                      error={errors.verificationState}
                      options={DeliverableStatusOptions ?? []}
                    />
                  </div>
                  <div className="group relative z-0 mb-6 w-full">
                    {/* <DefaultInput
                     labelClassName="w-[50%]"
                    type="text"
                    name="approvalState"
                    id="approvalState"
                    label={t('DeliverableForm.approvalStateLabel')}
                    value={values.approvalState}
                    onChange={handleChange}
                    error={errors.approvalState}
                  /> */}
                    <Dropdown
                      name="approvalState"
                      id="approvalState"
                      label={t('DeliverableForm.approvalStateLabel')}
                      value={values.approvalState}
                      translatedNameSpace="DeliverableStatus"
                      isTranslated={true}
                      optionTransalatedKey="name"
                      optionValue="id"
                      labelClassName="pl-0"
                      error={errors.approvalState}
                      options={DeliverableStatusOptions ?? []}
                    />
                  </div>
                </div>
                <div className="grid gap-4 md:grid-cols-2">
                  <div className="group relative z-0 mb-6 w-full">
                    <DefaultInput
                      labelClassName="w-[50%]"
                      type="text"
                      name="observations"
                      id="observations"
                      label={t('DeliverableForm.observationsLabel')}
                      value={values.observations}
                      onChange={handleChange}
                      error={errors.observations}
                    />
                  </div>
                  <div className="group relative  mb-6 w-full">
                    {/* <DefaultInput
                    labelClassName="w-[50%]"
                    type="text"
                    name="verifiersIds"
                    id="verifiersIds"
                    label={t('DeliverableForm.verifiersIdsLabel')}
                    value={values.verifiersIds}
                    onChange={handleChange}
                    error={errors.verifiersIds}
                  /> */}

                    <MultiSelect
                      id="verifiersIds"
                      name="verifiersIds"
                      optionValue="employeeId"
                      optionLabel="email"
                      options={projectTeamMemebers?.projectTeamMembers ?? []}
                      label={t('DeliverableForm.verifiersIdsLabel')}
                      selectedValue={
                        values.verifiersIds && values.verifiersIds !== ''
                          ? values.verifiersIds.split(',')
                          : []
                      }
                      onChange={(selectedOptions) => {
                        // handleChange({
                        //   target: {
                        //     name: 'moduleTypes',
                        //     value: selectedCheckboxes.join(','),
                        //   },
                        // });
                        setFieldValue(
                          'verifiersIds',
                          selectedOptions.join(',')
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="grid gap-4 md:grid-cols-2">
                  <div className="group relative mb-6 w-full">
                    {/* <DefaultInput
                    labelClassName="w-[50%]"
                    type="text"
                    name="approversIds"
                    id="approversIds"
                    label={t('DeliverableForm.approversIdsLabel')}
                    value={values.approversIds}
                    onChange={handleChange}
                    error={errors.approversIds}
                  /> */}

                    <MultiSelect
                      id="approversIds"
                      name="approversIds"
                      optionValue="employeeId"
                      optionLabel="email"
                      options={projectTeamMemebers?.projectTeamMembers ?? []}
                      label={t('DeliverableForm.approversIdsLabel')}
                      ///options={projectTeamMemebers?.employees ?? []}
                      selectedValue={
                        values.approversIds && values.approversIds !== ''
                          ? values.approversIds.split(',')
                          : []
                      }
                      onChange={(selectedOptions) => {
                        setFieldValue(
                          'approversIds',
                          selectedOptions.join(',')
                        );
                      }}
                    />
                  </div>
                  <div className="group relative -z-1  mb-6 w-full">
                    <TextArea
                      name="comments"
                      id="comments"
                      label={t('DeliverableForm.commentsLabel')}
                      value={values.comments}
                      onChange={handleChange}
                      error={errors.comments}
                    />
                  </div>
                </div>
                {/* Deliverable Codifications Section */}
                {/* <div className="mb-6">
                <label className="mb-2 block text-sm font-medium text-gray-700">
                  {t('DeliverableForm.codificationsLabel')}
                </label>
                <div className="grid gap-4 md:grid-cols-2">
                  {values.deliverableCodifications.map(
                    (codification, index) => (
                      <div key={index}>
                        <div className="group relative z-0 mb-6 w-full">
                          <DefaultInput
                             labelClassName="w-[50%]"
                            type="text"
                            name={`deliverableCodifications[${index}].description`}
                            id={`deliverableCodifications[${index}].description`}
                            label={t(
                              'DeliverableForm.codificationDescriptionLabel'
                            )}
                            value={codification.description}
                            onChange={handleChange}
                            error={
                              errors.deliverableCodifications?.[index]
                                ?.description
                            }
                          />
                        </div>
                        <div className="group relative z-0 mb-6 w-full">
                          <DefaultInput
                             labelClassName="w-[50%]"
                            type="text"
                            name={`deliverableCodifications[${index}].appreviation`}
                            id={`deliverableCodifications[${index}].appreviation`}
                            label={t(
                              'DeliverableForm.codificationAppreviationLabel'
                            )}
                            value={codification.appreviation}
                            onChange={handleChange}
                            error={
                              errors.deliverableCodifications?.[index]
                                ?.appreviation
                            }
                          />
                        </div>
                        <div className="group relative z-0 mb-6 w-full">
                          <DefaultInput
                             labelClassName="w-[50%]"
                            type="text"
                            name={`deliverableCodifications[${index}].companyId`}
                            id={`deliverableCodifications[${index}].companyId`}
                            label={t(
                              'DeliverableForm.codificationCompanyIdLabel'
                            )}
                            value={codification.companyId}
                            onChange={handleChange}
                            error={
                              errors.deliverableCodifications?.[index]
                                ?.companyId
                            }
                          />
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div> */}

                {modalOpen && (
                  <DeliverableCodificationsModal
                    deliverablesCodings={codingsDeliverables}
                    setDeliverablesCodings={(de) =>
                      setCodingsDeliverables((pr) => de)
                    }
                    closeModal={() => setModalOpen(false)}
                  />
                )}
                <FormButtons resetForm={resetForm} loading={loading} />
              </form>
            )
          )}
        </Formik>
      )}
    </DefaultLayout>
  );
}

export default DeliverableForm;
