import React, { useEffect, useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Dropdown from '@/components/DropDown';
import DatePickerDefault from '@/components/DatePicker';
import {
  RequestLeave,
  saveRequestLeave,
} from '@/services/RequestLeavesService';
import { useAuth } from '@/context/AuthContext';
import {
  VacationBalance,
  getVacationBalances,
  getVacationBalancesByEmployeeId,
} from '@/services/VacationBalanceService';
import { useQuery } from '@tanstack/react-query';
import { t } from 'i18next';
import Spinner from '@/components/Spinner';
import {
  Button,
  EditButton,
  LabelButton,
  PrimaryButton,
} from '@/components/shared/Button';
import TextArea from '@/components/TextArea';
import { useTranslation } from 'react-i18next';
import { differenceInCalendarDays } from 'date-fns';
import { default as DefaultInput } from '@/components/Input';
import { toast } from 'react-hot-toast';
import {
  TransferRequestState,
  VacationBalanceTransferRequest,
  saveTransferRequest,
} from '@/services/VacationBalanceTransferRequestService';
import { VacationTypeEnum } from '@/enums/VacationTypeEnum';
import { UserRoles } from '@/enums/UsersRole';

const RequestALeaveSchema = Yup.object().shape({
  days: Yup.number().required('number of days is required'),
  fromVacationBalanceId: Yup.string().required(
    'you should choose source of your vacation transfer'
  ),
  toVacationBalanceId: Yup.string().required(
    'you should choose destination to your vacation transferd'
  ),
});
interface RequestALeaveModalProps {
  onSaveWithSendingToValidate: () => void;
  onSaveOnly: () => void;
  isOpen: boolean;
  onCancel: () => void;
  isTransferRequest?: boolean;
}

const TransferRequestALeaveModal = ({ isOpen, onCancel }) => {
  {
    /* Modal props */
  }
  const [loading, setLoading] = React.useState(false);
  const { t } = useTranslation();

  const [modalClass, setModalClass] = useState('modal-hide');
  useEffect(() => {
    setModalClass((prev) => (prev ? 'modal-show' : 'modal-hide'));
  }, [isOpen]);
  {
    /* Modal props */
  }
  const initialValues: VacationBalanceTransferRequest = {
    days: 0,
    fromVacationBalanceId: '',
    toVacationBalanceId: '',
  };

  const { user } = useAuth();

  const {
    data: vacationBalancesData,
    isLoading: vacationBalancesLoading,
    isSuccess,
  } = useQuery(
    ['vacationsBalances', user?.id],
    () =>
      getVacationBalancesByEmployeeId({
        fromvalue: 0,
        takevalue: 0,
        search: '',
        employeeId: user?.id,
      }),
    {
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
    }
  );

  const handleSubmit = async (
    values: VacationBalanceTransferRequest,
    { resetForm, validateForm, submitForm, action }: any
  ) => {};

  const handleSaveAndSend = async (
    values: VacationBalanceTransferRequest,
    { resetForm, validateForm, submitForm, action }: any
  ) => {
    setLoading(true); // Set loading to true before making the API request
    try {
      let TransferRequestLeave: VacationBalanceTransferRequest = {
        ...values,
        isDraft: false,
      };

      const data = await saveTransferRequest(TransferRequestLeave);
      if (data) {
        toast.success(
          t('SuccessMessage', { name: t('RequestLeaveModal.requestLeave') })
        );
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error(
        t('ErrorMessage', { name: t('RequestLeaveModal.requestLeave') })
      );
    } finally {
      setLoading(false);
      resetForm(); // Set loading to false after the API request is complete
    }
  };

  const handleSave = async (
    values: VacationBalanceTransferRequest,
    { resetForm, validateForm, submitForm }: any
  ) => {
    setLoading(true); // Set loading to true before making the API request
    try {
      let TransferRequestLeave: VacationBalanceTransferRequest = {
        ...values,
        isDraft: true,
      };

      const data = await saveTransferRequest(TransferRequestLeave);
      if (data) {
        toast.success(
          t('SuccessMessage', {
            name: t('RequestLeaveModal.requestLeave'),
          })
        );
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
      resetForm(); // Set loading to false after the API request is complete
    }
  };

  const filterVacation = (vacations: VacationBalance[] | undefined) => {
    const transferVacations = [VacationTypeEnum.RTT, VacationTypeEnum.CP];

    if (!vacations) return [];

    return vacations.filter((vc) =>
      transferVacations.includes(vc.vacationName!)
    );
  };
  const filterEmpargnVacation = (vacations: VacationBalance[] | undefined) => {
    const transferVacations = [
      VacationTypeEnum.CET_RTT,
      VacationTypeEnum.CET_CP,
    ];

    if (!vacations) return [];

    return vacations.filter((vc) =>
      transferVacations.includes(vc.vacationName!)
    );
  };
  return (
    <div
      id="popup-modal"
      tabIndex={-1}
      className={`fixed left-0  right-0 top-0  z-[1055]  flex h-full w-full items-center  justify-center overflow-y-auto  bg-gray-800 bg-opacity-75 ${modalClass} `}
    >
      <div className=" max-h-200 relative  w-150 overflow-y-scroll  rounded-lg bg-white  shadow-md dark:bg-gray-700">
        <button
          type="button"
          className="absolute right-2.5 top-2  inline-flex h-8 w-8 items-center justify-center rounded-lg bg-transparent text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
          onClick={onCancel}
        >
          <svg
            className="h-3 w-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 14"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
          </svg>
          <span className="sr-only">{t('RequestLeaveModal.closeModal')}</span>
        </button>
        <div className="rounded-t border-b px-6 py-2 dark:border-gray-600">
          <h3 className="text-base font-semibold text-gray-900 dark:text-white lg:text-xl">
            {t('transferRequestVacation.label')}
          </h3>
        </div>
        {vacationBalancesLoading ? (
          <Spinner />
        ) : (
          <Formik
            initialValues={initialValues}
            validationSchema={RequestALeaveSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              resetForm,
              validateForm,
              setFieldValue,
              submitForm,
            }) => (
              <Form className="p-5">
                <div className="grid md:grid-cols-2 md:gap-6">
                  <div className="group mb-6 w-full">
                    <Dropdown
                      id="fromVacationBalanceId"
                      name="fromVacationBalanceId"
                      optionValue="id"
                      optionLabel="vacationName"
                      labelClassName="w-[60%]"
                      onChange={(e) => {
                        setFieldValue('fromVacationBalanceId', e.target.value);
                      }}
                      className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                      options={
                        filterVacation(vacationBalancesData?.leaveBalances) ??
                        []
                      }
                      label={t('transferRequestVacation.fromVacation')}
                      error={errors.fromVacationBalanceId}
                      value={values.fromVacationBalanceId}
                    />
                  </div>
                  <div className="group  mb-6 w-full">
                    <Dropdown
                      id="toVacationBalanceId"
                      name="toVacationBalanceId"
                      optionValue="id"
                      optionLabel="vacationName"
                      labelClassName="w-[80%]"
                      onChange={(e) => {
                        setFieldValue('toVacationBalanceId', e.target.value);
                      }}
                      className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                      options={
                        filterEmpargnVacation(
                          vacationBalancesData?.leaveBalances
                        ) ?? []
                      }
                      label={t('transferRequestVacation.toVacation')}
                      error={errors.toVacationBalanceId}
                      value={values.toVacationBalanceId}
                    />
                  </div>
                </div>
                <div className="group relative  mb-6 w-full">
                  <DefaultInput
                    type="number"
                    id="days"
                    name="days"
                    label={t('transferRequestVacation.days')}
                    errors={errors}
                    value={values.days}
                    onChange={handleChange}
                  />
                </div>
                {user?.isTeamLeader == 'True' && (
                  <div className="group relative  mb-6 w-full">
                    <TextArea
                      id="comment"
                      name="comment"
                      label={t('transferRequestVacation.comment')}
                      errors={errors}
                      value={values.comment}
                      onChange={handleChange}
                    />
                  </div>
                )}
                <div className="flex justify-between">
                  <LabelButton type="button" onClick={onCancel}>
                    {t('RequestLeaveModal.close')}
                  </LabelButton>
                  <div className="m-0 flex items-center justify-between p-0">
                    <PrimaryButton
                      disabled={loading}
                      type="button"
                      onClick={(e) =>
                        handleSaveAndSend(values, {
                          resetForm,
                          validateForm,
                          submitForm,
                          action: 'saveAndSend',
                        })
                      }
                    >
                      {t('RequestLeaveModal.saveAndSend')}
                    </PrimaryButton>
                    <EditButton
                      className="mb-0"
                      type="button"
                      onClick={(e) =>
                        handleSave(values, {
                          resetForm,
                          validateForm,
                          submitForm,
                          action: 'save',
                        })
                      }
                    >
                      {t('RequestLeaveModal.save')}
                    </EditButton>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </div>
  );
};

export default TransferRequestALeaveModal;
