import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

interface CheckboxProps {
  label: string;
  checked: boolean;
  onChange: (e: any) => void;
  id: string;
  name: string;
  disabled?: boolean;
  errors?: any; // Add the 'errors' prop if you want to show error messages
}

const Checkbox: React.FC<CheckboxProps> = ({
  label,
  checked,
  onChange,
  id,
  errors,
  name,
  disabled,
}) => {
  const { t } = useTranslation();

  return (
    <div className="relative">
      <div className="flex items-center">
        <input
          checked={checked}
          id={id}
          type="checkbox"
          value=""
          disabled={disabled}
          className={`
            relative h-4 w-4 appearance-none rounded border-gray-400 bg-gray-300 checked:border-transparent checked:bg-primary-500 checked:ring-2 checked:ring-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:border-gray-500 dark:bg-gray-600 dark:ring-offset-gray-700 dark:focus:ring-blue-600 dark:focus:ring-offset-gray-700
            ${
              errors && errors[id]
                ? 'border-red-500 focus:border-red-500 dark:border-red-400'
                : ''
            }
          `}
          onChange={onChange}
        />
        <label
          htmlFor={id}
          className={`
            ml-2 text-sm font-medium text-gray-900 dark:text-gray-300
            ${errors && errors[name] ? 'text-red-700 dark:text-red-500' : ''}
          `}
        >
          {label}
        </label>
      </div>
      {errors && errors[name] && (
        <p className="mt-2 text-sm text-red-600 dark:text-red-500">
          {t(errors[name])}
        </p>
      )}
    </div>
  );
};

export default Checkbox;
