import AbsenceManagement from '@/pages/AbsenseMangment';
import SignIn from '@/pages/Authentication/SignIn';
import Branches from '@/pages/Branch';
import BranchForm from '@/pages/Branch/BranchForm';
import Company from '@/pages/Company';
import CompanyForm from '@/pages/Company/CompanyForm';
import DashboradAnalytics from '@/pages/Dashboard/DashboradAnalytics';
import Departements from '@/pages/Departement';
import DepartementForm from '@/pages/Departement/DepartementForm';
import Employees from '@/pages/Employee';
import EmployeeForm from '@/pages/Employee/EmployeeForm';
import ErrorPage from '@/pages/ErrorPage';
import NotFound404 from '@/pages/NotFound';
import Profile from '@/pages/Profile';
import Settings from '@/pages/Settings';
import Teams from '@/pages/Team';
import TeamForm from '@/pages/Team/TeamForm';
import Vacations from '@/pages/Vacations';
import VacationForm from '@/pages/Vacations/VacationForm';
import WorkCalendar from '@/pages/WorkCalendars';
import WorkCalendarForm from '@/pages/WorkCalendars/WorkCalendarForm';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ProtectedRoute } from './ProtectedRoute';
import AbsensePannel from '@/pages/AbsenseMangment/AbsensePannel';
import RequestLeaveDetails from '@/pages/RequestLeaves/RequestLeaveDetails';
import ForgotPasswordForm from '@/pages/Authentication/ForgetPassword';
import ResetPasswordForm from '@/pages/Authentication/ResetPasswordForm';
import TransferVacationRequestDetails from '@/pages/TransferVacationRequest/TransferVacationRequestDetails';
import MedicalAppointments from '@/pages/MedicalAppointment';
import MedicalAppointmentForm from '@/pages/MedicalAppointment/MedicalAppointmentForm';
import TrainingRequests from '@/pages/TrainingRequest';
import TrainingRequestForm from '@/pages/TrainingRequest/TrainingRequestForm';
import TrainingRequestDetails from '@/pages/TrainingRequest/TrainingRequestDetails';
import TrainingSatisfactionFormTable from '@/pages/TrainingSatisfactionForm/TrainingSatisfactionFormTable';
import TrainingSatisfactionForms from '@/pages/TrainingSatisfactionForm';
import TrainingProviders from '@/pages/TrainingProviders';
import JobDescriptions from '@/pages/JobsDescription';
import JobDescriptionDetail from '@/pages/JobsDescription/JobDescriptionDetail';
import JobDescriptionDetailForm from '@/pages/MySpace/JobDescriptionForm';
import RhIndicators from '@/pages/Analytics/RhIndicators';
import FormationIndicators from '@/pages/Analytics/FormationIndicators';
import AnnualInterviews from '@/pages/AnnualInterview';
import AnnualInterviewForm from '@/pages/AnnualInterview/AnnualInterviewForm';
import AnnualInterviewMeeting from '@/pages/AnnualInterview/AnnualInterviewMeeting';
import AppointmentsIndicators from '@/pages/Analytics/AppointmentsIndicators';
import OrganisationChart from '@/pages/MySpace/OrganisationChart';
import ProjectExpenses from '@/pages/ProjectExpenses';
import ProjectExpenseForm from '@/pages/ProjectExpenses/ProjectExpenseForm';
import ProjectExpenseDetails from '@/pages/ProjectExpenses/ProjectExpenseDetails';
import DocumentRequests from '@/pages/DocumentRequests';
import DocumentRequestForm from '@/pages/DocumentRequests/DocumentRequestForm';
import DocumentRequestDetails from '@/pages/DocumentRequests/DocumentRequestDetails';
import ProjectsPOA from '@/pages/ProjectPOA';
import ProjectsPOAForm from '@/pages/ProjectPOA/ProjectsPOAForm';
import Equipments from '@/pages/Equipments';
import EquipmentForm from '@/pages/Equipments/EquipmentForm';
import SupplierOrders from '@/pages/SupplierOrders';
import SupplierOrderForm from '@/pages/SupplierOrders/SupplierOrderForm';
import CompanySubcontractors from '@/pages/CompanySubcontractors';
import CompanySubcontractorForm from '@/pages/CompanySubcontractors/CompanySubcontractorForm';
import PersonnelCosts from '@/pages/PersonnelCosts';
import PersonnelCostForm from '@/pages/PersonnelCosts/PersonnelCostForm';
import Partners from '@/pages/Partners';
import PartnerForm from '@/pages/Partners/PartnerForm';
import SubContractors from '@/pages/SubContractors';
import SubContractorForm from '@/pages/SubContractors/SubContractorForm';
import Competitors from '@/pages/Competitors';
import CompetitorForm from '@/pages/Competitors/CompetitorForm';
import ProjectOwners from '@/pages/ProjectOwners';
import ProjectOwnerForm from '@/pages/ProjectOwners/ProjectOwnerForm';
import ProjectExecutors from '@/pages/ProjectExecutors';
import ProjectExecutorForm from '@/pages/ProjectExecutors/ProjectExecutorForm';
import Deliverables from '@/pages/ProjectPOA/Components/Deliverables';
import InterfaceRequests from '@/pages/ProjectPOA/Components/InterfaceRequests';
import Journals from '@/pages/ProjectPOA/Components/Journals';
import OuvrageEntries from '@/pages/ProjectPOA/Components/OuvrageEntries';
import PlanningAOTasks from '@/pages/ProjectPOA/Components/PlanningAOTasks';
import ProjectAOStateForm from '@/pages/ProjectPOA/Components/ProjectAOCaseForm';
import ProjectCharastristics from '@/pages/ProjectPOA/Components/ProjectCharastristics';
import ProjectMeetings from '@/pages/ProjectPOA/Components/ProjectMeetings';
import ProjectTasks from '@/pages/ProjectPOA/Components/ProjectTasks';
import ProjectTeamMembers from '@/pages/ProjectPOA/Components/ProjectTeamMembers';
import SousProjects from '@/pages/ProjectPOA/Components/SousProjects';
import WorkCharges from '@/pages/ProjectPOA/Components/WorkCharges';
import ProjectCharacteristicsForm from '@/pages/ProjectPOA/Components/ProjectCharacteristicsForm';
import ProjectTeamMemberForm from '@/pages/ProjectPOA/Components/ProjectTeamMemberForm';
import ProjectMeetingForm from '@/pages/ProjectPOA/Components/ProjectMeetingForm';
import MeetingSubjects from '@/pages/ProjectPOA/Components/MeetingSubjects';
import MeetingSubjectForm from '@/pages/ProjectPOA/Components/MeetingSubjectForm';
import InterfaceRequestForm from '@/pages/ProjectPOA/Components/InterfaceRequestForm';
import QuestionResponses from '@/pages/ProjectPOA/Components/QuestionResponses';
import QuestionResponseForm from '@/pages/ProjectPOA/Components/QuestionResponseForm';
import JournalEntries from '@/pages/ProjectPOA/Components/JournalEntries';
import JournalEntryForm from '@/pages/ProjectPOA/Components/JournalEntryForm';
import PlanningAOTaskForm from '@/pages/ProjectPOA/Components/PlanningAOTaskForm';
import { TrashIcon } from '@heroicons/react/solid';
import ProjectTaskForm from '@/pages/ProjectPOA/Components/ProjectTaskForm';
import OuvrageEntryForm from '@/pages/ProjectPOA/Components/OuvrageEntryForm';
import Risks from '@/pages/ProjectPOA/Components/Risks';
import RiskForm from '@/pages/ProjectPOA/Components/RiskForm';
import DeliverableForm from '@/pages/ProjectPOA/Components/DeliverableForm';
import SubProjectMethods from '@/pages/ProjectPOA/Components/SousProjects';
import SubProjectMethodForm from '@/pages/ProjectPOA/Components/SubProjectFormStepper';
import SubProjectFormStepper from '@/pages/ProjectPOA/Components/SubProjectFormStepper';
import QuestionResponseDetails from '@/pages/ProjectPOA/Components/QuestionResponseDetails';

function AppRouter(): JSX.Element {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/auth/login" element={<SignIn />} />

        <Route
          path="/"
          element={
            <ProtectedRoute>
              <DashboradAnalytics />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashbord"
          element={
            <ProtectedRoute>
              <DashboradAnalytics />
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />
        <Route path="/forget-password" element={<ForgotPasswordForm />} />
        <Route path="/reset-password" element={<ResetPasswordForm />} />
        <Route
          path="/settings"
          element={
            <ProtectedRoute>
              <Settings />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gestion-resources-humains/request-leave-details/:id"
          element={
            <ProtectedRoute>
              <RequestLeaveDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gestion-resources-humains/vacation-transfer-request-details/:id"
          element={
            <ProtectedRoute>
              <TransferVacationRequestDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path="/settings/WorkCalendars"
          element={
            <ProtectedRoute>
              <WorkCalendar />
            </ProtectedRoute>
          }
        />
        <Route
          path="/settings/WorkCalendars/:id"
          element={
            <ProtectedRoute>
              <WorkCalendarForm />
            </ProtectedRoute>
          }
        />
        <Route
          path="/settings/Vacations"
          element={
            <ProtectedRoute>
              <Vacations />
            </ProtectedRoute>
          }
        />
        <Route
          path="/settings/Vacations/:id"
          element={
            <ProtectedRoute>
              <VacationForm />
            </ProtectedRoute>
          }
        />
        <Route
          path="/settings/partners"
          element={
            <ProtectedRoute>
              <Partners />
            </ProtectedRoute>
          }
        />
        <Route
          path="/settings/partners/:id"
          element={
            <ProtectedRoute>
              <PartnerForm />
            </ProtectedRoute>
          }
        />
        <Route
          path="/settings/Subcontractes"
          element={
            <ProtectedRoute>
              <SubContractors />
            </ProtectedRoute>
          }
        />
        <Route
          path="/settings/Subcontractes/:id"
          element={
            <ProtectedRoute>
              <SubContractorForm />
            </ProtectedRoute>
          }
        />
        <Route
          path="/settings/Competitors"
          element={
            <ProtectedRoute>
              <Competitors />
            </ProtectedRoute>
          }
        />
        <Route
          path="/settings/Competitors/:id"
          element={
            <ProtectedRoute>
              <CompetitorForm />
            </ProtectedRoute>
          }
        />
        <Route
          path="/settings/ProjectOwners"
          element={
            <ProtectedRoute>
              <ProjectOwners />
            </ProtectedRoute>
          }
        />
        <Route
          path="/settings/ProjectOwners/:id"
          element={
            <ProtectedRoute>
              <ProjectOwnerForm />
            </ProtectedRoute>
          }
        />
        <Route
          path="/settings/ProjectExecutors"
          element={
            <ProtectedRoute>
              <ProjectExecutors />
            </ProtectedRoute>
          }
        />
        <Route
          path="/settings/ProjectExecutors/:id"
          element={
            <ProtectedRoute>
              <ProjectExecutorForm />
            </ProtectedRoute>
          }
        />

        <Route
          path="/gestion-resources-humains/gestion-des-absences/employees"
          element={
            <ProtectedRoute>
              <Employees />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gestion-resources-humains/gestion-des-absences/absense-management"
          element={
            <ProtectedRoute>
              <AbsenceManagement />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gestion-resources-humains/gestion-des-absences/absense-pannel"
          element={
            <ProtectedRoute>
              <AbsensePannel />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gestion-resources-humains/gestion-des-absences/employees/:id"
          element={
            <ProtectedRoute>
              <EmployeeForm />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gestion-resources-humains/gestion-des-absences/departements"
          element={
            <ProtectedRoute>
              <Departements />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gestion-resources-humains/gestion-des-absences/teams/:id"
          element={
            <ProtectedRoute>
              <TeamForm />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gestion-resources-humains/gestion-des-absences/teams"
          element={
            <ProtectedRoute>
              <Teams />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gestion-resources-humains/gestion-des-absences/departements/:id"
          element={
            <ProtectedRoute>
              <DepartementForm />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gestion-resources-humains/gestion-des-absences/branches"
          element={
            <ProtectedRoute>
              <Branches />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gestion-resources-humains/gestion-des-absences/branches/:id"
          element={
            <ProtectedRoute>
              <BranchForm />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gestion-resources-humains/gestion-note-de-frais"
          element={
            <ProtectedRoute>
              <ProjectExpenses />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gestion-resources-humains/gestion-note-de-frais/:id"
          element={
            <ProtectedRoute>
              <ProjectExpenseForm />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gestion-resources-humains/gestion-note-de-frais/Details/:id"
          element={
            <ProtectedRoute>
              <ProjectExpenseDetails />
            </ProtectedRoute>
          }
        />

        <Route
          path="/companies"
          element={
            <ProtectedRoute>
              <Company />
            </ProtectedRoute>
          }
        />
        <Route
          path="/companies/:id"
          element={
            <ProtectedRoute>
              <CompanyForm />
            </ProtectedRoute>
          }
        />

        <Route
          path="/gestion-resources-humains/gestion-de-visite-medicale"
          element={
            <ProtectedRoute>
              <MedicalAppointments />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gestion-resources-humains/gestion-de-visite-medicale/:id"
          element={
            <ProtectedRoute>
              <MedicalAppointmentForm />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gestion-resources-humains/gestion-des-documents"
          element={
            <ProtectedRoute>
              <DocumentRequests />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gestion-resources-humains/gestion-des-documents/:id"
          element={
            <ProtectedRoute>
              <DocumentRequestForm />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gestion-resources-humains/gestion-des-documents/details/:id"
          element={
            <ProtectedRoute>
              <DocumentRequestDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gestion-resources-humains/gestion-des-formations/formations"
          element={
            <ProtectedRoute>
              <TrainingRequests />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gestion-resources-humains/gestion-des-formations/formations/:id"
          element={
            <ProtectedRoute>
              <TrainingRequestForm />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gestion-resources-humains/gestion-des-formations/formations/details/:id"
          element={
            <ProtectedRoute>
              <TrainingRequestDetails />
            </ProtectedRoute>
          }
        />

        <Route
          path="/gestion-resources-humains/gestion-des-formations/satification-forms"
          element={
            <ProtectedRoute>
              <TrainingSatisfactionForms />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gestion-resources-humains/gestion-des-formations/satification-forms/details/:id"
          element={
            <ProtectedRoute>
              <TrainingSatisfactionFormTable />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gestion-resources-humains/gestion-des-formations/training-providers"
          element={
            <ProtectedRoute>
              <TrainingProviders />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gestion-resources-humains/gestion-des-fiches-de-postes-cv"
          element={
            <ProtectedRoute>
              <JobDescriptions />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gestion-resources-humains/gestion-des-fiches-de-postes-cv/:id"
          element={
            <ProtectedRoute>
              <JobDescriptionDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gestion-des-rapports/RhIndicators"
          element={
            <ProtectedRoute>
              <RhIndicators />
            </ProtectedRoute>
          }
        />

        <Route
          path="/gestion-des-rapports/FormationsIndicators"
          element={
            <ProtectedRoute>
              <FormationIndicators />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gestion-des-rapports/AppointmentsIndicators"
          element={
            <ProtectedRoute>
              <AppointmentsIndicators />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gestion-resources-humains/gestion-entretien-annuel"
          element={
            <ProtectedRoute>
              <AnnualInterviews />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gestion-resources-humains/gestion-entretien-annuel/:id"
          element={
            <ProtectedRoute>
              <AnnualInterviewForm />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gestion-resources-humains/gestion-entretien-annuel/start-annual-interview/:id"
          element={
            <ProtectedRoute>
              <AnnualInterviewMeeting />
            </ProtectedRoute>
          }
        />

        <Route
          path="/gestion-resources-humains/gestion-entretien-annuel/Details/:id"
          element={
            <ProtectedRoute>
              <AnnualInterviewForm />
            </ProtectedRoute>
          }
        />
        {/* Project POA  */}
        <Route
          path="/gestion-project-ao"
          element={
            <ProtectedRoute>
              <ProjectsPOA />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gestion-project-ao/:id"
          element={
            <ProtectedRoute>
              <ProjectsPOAForm />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gestion-project-ao/:id/details"
          element={
            <ProtectedRoute>
              <ProjectsPOAForm />
            </ProtectedRoute>
          }
        />

        <Route
          path="/gestion-project-ao/:id/project-state"
          element={
            <ProtectedRoute>
              <ProjectAOStateForm />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gestion-project-ao/:id/Charastristics"
          element={
            <ProtectedRoute>
              <ProjectCharastristics />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gestion-project-ao/:id/Charastristics/:charId"
          element={
            <ProtectedRoute>
              <ProjectCharacteristicsForm />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gestion-project-ao/:id/TeamMembers"
          element={
            <ProtectedRoute>
              <ProjectTeamMembers />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gestion-project-ao/:id/TeamMembers/:teamId"
          element={
            <ProtectedRoute>
              <ProjectTeamMemberForm />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gestion-project-ao/:id/ProjectMeetings"
          element={
            <ProtectedRoute>
              <ProjectMeetings />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gestion-project-ao/:id/ProjectMeetings/:meetingId"
          element={
            <ProtectedRoute>
              <ProjectMeetingForm />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gestion-project-ao/:id/:meetingId/subjects"
          element={
            <ProtectedRoute>
              <MeetingSubjects />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gestion-project-ao/:id/:meetingId/subjects/:subjectId"
          element={
            <ProtectedRoute>
              <MeetingSubjectForm />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gestion-project-ao/:id/InterfaceRequests"
          element={
            <ProtectedRoute>
              <InterfaceRequests />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gestion-project-ao/:id/InterfaceRequests/:requestId"
          element={
            <ProtectedRoute>
              <InterfaceRequestForm />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gestion-project-ao/:id/Deliverables"
          element={
            <ProtectedRoute>
              <Deliverables />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gestion-project-ao/:id/Deliverables/:deliverableId"
          element={
            <ProtectedRoute>
              <DeliverableForm />
            </ProtectedRoute>
          }
        />

        <Route
          path="/gestion-project-ao/:id/OuvrageEntries"
          element={
            <ProtectedRoute>
              <OuvrageEntries />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gestion-project-ao/:id/OuvrageEntries/:entryId"
          element={
            <ProtectedRoute>
              <OuvrageEntryForm />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gestion-project-ao/:id/Risks"
          element={
            <ProtectedRoute>
              <Risks />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gestion-project-ao/:id/Risks/:riskId"
          element={
            <ProtectedRoute>
              <RiskForm />
            </ProtectedRoute>
          }
        />

        <Route
          path="/gestion-project-ao/:id/ProjectTasks"
          element={
            <ProtectedRoute>
              <ProjectTasks />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gestion-project-ao/:id/ProjectTasks/:taskId"
          element={
            <ProtectedRoute>
              <ProjectTaskForm />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gestion-project-ao/:id/PlanningAOTasks"
          element={
            <ProtectedRoute>
              <PlanningAOTasks />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gestion-project-ao/:id/PlanningAOTasks/:taskId"
          element={
            <ProtectedRoute>
              <PlanningAOTaskForm />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gestion-project-ao/:id/Journals"
          element={
            <ProtectedRoute>
              <Journals />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gestion-project-ao/:id/QuestionResponses"
          element={
            <ProtectedRoute>
              <QuestionResponses />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gestion-project-ao/:id/QuestionResponses/:responseId"
          element={
            <ProtectedRoute>
              <QuestionResponseForm />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gestion-project-ao/QuestionResponses/:responseId"
          element={
            <ProtectedRoute>
              <QuestionResponseDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gestion-project-ao/:id/JournalEntries"
          element={
            <ProtectedRoute>
              <JournalEntries />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gestion-project-ao/:id/JournalEntries/:entryId"
          element={
            <ProtectedRoute>
              <JournalEntryForm />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gestion-project-ao/:id/WorkCharges"
          element={
            <ProtectedRoute>
              <WorkCharges />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gestion-project-ao/:id/SousProjects"
          element={
            <ProtectedRoute>
              <SubProjectMethods />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gestion-project-ao/:id/SousProjects/:subProjectId"
          element={
            <ProtectedRoute>
              <SubProjectFormStepper />
            </ProtectedRoute>
          }
        />
        {/* end of projectAO */}

        <Route
          path="/gestion-Materials"
          element={
            <ProtectedRoute>
              <Equipments />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gestion-Materials/:id"
          element={
            <ProtectedRoute>
              <EquipmentForm />
            </ProtectedRoute>
          }
        />

        <Route
          path="/gestion-supplier-Orders"
          element={
            <ProtectedRoute>
              <SupplierOrders />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gestion-supplier-Orders/:id"
          element={
            <ProtectedRoute>
              <SupplierOrderForm />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gestion-company-SubContractor"
          element={
            <ProtectedRoute>
              <CompanySubcontractors />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gestion-company-SubContractor/:id"
          element={
            <ProtectedRoute>
              <CompanySubcontractorForm />
            </ProtectedRoute>
          }
        />

        <Route
          path="/gestion-personnel-cost"
          element={
            <ProtectedRoute>
              <PersonnelCosts />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gestion-personnel-cost/:id"
          element={
            <ProtectedRoute>
              <PersonnelCostForm />
            </ProtectedRoute>
          }
        />

        <Route
          path="/mySpace/post-file"
          element={
            <ProtectedRoute>
              <JobDescriptionDetailForm />
            </ProtectedRoute>
          }
        />
        <Route
          path="/mySpace/org-dataChart"
          element={
            <ProtectedRoute>
              <OrganisationChart />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<NotFound404 />} />
        <Route path="/error" element={<ErrorPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default AppRouter;
