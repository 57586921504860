import React, { useEffect, useState } from 'react';
import DefaultLayout from '@/layout/DefaultLayout';
import Breadcrumb from '@/components/Breadcrumb';
import DefaultInput from '@/components/Input';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import DatePickerDefault from '@/components/DatePicker';
import Spinner from '@/components/Spinner';
import { useNavigate, useParams } from 'react-router-dom';
import Dropdown from '@/components/DropDown';
import { getCompanyId } from '@/services/AuthService';
import { toast } from 'react-hot-toast';
import {
  CompanySubcontractor,
  getCompanySubcontractor,
  getCompanySubcontractors,
  saveCompanySubcontractor,
} from '@/services/CompanySubcontractorService';
import { TaskStatus, TaskStatusOptions } from '@/enums/TaskStatus';
import { Currency, currencyOptions } from '@/enums/Currency';
import { PaymentStatus } from '@/enums/PaymentStatus';
import FormButtons from '@/components/shared/FormButtons';

// Define validation schema using Yup
const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  contact: Yup.string().required('Contact is required'),
  companyName: Yup.string().required('Company Name is required'),
  email: Yup.string().required('Email is required').email('Invalid email'),
  phone: Yup.string().required('Phone number is required'),
  address: Yup.string().required('Address is required'),
  countryId: Yup.number().required('Country is required'),
  taskDetail: Yup.string().required('Task Detail is required'),
  amountHT: Yup.number().required('Amount HT is required'),
  TVA: Yup.number().required('TVA is required'),
  amountTTC: Yup.number().required('Amount TTC is required'),
  currency: Yup.string().required('Currency is required'),
  startDate: Yup.date().required('Start Date is required'),
  endDate: Yup.date().required('End Date is required'),
  status: Yup.string().required('Status is required'),
  invoice: Yup.string().required('Invoice is required'),
  paymentStatus: Yup.string().required('Payment Status is required'),
  serviceType: Yup.string().required('Service Type is required'),
  comment: Yup.string().required('Comment is required'),
});

// Define interface for form values
interface FormValues extends CompanySubcontractor {}

function CompanySubcontractorForm() {
  const { id } = useParams();
  const editForm = id !== 'new';
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Initial form values state
  const [formikValuesState, setFormikValuesState] = useState<FormValues>({
    name: '',
    contact: '',
    companyName: '',
    email: '',
    phone: '',
    address: '',
    countryId: 0,
    taskDetail: '',
    amountHT: 0,
    TVA: 0,
    amountTTC: 0,
    currency: Currency.EUR,
    startDate: new Date(),
    endDate: new Date(),
    status: TaskStatus.InProgress,
    invoice: '',
    paymentStatus: PaymentStatus.Pending,
    serviceType: '',
    comment: '',
    companyId: getCompanyId()!, // Fetch company ID from AuthService or context
  });

  const [loading, setLoading] = useState(false); // Loading state

  // Fetch initial data if editing existing subcontractor
  const {
    data: subcontractorData,
    isLoading: isQueryLoading,
    refetch: refetchSubcontractor,
  } = useQuery<FormValues | undefined>(
    ['Companysubcontractor', id],
    async () => {
      return await getCompanySubcontractor(id); // Replace with actual function
    },
    {
      enabled: editForm,
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
      onSuccess: (subcontractor) => {
        if (subcontractor) {
          setFormikValuesState((prev) => ({ ...prev, ...subcontractor }));
        }
      },
    }
  );

  useEffect(() => {
    if (editForm && subcontractorData) {
      setFormikValuesState((prev) => ({ ...prev, ...subcontractorData }));
    }
  }, [editForm, subcontractorData]);

  // Handle form submission
  const handleSaveSubcontractor = async (
    values: FormValues,
    { resetForm, validateForm }: any
  ) => {
    setLoading(true);

    try {
      // Prepare subcontractor object to save
      const subcontractor: FormValues = {
        ...values,
        companyId: getCompanyId()!, // Fetch company ID from AuthService or context
      };

      // Implement your save function here
      await saveCompanySubcontractor(subcontractor); // Replace with actual function

      // Show success message
      toast.success(
        t('SuccessMessage', {
          name: t('ToastMsg.Subcontractor'),
        })
      );

      // Refetch data if editing existing subcontractor
      if (editForm) {
        refetchSubcontractor();
      }

      // Navigate back to previous page
      navigate(-1);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
      resetForm(); // Reset form values
    }
  };

  return (
    <DefaultLayout>
      {/* Breadcrumb component */}
      <Breadcrumb
        pageName={
          editForm
            ? t('CompanySubcontractorForm.editSubcontractor')
            : t('CompanySubcontractorForm.addSubcontractor')
        }
      />
      {/* Show spinner while loading */}
      {loading ? (
        <Spinner />
      ) : (
        <Formik
          initialValues={formikValuesState}
          validationSchema={validationSchema}
          onSubmit={handleSaveSubcontractor}
          enableReinitialize={editForm}
        >
          {({ values, errors, handleChange, handleSubmit, resetForm }) => (
            <form onSubmit={handleSubmit}>
              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="text"
                    name="name"
                    id="name"
                    placeholder=" "
                    onChange={handleChange}
                    value={values.name}
                    label={t('CompanySubcontractorForm.nameLabel')}
                    errors={errors}
                  />
                </div>
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="text"
                    name="contact"
                    id="contact"
                    placeholder=" "
                    onChange={handleChange}
                    value={values.contact}
                    label={t('CompanySubcontractorForm.contactLabel')}
                    errors={errors}
                  />
                </div>
              </div>
              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="text"
                    name="companyName"
                    id="companyName"
                    placeholder=" "
                    onChange={handleChange}
                    value={values.companyName}
                    label={t('CompanySubcontractorForm.companyNameLabel')}
                    errors={errors}
                  />
                </div>
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="text"
                    name="address"
                    id="address"
                    placeholder=" "
                    onChange={handleChange}
                    value={values.address}
                    label={t('CompanySubcontractorForm.addressLabel')}
                    errors={errors}
                  />
                </div>
              </div>
              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="email"
                    name="email"
                    id="email"
                    placeholder=" "
                    onChange={handleChange}
                    value={values.email}
                    label={t('CompanySubcontractorForm.emailLabel')}
                    errors={errors}
                  />
                </div>
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="text"
                    name="phone"
                    id="phone"
                    placeholder=" "
                    onChange={handleChange}
                    value={values.phone}
                    label={t('CompanySubcontractorForm.phoneLabel')}
                    errors={errors}
                  />
                </div>
              </div>
              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="number"
                    name="countryId"
                    id="countryId"
                    placeholder=" "
                    onChange={handleChange}
                    value={values.countryId}
                    label={t('CompanySubcontractorForm.countryIdLabel')}
                    errors={errors}
                  />
                </div>
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="text"
                    name="taskDetail"
                    id="taskDetail"
                    placeholder=" "
                    onChange={handleChange}
                    value={values.taskDetail}
                    label={t('CompanySubcontractorForm.taskDetailLabel')}
                    errors={errors}
                  />
                </div>
              </div>
              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="number"
                    name="amountHT"
                    id="amountHT"
                    placeholder=" "
                    onChange={handleChange}
                    value={values.amountHT}
                    label={t('CompanySubcontractorForm.amountHTLabel')}
                    errors={errors}
                  />
                </div>
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="number"
                    name="TVA"
                    id="TVA"
                    placeholder=" "
                    onChange={handleChange}
                    value={values.TVA}
                    label={t('CompanySubcontractorForm.TVALabel')}
                    errors={errors}
                  />
                </div>
              </div>
              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="number"
                    name="amountTTC"
                    id="amountTTC"
                    placeholder=" "
                    onChange={handleChange}
                    value={values.amountTTC}
                    label={t('CompanySubcontractorForm.amountTTCLabel')}
                    errors={errors}
                  />
                </div>
                <div className="group relative z-0 mb-6 w-full">
                  <Dropdown
                    labelDir="Above"
                    name="currency"
                    id="currency"
                    label={t('CompanySubcontractorForm.currencyLabel')}
                    onChange={handleChange}
                    value={values.currency}
                    errors={errors.currency}
                    optionValue="id"
                    labelClassName="pl-0"
                    options={currencyOptions ?? []}
                  />
                </div>
              </div>
              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="group relative z-0 mb-6 w-full">
                  <DatePickerDefault
                    labelDir="Above"
                    label={t('CompanySubcontractorForm.startDateLabel')}
                    id="startDate"
                    name="startDate"
                    value={new Date(values.startDate)}
                    defaultDate={new Date(values.startDate)}
                    onChange={(date) => {
                      handleChange({
                        target: {
                          name: 'startDate',
                          value: date,
                        },
                      });
                    }}
                    errors={errors.startDate}
                  />
                </div>
                <div className="group relative z-0 mb-6 w-full">
                  <DatePickerDefault
                    labelDir="Above"
                    label={t('CompanySubcontractorForm.endDateLabel')}
                    id="endDate"
                    name="endDate"
                    value={new Date(values.endDate)}
                    defaultDate={new Date(values.endDate)}
                    onChange={(date) => {
                      handleChange({
                        target: {
                          name: 'endDate',
                          value: date,
                        },
                      });
                    }}
                    errors={errors.endDate}
                  />
                </div>
              </div>
              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="group relative z-0 mb-6 w-full">
                  <Dropdown
                    name="status"
                    id="status"
                    label={t('CompanySubcontractorForm.statusLabel')}
                    value={values.status}
                    options={TaskStatusOptions}
                    onChange={handleChange}
                    error={errors.status}
                    translatedNameSpace="TaskStatus"
                    isTranslated={true}
                    optionTransalatedKey="name"
                  />
                </div>
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="text"
                    name="invoice"
                    id="invoice"
                    placeholder=" "
                    onChange={handleChange}
                    value={values.invoice}
                    label={t('CompanySubcontractorForm.invoiceLabel')}
                    errors={errors}
                  />
                </div>
              </div>
              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="text"
                    name="paymentStatus"
                    id="paymentStatus"
                    placeholder=" "
                    onChange={handleChange}
                    value={values.paymentStatus}
                    label={t('CompanySubcontractorForm.paymentStatusLabel')}
                    errors={errors}
                  />
                </div>
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="text"
                    name="serviceType"
                    id="serviceType"
                    placeholder=" "
                    onChange={handleChange}
                    value={values.serviceType}
                    label={t('CompanySubcontractorForm.serviceTypeLabel')}
                    errors={errors}
                  />
                </div>
              </div>
              <div className="group relative z-0 mb-6 w-full">
                <DefaultInput
                  type="text"
                  name="comment"
                  id="comment"
                  placeholder=" "
                  onChange={handleChange}
                  value={values.comment}
                  label={t('CompanySubcontractorForm.commentLabel')}
                  errors={errors}
                />
              </div>
              <FormButtons resetForm={resetForm} loading={loading} />
            </form>
          )}
        </Formik>
      )}
    </DefaultLayout>
  );
}

export default CompanySubcontractorForm;
