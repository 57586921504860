import Pagination from '@/components/Pagination';
import Spinner from '@/components/Spinner';
import {
  Event,
  OffDaysType,
  ResourceType,
  Timeline,
  WeekendsType,
} from '@/components/TimeLine';
import { useAuth } from '@/context/AuthContext';
import { RequestLeaveState } from '@/enums/RequestLeaveState';
import { OffDay, WorkCalendar } from '@/services/WorkCalendarsService';
import {
  Employee,
  getEmployee,
  EmployeesResponse,
  getEmployees,
  getEmployeeTeamMemebers,
  getEmployeeByDepartmentId,
} from '@/services/EmployeeService';
import {
  getRequestLeavesByDepartmentId,
  getRequestLeavesByTeam,
  getRequestLeavesByTeamLeaderId,
} from '@/services/RequestLeavesService';
import {
  convertEmployeesToResources,
  convertRequestLeavesToEvents,
} from '@/utils/CalendarHelper';
import { useQuery } from '@tanstack/react-query';
import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DaysCalendarColor } from '@/variables/const';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import * as XLSX from 'xlsx';
import ReactPDF from '@react-pdf/renderer';
import PdfIcon from '/src/images/icon/pdf.svg';
import ExcelIcon from '/src/images/icon/excel.svg';
import { format } from 'date-fns'; // Import date-fns for formatting
import ChartTwo, { ChartTwoState } from '@/components/EmployeesAbsenseChart';
import moment from 'moment';
import EmployeesAbsenseChart from '@/components/EmployeesAbsenseChart';
import generateExcel, { addStyle, generatePDF } from '@/utils/fileHelper';

export interface DepartmentCalendarProps {
  workCalendar?: WorkCalendar;
}
const DepartmentCalendar = ({ workCalendar }: DepartmentCalendarProps) => {
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [filterdresourcesList, setResourcesList] = useState<ResourceType[]>([]);
  const [allfilterdresourcesList, setAllResourcesList] = useState<
    ResourceType[]
  >([]);
  const [searchResource, setSearchResource] = useState<string>('');
  const { t, i18n } = useTranslation();

  const [events, setEvents] = useState<Event[]>([]);
  const [weekendDays, setWeekendDays] = useState<WeekendsType[]>([]);
  const [allWeekendDays, setAllWeekendDays] = useState<WeekendsType[]>([]);

  const { user } = useAuth();
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [employeeAbsenseCountsPerDay, setEmployeeAbsenseCountsPerDay] =
    useState<any[]>([]);

  // const {
  //   data: employee,
  //   isSuccess,
  //   isLoading,
  //   refetch: refetchEmployee,
  // } = useQuery<Employee | undefined>(
  //   ['employees', pageIndex, pageSize, searchResource],
  //   () => getEmployee(user?.id!),
  //   {
  //     refetchOnWindowFocus: false,
  //     staleTime: 6000,
  //     keepPreviousData: true,
  //     enabled: user?.id !== null,
  //   }
  // );
  const printRef = React.useRef<HTMLDivElement>();
  const legendRef = React.useRef<HTMLDivElement>();
  const daysInMonth = useMemo(
    () =>
      new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth() + 1,
        0
      ).getDate(),

    [selectedDate]
  );
  const shortDayNames = useMemo(() => {
    return [
      t('Timeline.Sun'),
      t('Timeline.Mon'),
      t('Timeline.Tue'),
      t('Timeline.Wed'),
      t('Timeline.Thu'),
      t('Timeline.Fri'),
      t('Timeline.Sat'),
    ];
  }, [t]);
  const handleExportExcel = async () => {
    if (!employees || !events) return;
    const buffer: Uint8Array = await generateExcel(
      employees?.employees,
      events,
      selectedDate,
      daysInMonth,
      i18n.language,
      user?.fullName,
      t
    );

    const blob: Blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    const url: string = URL.createObjectURL(blob);
    const link: HTMLAnchorElement = document.createElement('a');
    link.href = url;
    link.download = 'timeline.xlsx';
    link.click();

    URL.revokeObjectURL(url);
  };
  const handleDownloadPdf = async () => {
    if (!employees || !events) return;
    const buffer: Uint8Array = await generatePDF(
      employees?.employees,
      events,
      selectedDate,
      daysInMonth,
      i18n.language,
      user?.fullName,
      t
    );

    const blob: Blob = new Blob([buffer], {
      type: 'application/pdf',
    });

    const url: string = URL.createObjectURL(blob);
    const link: HTMLAnchorElement = document.createElement('a');
    link.href = url;
    link.download = 'timeline.pdf';
    link.click();

    URL.revokeObjectURL(url);
  };

  const {
    data: employees,
    isSuccess: isEmplyeesDeparmentSuccess,
    isLoading: isLoadigEmDeparment,
    refetch: refetchDepartmentEmployee,
  } = useQuery<EmployeesResponse | undefined>(
    ['employees', pageIndex, pageSize, searchResource, user?.id],
    () =>
      getEmployeeByDepartmentId({
        fromvalue: pageIndex,
        takevalue: pageSize,
        search: searchResource,
        employeeId: user?.id,
      }),
    {
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
    }
  );

  const {
    data: allEmployees,
    isSuccess: isAllEmplyeesDeparmentSuccess,
    isLoading: isAllLoadigEmDeparment,
    refetch: refetchAllDepartmentEmployee,
  } = useQuery<EmployeesResponse | undefined>(
    ['AllEmployees'],
    () =>
      getEmployeeByDepartmentId({
        fromvalue: 0,
        takevalue: 0,
        search: '',
        employeeId: user?.id,
      }),
    {
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
    }
  );

  const {
    data: requestLeavesData,
    isLoading: requestLeaveLoading,
    isSuccess: isEventSucees,
    refetch: refetchRLEvents,
  } = useQuery(
    ['requestLeavesByDepId', user?.id],
    () => {
      const startOfMonth = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        1
      );
      const endOfMonth = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth() + 1,
        0
      );
      /// we nned to add te last minute ofday
      endOfMonth.setHours(23, 59, 59, 999);

      return getRequestLeavesByTeam({
        fromvalue: 0,
        takevalue: 0,
        search: '',
        employeeId: user?.id,
        requestLeaveState: RequestLeaveState.Approved,
        fromDate: startOfMonth,
        toDate: endOfMonth,
      });
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
      enabled: isEmplyeesDeparmentSuccess,
    }
  );

  useEffect(() => {
    if (isEmplyeesDeparmentSuccess) {
      refetchRLEvents();
    }
  }, [selectedDate, isEmplyeesDeparmentSuccess]);

  useEffect(() => {
    if (isEmplyeesDeparmentSuccess && employees) {
      setResourcesList(convertEmployeesToResources(employees.employees!));
    }
  }, [employees, isEmplyeesDeparmentSuccess]);

  useEffect(() => {
    if (isAllEmplyeesDeparmentSuccess && allEmployees) {
      setAllResourcesList(convertEmployeesToResources(allEmployees.employees!));
    }
  }, [allEmployees, isEmplyeesDeparmentSuccess]);

  useEffect(() => {
    if (requestLeavesData && isEventSucees) {
      const eventsData = convertRequestLeavesToEvents(
        requestLeavesData.requestLeaves!
      );

      setEvents(eventsData);
      //! set Events List
    }
  }, [requestLeavesData, isEventSucees]);
  let pageCount = useMemo(() => {
    return Math.ceil((employees?.count ?? 0) / pageSize);
  }, [pageSize, employees?.count]);

  const nextPage = () => {
    setPageIndex(pageIndex + 1);
  };
  const previousPage = () => setPageIndex(pageIndex - 1);
  const onPageChange = (newPageIndex: number) => setPageIndex(newPageIndex);
  const onPageSizeChange = (newPageSize: number) => {
    setPageIndex(0);
    setPageSize(newPageSize);
  };

  useEffect(() => {
    let daysMapping = {
      monday: t('Timeline.Mon'),
      tuesday: t('Timeline.Tue'),
      wednesday: t('Timeline.Wed'),
      thursday: t('Timeline.Thu'),
      friday: t('Timeline.Fri'),
      saturday: t('Timeline.Sat'),
      sunday: t('Timeline.Sun'),
    };

    const weekends =
      employees?.employees?.map((employee) => ({
        employeeId: employee.id!,
        days: Object.keys(daysMapping)
          .filter((day) => employee?.workCalendar[day])
          .map((day) => daysMapping[day]),
      })) ?? [];

    setWeekendDays((prv) => weekends);
  }, [employees, t]);

  useEffect(() => {
    let daysMapping = {
      monday: t('Timeline.Mon'),
      tuesday: t('Timeline.Tue'),
      wednesday: t('Timeline.Wed'),
      thursday: t('Timeline.Thu'),
      friday: t('Timeline.Fri'),
      saturday: t('Timeline.Sat'),
      sunday: t('Timeline.Sun'),
    };

    const weekends =
      allEmployees?.employees?.map((employee) => ({
        employeeId: employee.id!,
        days: Object.keys(daysMapping)
          .filter((day) => employee?.workCalendar[day])
          .map((day) => daysMapping[day]),
      })) ?? [];

    setAllWeekendDays((prv) => weekends);
  }, [allEmployees, t]);

  const holidays: OffDaysType[] = useMemo(() => {
    return (
      employees?.employees
        ?.map((employee) => {
          const employeeHolidays: OffDay[] =
            employee?.workCalendar?.offDays?.filter((x) => x.isPayed) ?? [];
          return employeeHolidays.map((holiday: OffDay) => ({
            employeeId: employee.id!,
            type: 'holiday',
            ...holiday,
          }));
        })
        .flat() ?? []
    );
  }, [employees, selectedDate]);

  const enterpriseClosing: OffDaysType[] = useMemo(() => {
    return (
      employees?.employees
        ?.map((employee) => {
          const employeeClosings: OffDay[] =
            employee?.workCalendar?.offDays?.filter((x) => !x.isPayed) ?? [];
          return employeeClosings.map((closing: OffDay) => ({
            employeeId: employee.id!,
            type: 'enterpriseClosing',
            ...closing,
          }));
        })
        .flat() ?? []
    );
  }, [employees, selectedDate]);

  /* --------  charts ------------*/

  // --- all hoidlays
  const allEmployeesholidays: OffDaysType[] = useMemo(() => {
    return (
      allEmployees?.employees
        ?.map((employee) => {
          const employeeHolidays: OffDay[] =
            employee?.workCalendar?.offDays?.filter((x) => x.isPayed) ?? [];
          return employeeHolidays.map((holiday: OffDay) => ({
            employeeId: employee.id!,
            type: 'holiday',
            ...holiday,
          }));
        })
        .flat() ?? []
    );
  }, [allEmployees, selectedDate]);

  const allEmployeesenterpriseClosing: OffDaysType[] = useMemo(() => {
    return (
      employees?.employees
        ?.map((employee) => {
          const employeeClosings: OffDay[] =
            employee?.workCalendar?.offDays?.filter((x) => !x.isPayed) ?? [];
          return employeeClosings.map((closing: OffDay) => ({
            employeeId: employee.id!,
            type: 'enterpriseClosing',
            ...closing,
          }));
        })
        .flat() ?? []
    );
  }, [allEmployees, selectedDate]);

  //---

  return (
    <div>
      <div className="flex justify-between p-4">
        <div></div>

        <div>
          {/* <button onClick={handleDownloadPdf}>
            <img src={PdfIcon} className="ml-1 inline h-10 w-10 self-end" />
          </button> */}
          <button onClick={handleExportExcel}>
            <ExcelIcon className="ml-1 inline h-10 w-10" />
          </button>
        </div>
      </div>

      <div className="flex  overflow-x-auto dark:border-gray-700 dark:bg-boxdark-2 dark:text-bodydark">
        {isLoadigEmDeparment || requestLeaveLoading ? (
          <Spinner />
        ) : (
          <Timeline
            ref={printRef}
            resourcesLength={filterdresourcesList.length - 1}
            resourcesList={filterdresourcesList}
            searchResource={searchResource}
            setSearchResource={setSearchResource}
            weekendDays={weekendDays}
            myEvents={events}
            holidays={holidays}
            enterpriseClosing={enterpriseClosing}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
          />
        )}
      </div>
      <div
        ref={legendRef}
        className=" mt-5 flex align-bottom   dark:bg-boxdark dark:text-bodydark"
      >
        <div className="flex gap-4 px-2">
          <div
            className={`h-4 w-4 rounded-md ${DaysCalendarColor.holiday}`}
            style={{ boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' }}
          />
          <p className="text-sm">{t('Holiday')}</p>
        </div>
        <div className="flex gap-4 px-2">
          <div
            className={`h-4 w-4 rounded-md ${DaysCalendarColor.weekend}`}
            style={{ boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' }}
          />
          <p className="text-sm">{t('Weekend')}</p>
        </div>
        <div className="flex gap-4 px-2">
          <div
            className={`h-4 w-4 rounded-md ${DaysCalendarColor.enterpriseClosing}`}
            style={{ boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' }}
          />
          <p className="text-sm">{t('Enterprise Closing')}</p>
        </div>
      </div>
      <Pagination
        pageIndex={pageCount === 0 ? -1 : pageIndex}
        pageCount={pageCount}
        pageSize={pageSize}
        onNextPage={nextPage}
        onPreviousPage={previousPage}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
      />
      {isLoadigEmDeparment || requestLeaveLoading ? (
        <Spinner />
      ) : (
        <EmployeesAbsenseChart
          events={events}
          resources={allfilterdresourcesList}
          employees={allEmployees}
          selectedDate={selectedDate}
          enterpriseClosing={allEmployeesenterpriseClosing}
          holidays={allEmployeesholidays}
          weekendDays={allWeekendDays ?? []}
        />
      )}
    </div>
  );
};
export default DepartmentCalendar;

export const PDFDocument = ({ element, legendElement }: any) => (
  <ReactPDF.Document>
    <ReactPDF.Page>
      <div>
        {React.cloneElement(element, {
          style: {
            position: 'static',
            width: '100%',
            height: 'auto',
            overflow: 'visible',
          },
        })}
        {React.cloneElement(legendElement, {
          style: {
            position: 'static',
            width: '100%',
            height: 'auto',
            overflow: 'visible',
          },
        })}
      </div>
    </ReactPDF.Page>
  </ReactPDF.Document>
);
