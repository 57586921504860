import { UploadImagesUrl, siteName } from '@/variables/Urls';
import { useEffect, useState, ChangeEvent } from 'react';
import axios from 'axios';
import Spinner from './Spinner';
import { toast } from 'react-hot-toast';
import { useQuery } from '@tanstack/react-query';
import imageCompression from 'browser-image-compression'; // Import the library
import { env } from 'process';
import { useTranslation } from 'react-i18next';

export interface ImageFile extends File {
  preview: string;
}

export interface ImageUploaderProps {
  handleChange?: (e: ChangeEvent<any>, fn?: any) => void;

  multiple?: boolean;
  value: string;
  id: string;
  name: string;
}

const allowedImageTypes = ['image/png', 'image/jpeg', 'image/bmp'];

const isValidImageType = (file: File) => {
  return allowedImageTypes.includes(file.type);
};
const ImageUploader = ({
  handleChange,
  multiple = false,
  value,
  name,
}: ImageUploaderProps) => {
  const [files, setFiles] = useState<ImageFile[]>([]);
  const { t } = useTranslation();
  const [rawFiles, setRawFiles] = useState<File[]>([]);
  const [loading, setLoading] = useState(false); // Add loading state
  const isDevelopment = import.meta.env.MODE === 'development';

  useEffect(() => {
    if (value && value !== '' && files.length === 0) {
      // Create a fake ImageFile object with the provided URL as preview
      const splitForName = value.split('/');
      const fileName = splitForName[splitForName.length - 1];
      const fileExtension = fileName.split('.').pop()?.toLowerCase();
      let fileType = 'image/jpeg'; // Default to JPEG
      value = isDevelopment ? siteName + value : value;
      if (fileExtension === 'png') {
        fileType = 'image/png';
      } else if (fileExtension === 'bmp') {
        fileType = 'image/bmp';
      }

      const uploadedFile: ImageFile = {
        preview: value,
        name: fileName,
        type: fileType,
        size: 0,
        lastModified: Date.now(),
      };

      addFile(uploadedFile);
    }
  }, [value]);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    let fileList = Array.from(e.target.files || []);

    const validFiles = fileList.filter((file: File) => isValidImageType(file));
    const invalidFiles = fileList.filter(
      (file: File) => !isValidImageType(file)
    );

    if (invalidFiles.length > 0) {
      // Show toast error for invalid file types
      toast.error(
        'Invalid file type. Only PNG, JPG, and BMP images are allowed.'
      );
    }

    AddAndConstructFiles(validFiles);
    e.target.value = '';
  };

  const AddAndConstructFiles = (fileList: File[]) => {
    fileList.forEach((file: File) => {
      const reader = new FileReader();
      setRawFiles((prevFiles) => [...prevFiles, file]);
      reader.addEventListener('load', () => {
        //@ts-ignore
        const uploadedFile: ImageFile = {
          ...file,
          name: file.name,
          type: file.type,
          size: file.size,
          lastModified: file.lastModified,
          preview: reader.result as string,
        };

        addFile(uploadedFile);
      });

      reader.readAsDataURL(file);
    });
  };

  const addFile = (file: ImageFile) => {
    if (!multiple) {
      setFiles([file]);
      return;
    }
    setFiles((prevFiles) => [...prevFiles, file]);
  };

  const removeFile = (index: number) => {
    const removedFile = files[index];
    setFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(index, 1);
      return updatedFiles;
    });
    setRawFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(index, 1);
      return updatedFiles;
    });

    URL.revokeObjectURL(removedFile.preview);
  };

  const handleCancel = () => {
    setFiles([]);
  };

  const handleUpload = async () => {
    if (files.length === 0) {
      toast.error('Please select an image before uploading.');
      return;
    }
    setLoading(true);
    if (!multiple && rawFiles.length > 0) {
      let formData = new FormData();
      const file = rawFiles[0];

      const compressedFile = await imageCompression(file, {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
      });

      const compressedAsFile = new File([compressedFile], file.name, {
        type: file.type,
        lastModified: file.lastModified,
      });

      formData.append('image', compressedAsFile);

      axios
        .post(UploadImagesUrl, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then((response) => {
          if (response.status === 200) {
            const fullUrl = response.data;
            //@ts-ignore
            handleChange!({
              target: {
                name: name,
                value: fullUrl,
              },
            });
          }
        })
        .catch(() => {
          // Handle error
        })
        .finally(() => {
          setLoading(false);
        });
    }

    //handleImageUpload(files);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const fileList = Array.from(e.dataTransfer.files);
    AddAndConstructFiles(fileList);
  };
  return (
    <div
      className="h-screen 
     dark:border-strokedark
      dark:bg-boxdark sm:px-8 sm:py-8 md:px-16"
    >
      {
        <main className="container mx-auto h-full max-w-screen-lg">
          <article
            aria-label="File Upload Modal"
            className=" flex h-full flex-col rounded-md bg-white shadow-xl dark:border-strokedark dark:bg-boxdark"
            onDrop={handleDrop}
            onDragOver={(e) => e.preventDefault()}
            onDragLeave={(e) => e.preventDefault()}
            onDragEnter={(e) => e.preventDefault()}
          >
            {!loading && (
              <div
                id="overlay"
                className="pointer-events-none absolute left-0 top-0 z-20 flex h-full w-full flex-col items-center justify-center rounded-md"
              >
                <i>
                  <svg
                    className="mb-3 h-12 w-12 fill-current text-blue-700"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path d="M19.479 10.092c-.212-3.951-3.473-7.092-7.479-7.092-4.005 0-7.267 3.141-7.479 7.092-2.57.463-4.521 2.706-4.521 5.408 0 3.037 2.463 5.5 5.5 5.5h13c3.037 0 5.5-2.463 5.5-5.5 0-2.702-1.951-4.945-4.521-5.408zm-7.479-1.092l4 4h-3v4h-2v-4h-3l4-4z" />
                  </svg>
                </i>
                <p className="text-lg text-blue-700">
                  {t('ImageUploader.UploadOverlayText')}
                </p>
              </div>
            )}

            <section className="flex  h-full w-full flex-col overflow-auto p-8">
              <header className="flex flex-col items-center justify-center border-2 border-dashed border-gray-400 py-12">
                <p className="mb-3 flex flex-wrap justify-center font-semibold text-gray-900">
                  <span>{t('ImageUploader.DragAndDropFiles')}</span>&nbsp;
                  <span>{t('ImageUploader.DropFilesToUpload')}</span>
                </p>
                <input
                  id="hidden-input"
                  type="file"
                  multiple
                  className="hidden"
                  onChange={handleFileChange}
                />
                <button
                  type="button"
                  disabled={loading}
                  id="button"
                  className="focus:shadow-outline mt-2 
              rounded-sm bg-gray-200 px-3 py-1 hover:bg-gray-300 focus:outline-none"
                  onClick={() => {
                    document.getElementById('hidden-input')?.click();
                  }}
                >
                  {loading ? <Spinner /> : 'Upload a file'}
                </button>
              </header>

              <h1 className="pb-3 pt-8 font-semibold text-gray-900 sm:text-lg">
                {t('FileUploadModal.ToUpload')}
              </h1>

              <ul id="gallery" className="-m-1 flex flex-1 flex-wrap">
                {files.length === 0 ? (
                  <li
                    id="empty"
                    className="flex h-full w-full flex-col 
                items-center justify-center text-center"
                  >
                    <img
                      className="mx-auto w-32"
                      src="https://user-images.githubusercontent.com/507615/54591670-ac0a0180-4a65-11e9-846c-e55ffce0fe7b.png"
                      alt="no data"
                    />
                    <span className="text-small text-gray-500">
                      {t('FileUploadModal.NoFilesSelected')}
                    </span>
                  </li>
                ) : files.length === 1 ? (
                  <li
                    id="empty"
                    className="flex h-full w-full flex-col 
              items-center justify-center text-center"
                  >
                    <article
                      tabIndex={0}
                      className={`
                    
                    hasImage focus:shadow-outline group relative h-full w-full cursor-pointer rounded-md bg-gray-100
                     text-transparent shadow-sm hover:text-white focus:outline-none 
                    `}
                    >
                      <img
                        alt="upload preview"
                        className="img-preview sticky h-full w-full rounded-md bg-fixed object-cover"
                        src={files[0].preview}
                      />

                      <section
                        className={`absolute top-0 z-20 flex h-full w-full
                     flex-col break-words rounded-md px-3 py-2 text-xs
                     ${files.length > 0 ? 'hover:bg-[rgba(5,5,5,0.4)] ' : ''}
                     `}
                      >
                        <h1 className="flex-1 ">{files[0].name}</h1>
                        <div className="flex">
                          <span className="p-1 text-blue-800">
                            <i>
                              <svg
                                className="ml-auto h-4 w-4 fill-current pt-1"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                              >
                                <path d="M15 2v5h5v15h-16v-20h11zm1-2h-14v24h20v-18l-6-6z" />
                              </svg>
                            </i>
                          </span>
                          <p className="size p-1 text-xs text-gray-700">
                            {files[0].size > 1024
                              ? files[0].size > 1048576
                                ? Math.round(files[0].size / 1048576) + 'mb'
                                : Math.round(files[0].size / 1024) + 'kb'
                              : files[0].size + 'b'}
                          </p>
                          <button
                            className={`delete ml-auto 
                          rounded-md p-1 text-gray-800 hover:bg-gray-300 
                          focus:outline-none
                          ${
                            files.length > 0 ? 'hover:bg-[rgba(5,5,5,0.4)]' : ''
                          }
                          `}
                            onClick={() => removeFile(0)}
                          >
                            <svg
                              className="pointer-events-none ml-auto h-4 w-4 fill-current"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                            >
                              <path
                                className="pointer-events-none"
                                d="M3 6l3 18h12l3-18h-18zm19-4v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.316c0 .901.73 2 1.631 2h5.711z"
                              />
                            </svg>
                          </button>
                        </div>
                      </section>
                    </article>
                  </li>
                ) : (
                  files.map((file, index) => (
                    <li
                      key={index}
                      className={`xl:w-1/8 block h-24 w-1/2 p-1 sm:w-1/3 md:w-1/4
                   lg:w-1/6`}
                    >
                      <article
                        tabIndex={0}
                        className={`
                    
                    hasImage focus:shadow-outline group relative h-full w-full cursor-pointer rounded-md bg-gray-100
                     text-transparent shadow-sm hover:text-white focus:outline-none 
                    `}
                      >
                        <img
                          alt="upload preview"
                          className="img-preview sticky h-full w-full rounded-md bg-fixed object-cover"
                          src={file.preview}
                        />

                        <section
                          className={`absolute top-0 z-20 flex h-full w-full
                     flex-col break-words rounded-md px-3 py-2 text-xs
                     ${files.length > 0 ? 'hover:bg-[rgba(5,5,5,0.4)] ' : ''}
                     `}
                        >
                          <h1 className="flex-1 ">{file.name}</h1>
                          <div className="flex">
                            <span className="p-1 text-blue-800">
                              <i>
                                <svg
                                  className="ml-auto h-4 w-4 fill-current pt-1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M15 2v5h5v15h-16v-20h11zm1-2h-14v24h20v-18l-6-6z" />
                                </svg>
                              </i>
                            </span>
                            <p className="size p-1 text-xs text-gray-700">
                              {file.size > 1024
                                ? file.size > 1048576
                                  ? Math.round(file.size / 1048576) + 'mb'
                                  : Math.round(file.size / 1024) + 'kb'
                                : file.size + 'b'}
                            </p>
                            <button
                              className={`delete ml-auto 
                          rounded-md p-1 text-gray-800 hover:bg-gray-300 
                          focus:outline-none
                          ${
                            files.length > 0 ? 'hover:bg-[rgba(5,5,5,0.4)]' : ''
                          }
                          `}
                              onClick={() => removeFile(index)}
                            >
                              <svg
                                className="pointer-events-none ml-auto h-4 w-4 fill-current"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  className="pointer-events-none"
                                  d="M3 6l3 18h12l3-18h-18zm19-4v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.316c0 .901.73 2 1.631 2h5.711z"
                                />
                              </svg>
                            </button>
                          </div>
                        </section>
                      </article>
                    </li>
                  ))
                )}
              </ul>
            </section>

            <footer className="flex justify-end px-8 pb-8 pt-4">
              <button
                id="submit"
                type="button"
                disabled={loading}
                className="focus:shadow-outline  rounded-sm bg-blue-700 px-3 py-1 text-white hover:bg-blue-500 focus:outline-none"
                onClick={handleUpload}
              >
                {loading ? <Spinner /> : t('FileUploadModal.UploadNow')}
              </button>
              <button
                type="button"
                id="cancelPicker"
                className="focus:shadow-outline ml-3 rounded-sm px-3 py-1 hover:bg-gray-300 focus:outline-none"
                onClick={handleCancel}
              >
                {t('FileUploadModal.Cancel')}
              </button>
            </footer>
          </article>
        </main>
      }
    </div>
  );
};

export default ImageUploader;
