import React, { useMemo, useState } from 'react';
import DefaultLayout from '@/layout/DefaultLayout';
import Breadcrumb from '@/components/Breadcrumb';
import { useQueryClient, useQuery, useMutation } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import DynamicTable from '@/components/DynamicTable';
import Pagination from '@/components/Pagination';
import Spinner from '@/components/Spinner';
import {
  DeleteButton,
  EditButton,
  GreenButton,
  PrimaryButton,
  PurpleButton,
} from '@/components/shared/Button';
import DeleteConfirmationModal from '@/components/shared/DeleteConfirmationModal';

import { useTranslation } from 'react-i18next';
import { RootState, store } from '@/state';
import {
  deleteSubProjectEquipment,
  saveSubProjectEquipmentsAsync,
} from '@/state/slices/subProjectMethodSlice';
import { ThunkDispatch, AnyAction } from '@reduxjs/toolkit';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { PlusCircleIcon } from '@heroicons/react/solid';
import SubProjectEquipmentModal, {
  ModalProps,
} from './SubProjectEquipmentModal';
import { formatCurrency } from '@/utils/helpers';
import { Currency } from '@/enums/Currency';
import {
  SubProjectEquipment,
  SubProjectEquipmentsPayload,
} from '@/services/SubProjectMethodsService';

function SubProjectEquipments() {
  const { id } = useParams();
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState<string>('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEquipmentModal, setIsEquipmentModal] = useState(false);
  const [initEquipmentData, setInitEquipmentDate] =
    useState<SubProjectEquipment>();
  const [selectedRow, setSelectedRow] = useState('');
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch();

  const { data, status } = useSelector(
    (state: RootState) => state.subProjectMethod!
  );
  let projectCurrency = data?.currency ?? Currency.EUR;
  let subProjectId = data?.id;
  let subProjectEquipments = useMemo(
    () => data?.subProjectEquipments ?? [],
    [data, status]
  );

  const ActionButtonsCell = ({ row, value }: any) => (
    <div>
      <DeleteButton
        onClick={() => {
          setSelectedRow(value);
          setIsModalVisible(true);
        }}
      >
        {t('SubProjectEquipments.deleteButton')}
      </DeleteButton>
      <EditButton
        type="button"
        onClick={() => {
          setInitEquipmentDate((pr) => row.original);
          setIsEquipmentModal(true);
        }}
      >
        {t('SubProjectEquipments.editButton')}
      </EditButton>
    </div>
  );

  const handleCancelDelete = () => {
    setIsModalVisible(false);
  };

  const handleDeleteConfirmation = () => {
    handleDeleteSubProjectEquipment.mutate(selectedRow);
    setIsModalVisible(false);
  };

  const columns = useMemo(
    () => [
      {
        Header: t('SubProjectEquipments.actionsColumn'),
        accessor: 'id',
        Cell: ({ row, cell: { value } }: any) => (
          <ActionButtonsCell value={value} row={row} />
        ),
        Footer: (infos) => {
          return <>Total</>;
        },
      },
      {
        Header: t('SubProjectEquipments.equipmentIdColumn'),
        accessor: 'name',
      },
      {
        Header: t('SubProjectEquipments.descriptionColumn'),
        accessor: 'description',
        Footer: (infos) => {
          return <></>;
        },
      },

      {
        Header: t('SubProjectEquipments.isLocatedColumn'),
        accessor: 'isLocated',
        Cell: ({ cell: { value } }: any) => (
          <span>
            {value
              ? t('SubProjectEquipments.yes')
              : t('SubProjectEquipments.no')}
          </span>
        ),
        Footer: (infos) => {
          return <></>;
        },
      },
      {
        Header: t('SubProjectEquipments.trendColumn'),
        accessor: 'trend',
        Footer: (infos) => {
          return <></>;
        },
      },
      {
        Header: t('SubProjectEquipments.quantityColumn'),
        accessor: 'quantity',
        Footer: (infos) => {
          var total = useMemo(
            () => infos.rows.reduce((sum, row) => row.values.quantity + sum, 0),
            [infos.rows]
          );
          return <>{total}</>;
        },
      },
      {
        Header: t('SubProjectEquipments.unitPriceColumn'),
        accessor: 'unitPrice',
        Cell: ({ row, cell: { value } }: any) => {
          var currency = row.original['equipmentCurrency'];
          return <span> {formatCurrency(value, currency)} </span>;
        },
        Footer: (infos) => {
          return <></>;
        },
      },
      {
        Header: t('SubProjectEquipments.totalPriceColumn'),
        accessor: 'totalPrice',
        Cell: ({ row, cell: { value } }: any) => {
          var currency = row.original['equipmentCurrency'];
          return <span> {formatCurrency(value, currency)} </span>;
        },
        Footer: (infos) => {
          var total = useMemo(
            () =>
              infos.rows.reduce(
                (sum, row) =>
                  parseFloat(row.values.totalPrice) + parseFloat(sum),
                0
              ),
            [infos.rows]
          );
          return <>{total.toFixed(4)}</>;
        },
      },
      {
        Header: t('SubProjectEquipments.exchangeRateColumn'),
        accessor: 'exchangeRate',
        Footer: (infos) => {
          return <></>;
        },
      },
      {
        Header: t('SubProjectEquipments.TotalWithCurrencyColumn'),
        accessor: 'totalToCurrency',
        Cell: ({ cell: { value } }: any) => (
          <span> {formatCurrency(value, projectCurrency)} </span>
        ),
        Footer: (infos) => {
          var total = useMemo(
            () =>
              infos.rows.reduce(
                (sum, row) =>
                  parseFloat(row.values.totalToCurrency) + parseFloat(sum),
                0
              ),
            [infos.rows]
          );
          return <>{formatCurrency(total.toFixed(4), projectCurrency)}</>;
        },
      },
      {
        Header: t('SubProjectEquipments.commentsColumn'),
        accessor: 'comment',
      },
    ],
    [t, projectCurrency]
  );

  const queryClient = useQueryClient();

  const handleDeleteSubProjectEquipment = useMutation(
    async (id: string) => {
      await dispatch(deleteSubProjectEquipment(id));
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          'subProjectEquipments',
          pageIndex,
          pageSize,
          search,
        ]);
      },
    }
  );

  const pageCount = useMemo(() => {
    return Math.ceil((subProjectEquipments?.length ?? 0) / pageSize);
  }, [pageSize, subProjectEquipments]);

  const nextPage = () => setPageIndex(pageIndex + 1);
  const previousPage = () => setPageIndex(pageIndex - 1);
  const onPageChange = (newPageIndex: number) => setPageIndex(newPageIndex);
  const onPageSizeChange = (newPageSize: number) => {
    setPageIndex(0);
    setPageSize(newPageSize);
  };

  const handleSaveEquipments = async () => {
    var payload: SubProjectEquipmentsPayload = {
      subProjectEquipments: subProjectEquipments.map((sm) => ({
        ...sm,
        id: sm.id?.includes('new') ? null : sm.id,
      })),
      subProjectMethodId: data?.id!,
    };
    const result = await dispatch(saveSubProjectEquipmentsAsync(payload));
  };
  return (
    <div>
      <PrimaryButton
        type="button"
        onClick={() => {
          setInitEquipmentDate(undefined);
          setIsEquipmentModal(true);
        }}
        className="mt-5"
      >
        {t('expenses.add')}
      </PrimaryButton>

      {subProjectId && (
        <PurpleButton
          type="button"
          onClick={handleSaveEquipments}
          className="mt-5 py-2.5"
        >
          {t('stepper.save')}
        </PurpleButton>
      )}
      {status == 'loading' ? (
        <div className="flex items-center justify-center">
          <Spinner height="3rem" width="3rem" />
        </div>
      ) : (
        <div className="flex flex-col">
          <DynamicTable
            dataCount={subProjectEquipments?.length}
            data={subProjectEquipments ?? []}
            columns={columns}
            setSearch={setSearch}
            exportPdf={false}
            exportExcel={false}
            hideToolbar={true}
          />
          {isModalVisible && (
            <DeleteConfirmationModal
              isOpen={isModalVisible}
              onDelete={handleDeleteConfirmation}
              onCancel={handleCancelDelete}
            />
          )}
          {isEquipmentModal && (
            <SubProjectEquipmentModal
              closeModal={() => setIsEquipmentModal(false)}
              initialData={initEquipmentData as ModalProps}
            />
          )}
          <Pagination
            pageIndex={pageCount === 0 ? -1 : pageIndex}
            pageCount={pageCount}
            pageSize={pageSize}
            onNextPage={nextPage}
            onPreviousPage={previousPage}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
          />
        </div>
      )}
    </div>
  );
}

export default SubProjectEquipments;
