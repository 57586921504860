import React, { useMemo, useState } from 'react';
import DefaultLayout from '@/layout/DefaultLayout';
import Breadcrumb from '@/components/Breadcrumb';
import { useQueryClient, useQuery, useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import DynamicTable, { SelectColumnFilter } from '@/components/DynamicTable';
import Pagination from '@/components/Pagination';
import Spinner from '@/components/Spinner';
import {
  DeleteButton,
  EditButton,
  PrimaryButton,
} from '@/components/shared/Button';
import DeleteConfirmationModal from '@/components/shared/DeleteConfirmationModal';
import { useTranslation } from 'react-i18next';
import {
  ProjectExStateChange,
  changeProjectExState,
  getProjectExpenses,
} from '@/services/ProjectExpensesService';
import moment from 'moment';
import { Currency } from '@/enums/Currency';
import { ExpensesState } from '@/enums/ExpensesState';
import { RootState } from '@/state';
import { ThunkDispatch, AnyAction } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { stateColors } from '@/enums/RequestLeaveState';
import { useAuth } from '@/context/AuthContext';
import { UserRoles } from '@/enums/UsersRole';
import { resetPEXState } from '@/state/slices/projectExpensesSlice';

function ProjectExpenses() {
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState<string>('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isRejectModalVisible, setIsRejectModalVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState('');
  const navigate = useNavigate();
  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch();
  const { user } = useAuth();

  const { t } = useTranslation();

  // const handleApproveRequest = async () => {
  //   // !!!handle approve request
  //   setLoading(true);
  //   const pxState: ProjectExStateChange = {
  //     id: id!,
  //     state: RequestLeaveState.Approved,
  //     comment: '',
  //   };
  //   const data = await changeProjectExState(pxState);
  //   setLoading(false);
  //   setIsRejectModalVisible(false);
  //   //refetch();
  // };
  const ActionButtonsCell = ({ value, row }: any) => {
    var state = row.original['state'];
    var teamLeader = row.original['teamLeaderId'];
    var isTeamLeader =
      user !== null &&
      (user?.role == UserRoles.Admin || user?.id == teamLeader);

    dispatch(resetPEXState());
    // if (isTeamLeader) {
    //   return state !== ExpensesState.Pending ? (
    //     <div>
    //       <PrimaryButton
    //         type="button"
    //         onClick={() => {
    //           navigate(`Details/${value}`);
    //         }}
    //       >
    //         {t('Appointments.Details')}
    //       </PrimaryButton>
    //     </div>
    //   ) : (
    //     <div className="flex items-end justify-end">
    //       <EditButton onClick={() => handleApproveRequest()}>
    //         {t('Requestleave.Approve')}
    //       </EditButton>

    //       <DeleteButton
    //         type="button"
    //         onClick={() => {
    //           setIsRejectModalVisible(true);
    //         }}
    //       >
    //         {t('Requestleave.Reject')}
    //       </DeleteButton>
    //     </div>
    //   );
    // }

    if (isTeamLeader) {
      return (
        <div>
          <PrimaryButton
            type="button"
            onClick={() => {
              navigate(`Details/${value}`);
            }}
          >
            {t('Appointments.Details')}
          </PrimaryButton>
        </div>
      );
    }
    return state !== ExpensesState.Pending ? (
      <div>
        <PrimaryButton
          type="button"
          onClick={() => {
            navigate(`Details/${value}`);
          }}
        >
          {t('Appointments.Details')}
        </PrimaryButton>
      </div>
    ) : (
      <div>
        <DeleteButton
          onClick={() => {
            setSelectedRow(value);
            setIsModalVisible(true);
          }}
        >
          {t('ProjectExpenses.deleteButton')}
        </DeleteButton>

        <EditButton
          type="button"
          onClick={() => {
            navigate(`${value}`);
          }}
        >
          {t('ProjectExpenses.editButton')}
        </EditButton>
      </div>
    );
  };

  const handleCancelDelete = () => {
    setIsModalVisible(false);
  };

  const handleDeleteConfirmation = () => {
    handleDeleteProjectExpense.mutate(selectedRow);
    setIsModalVisible(false);
  };

  const columns = useMemo(
    () => [
      {
        Header: t('ProjectExpenses.actionsColumn'),
        accessor: 'id',
        Cell: ({ row, cell: { value } }: any) => (
          <ActionButtonsCell
            value={value}
            row={row}
            handleDelete={handleDeleteProjectExpense}
          />
        ),
      },
      {
        Header: t('ProjectExpenses.titleColumn'),
        accessor: 'projectExpenseTitle',
      },

      {
        Header: t('ProjectExpenses.stateColumn'),
        accessor: 'state', // Assuming state is a string, adjust accessor accordingly

        Cell: ({ cell: { value } }) => {
          const frontendState = ExpensesState[value];
          const bgColor = stateColors[frontendState] || 'bg-gray-300';

          return (
            <div
              className={`rounded-md text-center ${bgColor} px-2.5  py-2.5 text-sm text-white`}
            >
              {frontendState !== null
                ? t(`RequestLeaveState.${frontendState}`)
                : ''}
            </div>
          );
        }, // If state is filterable, provide appropriate filter component
      },
      {
        Header: t('ProjectExpenses.currencyColumn'),
        accessor: 'projectExpenseCurrency',

        Cell: ({ cell: { value } }) => {
          return <div>{Currency[value]}</div>;
        },
      },
      {
        Header: t('ProjectExpenses.totalColumn'),
        accessor: 'total',
        Cell: ({ row, cell: { value } }) => {
          const currencyTxt = Currency[row.original['projectExpenseCurrency']];
          return <div>{value + ' ' + currencyTxt}</div>;
        },
      },
      {
        Header: t('ProjectExpenses.employeeIdColumn'),
        accessor: 'employeeFullName',
      },
      {
        Header: t('ProjectExpenses.projectTitleColumn'),
        accessor: 'projectTitle',
      },
      {
        Header: t('ProjectExpenses.missionStartDateColumn'),
        accessor: 'missionStartDate',
        Cell: ({ cell: { value } }) => {
          const formattedValue = moment(value).format('YYYY-MM-DD'); // Customize the format as you need
          return <div>{formattedValue}</div>;
        },
      },
      {
        Header: t('ProjectExpenses.missionEndDateColumn'),
        accessor: 'missionEndDate',
        Cell: ({ cell: { value } }) => {
          const formattedValue = moment(value).format('YYYY-MM-DD'); // Customize the format as you need
          return <div>{formattedValue}</div>;
        },
      },
      {
        Header: t('ProjectExpenses.commentsColumn'),
        accessor: 'comments',
      },
    ],
    [t]
  );

  const queryClient = useQueryClient();

  const {
    isLoading,
    isError,
    data: projectExpenses,
  } = useQuery<any>(
    ['projectExpenses', pageIndex, pageSize, search, user?.id],
    () => {
      if (user?.role == UserRoles.Admin) {
        return getProjectExpenses({
          fromvalue: pageIndex,
          takevalue: pageSize,
          search: search,
        });
      }
      if (user?.isTeamLeader == 'True') {
        return getProjectExpenses({
          fromvalue: pageIndex,
          takevalue: pageSize,
          search: search,
          teamLeaderId: user?.id,
        });
      }

      return getProjectExpenses({
        fromvalue: pageIndex,
        takevalue: pageSize,
        search: search,
        employeeId: user?.id,
      });
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
    }
  );

  const handleDeleteProjectExpense = useMutation(
    async (id: string) => {
      // Handle delete logic here
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          'projectExpenses',
          pageIndex,
          pageSize,
          search,
        ]);
      },
    }
  );

  const pageCount = useMemo(() => {
    return Math.ceil((projectExpenses?.count ?? 0) / pageSize);
  }, [pageSize, projectExpenses]);
  const nextPage = () => setPageIndex(pageIndex + 1);
  const previousPage = () => setPageIndex(pageIndex - 1);
  const onPageChange = (newPageIndex: number) => setPageIndex(newPageIndex);
  const onPageSizeChange = (newPageSize: number) => {
    setPageIndex(0);
    setPageSize(newPageSize);
  };

  return (
    <DefaultLayout>
      <Breadcrumb
        pageName={t('ProjectExpenses.pageName')}
        addbtn={
          user?.role !== UserRoles.Admin && user?.role !== UserRoles.SuperAdmin
        }
        addRouter="new"
      />
      {isLoading ? (
        <div className="flex items-center justify-center">
          <Spinner height="3rem" width="3rem" />
        </div>
      ) : (
        <div className="flex flex-col">
          <DynamicTable
            dataCount={projectExpenses?.count}
            data={projectExpenses?.projectExpenses ?? []}
            columns={columns}
            setSearch={setSearch}
            exportPdf={false}
            exportExcel={false}
          />
          {isModalVisible && (
            <DeleteConfirmationModal
              isOpen={isModalVisible}
              onDelete={handleDeleteConfirmation}
              onCancel={handleCancelDelete}
            />
          )}
          <Pagination
            pageIndex={pageCount === 0 ? -1 : pageIndex}
            pageCount={pageCount}
            pageSize={pageSize}
            onNextPage={nextPage}
            onPreviousPage={previousPage}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
          />
        </div>
      )}
    </DefaultLayout>
  );
}

export default ProjectExpenses;
