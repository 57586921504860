import { useEffect, useMemo, useState } from 'react';
import DefaultLayout from '@/layout/DefaultLayout';
import Breadcrumb from '@/components/Breadcrumb';
import { useQueryClient, useQuery, useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import DynamicTable, { SelectColumnFilter } from '@/components/DynamicTable';
import Pagination from '@/components/Pagination';
import Spinner from '@/components/Spinner';
import {
  DeleteButton,
  EditButton,
  PrimaryButton,
} from '@/components/shared/Button';
import { PaperAirplaneIcon } from '@heroicons/react/solid';

import DeleteConfirmationModal from '@/components/shared/DeleteConfirmationModal';
import {
  getTrainingRequests,
  TrainingRequestResponse,
  getTrainingRequestsByEmployeeId,
  deleteTrainingRequest,
} from '@/services/TrainingRequestService';
import { useTranslation } from 'react-i18next';
import { TableState } from 'react-table';
import moment from 'moment';
import { UserRoles } from '@/enums/UsersRole';
import { useAuth } from '@/context/AuthContext';
import { TrainingRequestState } from '@/enums/TrainingRequestState';
import { stateColors } from '@/enums/RequestLeaveState';
import { saveTrainingSatisfactionForm } from '@/services/TrainingSatisfactionFormService';
import { UserResponsibility } from '@/enums/UserResponsibility';
import {
  DocumentRequestResponse,
  deleteDocRequest,
  getDocumentRequests,
} from '@/services/DocumentRequestsService';
import { DocumentRequestStatus } from '@/enums/DocumentRequestStatus';
import { DocumentTypeEnum } from '@/enums/DocumentTypeEnum';

function DocumentRequests() {
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState<string>('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState('');
  const [docRequestsData, setDocRequestsData] =
    useState<DocumentRequestResponse>();
  const { user } = useAuth();

  const [isTRLoading, setIsTRLoading] = useState(true);

  const navigate = useNavigate();

  const isRHAdmin =
    user?.responsibilityClaim ===
      UserResponsibility[UserResponsibility.GestionDesRH].toString() ||
    user?.role === UserRoles.Admin;
  const { t } = useTranslation();

  const ActionButtonsCell = ({
    value,
    state,
    teamLeaderId,
    trainingAdminId,
    employeeId,
  }: any) => {
    // const isOwnRequest =
    //   value === user?.id &&
    //   (user?.id === trainingAdminId || user?.isTeamLeader == 'True');

    const renderDetailButton = () => (
      <PrimaryButton
        type="button"
        onClick={() => {
          navigate(`details/${value}`);
        }}
      >
        {t('TrainingRequests.Details')}
      </PrimaryButton>
    );

    const renderEditAndDeleteButtons = () => (
      <div>
        <DeleteButton
          onClick={() => {
            setSelectedRow(value);
            setIsModalVisible(true);
          }}
        >
          {t('TrainingRequests.deleteButton')}
        </DeleteButton>

        <EditButton
          type="button"
          onClick={() => {
            navigate(`${value}`);
          }}
        >
          {t('TrainingRequests.editButton')}
        </EditButton>
      </div>
    );

    // if (isTeamLeaderOrTrainingAdmin) {
    //   if (isOwnRequest) {
    //     return state === TrainingRequestState.Pending
    //       ? renderEditAndDeleteButtons()
    //       : renderDetailButton();
    //   } else {
    //     return renderDetailButton();
    //   }
    // } else {
    return state === DocumentRequestStatus.Pending && user?.id === employeeId
      ? renderEditAndDeleteButtons()
      : renderDetailButton();
    //}
  };

  const handleCancelDelete = () => {
    setIsModalVisible(false);
  };

  const handleDeleteConfirmation = () => {
    handleDeleteDocRequest.mutate(selectedRow);
    setIsModalVisible(false);
  };

  const columns = useMemo(
    () => [
      {
        Header: t('DocRequests.actionsColumn'),
        accessor: 'id',
        Cell: ({ row, cell: { value } }: any) => (
          <ActionButtonsCell
            state={row.original['status']}
            employeeId={row.original['employeeId']}
            value={value}
            teamLeaderId={row.original['teamLeaderId']}
            trainingAdminId={row.original['trainingAdminId']}
          />
        ),
      },
      {
        Header: t('DocRequests.employeeFullName'),
        accessor: 'employeeFullName',
        Filter: SelectColumnFilter,
      },

      {
        Header: t('DocRequests.DocumentType'),
        accessor: 'documentType',
        Cell: ({ cell: { value } }: any) => {
          const type = DocumentTypeEnum[value];
          //@ts-ignore

          return (
            <input
              type="text"
              className="min-w-40 dark:bg-boxdark-2 dark:text-bodydark"
              value={t(`DocumentTypes.${type}`)}
              readOnly
            />
          );
        },
      },
      {
        Header: t('DocRequests.Status'),
        accessor: 'status',
        Cell: ({ cell: { value } }: any) => {
          const frontendState = DocumentRequestStatus[value];
          //@ts-ignore
          const bgColor = stateColors[frontendState] || 'bg-gray-300';

          return (
            <div
              className={`w-50 rounded-md text-center ${bgColor} px-2.5  py-2.5 text-sm text-white`}
            >
              {frontendState !== null
                ? //@ts-ignore
                  t(`DocumentRequestStatus.${frontendState}`)
                : ''}
            </div>
          );
        },
      },

      {
        Header: t('DocRequests.SendingDate'),
        accessor: 'sendingDate',
        Cell: ({ cell: { value } }: any) => {
          const dateValue = moment.utc(value);

          if (dateValue.format('YYYY-MM-DD') === '0001-01-01') {
            return (
              <div className="dark:bg-boxdark-2 dark:text-bodydark">
                {t('NoDateAvailable')}
              </div>
            );
          }

          const formattedValue = dateValue.format('DD/MM/YYYY');
          return (
            <div className="dark:bg-boxdark-2 dark:text-bodydark">
              {formattedValue}
            </div>
          );
        },
      },
      {
        Header: t('DocRequests.DecisionDate'),
        accessor: 'decisionDate',
        Cell: ({ cell: { value } }: any) => {
          const dateValue = moment.utc(value);

          if (dateValue.format('YYYY-MM-DD') === '0001-01-01') {
            return (
              <div className="dark:bg-boxdark-2 dark:text-bodydark">
                {t('NoDateAvailable')}
              </div>
            );
          }

          const formattedValue = dateValue.format('DD/MM/YYYY');
          return (
            <div className="dark:bg-boxdark-2 dark:text-bodydark">
              {formattedValue}
            </div>
          );
        },
      },
      {
        Header: t('DocRequests.comments'),
        accessor: 'comments',
      },
    ],
    [t]
  );

  const queryClient = useQueryClient();

  const { data: docRequests } = useQuery<DocumentRequestResponse | undefined>(
    ['docRequests', pageIndex, pageSize, search],
    () => {
      if (!isRHAdmin) {
        return getDocumentRequests({
          fromvalue: pageIndex,
          takevalue: pageSize,
          search: search,
          employeeId: user?.id,
        });
      } else {
        return getDocumentRequests({
          fromvalue: pageIndex,
          takevalue: pageSize,
          search: search,
        });
      }
    },
    {
      onSuccess: (data) => {
        if (data) {
          setDocRequestsData(data);
        }
      },
      refetchOnWindowFocus: false,
      staleTime: 3000,
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    if (docRequestsData !== null && docRequestsData !== undefined) {
      setIsTRLoading(false);
    }
  }, [docRequestsData]);

  const handleDeleteDocRequest = useMutation(
    async (id: string) => {
      await deleteDocRequest(id);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          'docRequests',
          pageIndex,
          pageSize,
          search,
        ]);
      },
    }
  );

  const pageCount = useMemo(() => {
    return Math.ceil((docRequests?.count ?? 0) / pageSize);
  }, [pageSize, docRequests]);

  const nextPage = () => setPageIndex(pageIndex + 1);
  const previousPage = () => setPageIndex(pageIndex - 1);
  const onPageChange = (newPageIndex: number) => setPageIndex(newPageIndex);
  const onPageSizeChange = (newPageSize: number) => {
    setPageIndex(0);
    setPageSize(newPageSize);
  };

  return (
    <DefaultLayout>
      <Breadcrumb
        pageName={t('DocRequests.pageName')}
        addbtn={user?.role !== UserRoles.Admin ? true : false}
        addRouter="new"
      />
      {isTRLoading || user === null ? (
        <div className="flex items-center justify-center">
          <Spinner height="3rem" width="3rem" />
        </div>
      ) : (
        <div className="flex flex-col">
          <DynamicTable
            dataCount={docRequestsData?.count}
            data={docRequestsData?.documentRequests ?? []}
            columns={columns}
            exportPdf={false}
            setSearch={setSearch}
          />
          {isModalVisible && (
            <DeleteConfirmationModal
              isOpen={isModalVisible}
              onDelete={handleDeleteConfirmation}
              onCancel={handleCancelDelete}
            />
          )}
          <Pagination
            pageIndex={pageCount === 0 ? -1 : pageIndex}
            pageCount={pageCount}
            pageSize={pageSize}
            onNextPage={nextPage}
            onPreviousPage={previousPage}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
          />
        </div>
      )}
    </DefaultLayout>
  );
}

export default DocumentRequests;
