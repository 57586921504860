import React, { useEffect, useState } from 'react';
import DefaultLayout from '@/layout/DefaultLayout';
import Breadcrumb from '@/components/Breadcrumb';
import { default as DefaultInput } from '@/components/Input';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import DatePickerDefault from '@/components/DatePicker';
import {
  SupplierOrder,
  getSupplierOrder,
  saveSupplierOrder,
} from '@/services/SupplierOrderService';
import Spinner from '@/components/Spinner';
import { useNavigate, useParams } from 'react-router-dom';
import Dropdown from '@/components/DropDown';
import { getCompanyId } from '@/services/AuthService';
import { toast } from 'react-hot-toast';
import { OfferStatus, OfferStatusOptions } from '@/enums/OfferStatus';
import { PaymentStatus, PaymentStatusOptions } from '@/enums/PaymentStatus';
import { Currency, currencyOptions } from '@/enums/Currency';
import FormButtons from '@/components/shared/FormButtons';

const validationSchema = Yup.object().shape({
  supplier: Yup.string().required('SupplierOrderForm.supplierRequired'),
  contact: Yup.string().required('SupplierOrderForm.contactRequired'),
  companyName: Yup.string().required('SupplierOrderForm.companyNameRequired'),
  address: Yup.string().required('SupplierOrderForm.addressRequired'),
  email: Yup.string()
    .required('SupplierOrderForm.emailRequired')
    .email('SupplierOrderForm.emailInvalid'),
  phone: Yup.string().required('SupplierOrderForm.phoneRequired'),
  orderDetail: Yup.string().required('SupplierOrderForm.orderDetailRequired'),
  amountHT: Yup.number().required('SupplierOrderForm.amountHTRequired'),
  tva: Yup.number().required('SupplierOrderForm.tvaRequired'),
  amountTTC: Yup.number().required('SupplierOrderForm.amountTTCRequired'),
  submissionDate: Yup.date().required(
    'SupplierOrderForm.submissionDateRequired'
  ),
  offerStatus: Yup.string().required('SupplierOrderForm.offerStatusRequired'),
  invoice: Yup.string().required('SupplierOrderForm.invoiceRequired'),
  paymentStatus: Yup.string().required(
    'SupplierOrderForm.paymentStatusRequired'
  ),
  serviceType: Yup.string().required('SupplierOrderForm.serviceTypeRequired'),
  comment: Yup.string().required('SupplierOrderForm.commentRequired'),
});

interface FormValues extends SupplierOrder {}

function SupplierOrderForm() {
  const { id } = useParams();
  const editForm = id !== 'new';
  const navigate = useNavigate();

  const { t } = useTranslation();

  const [formikValuesState, setFormikValuesState] = useState<FormValues>({
    supplier: '',
    contact: '',
    companyName: '',
    address: '',
    email: '',
    phone: '',
    orderDetail: '',
    amountHT: 0,
    tva: 0,
    amountTTC: 0,
    submissionDate: new Date(),
    offerStatus: OfferStatus.InPreparation,
    invoice: '',
    paymentStatus: PaymentStatus.Pending,
    serviceType: '',
    comment: '',
    currency: Currency.USD,
    companyId: getCompanyId()!.toString(),
  });
  const [loading, setLoading] = useState(false);

  const {
    data: supplierOrderData,
    isLoading,
    refetch: refetchSupplierOrder,
  } = useQuery<SupplierOrder | undefined>(
    ['supplierOrder', id],
    async () => await getSupplierOrder(id!),
    {
      enabled: editForm,
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
      onSuccess: (supplierOrder) => {
        if (supplierOrder) {
          setFormikValuesState((prev) => ({ ...prev, ...supplierOrder }));
        }
      },
    }
  );

  useEffect(() => {
    if (editForm && supplierOrderData) {
      setFormikValuesState((prev) => ({ ...prev, ...supplierOrderData }));
    }
  }, [editForm, supplierOrderData]);

  const handleSaveSupplierOrder = async (
    values: FormValues,
    { resetForm, validateForm }: any
  ) => {
    setLoading(true);

    try {
      const supplierOrder: SupplierOrder = {
        ...values,
        companyId: getCompanyId()!.toString(),
      };

      const data = await saveSupplierOrder(supplierOrder);
      if (data) {
        if (editForm) {
          toast.success(
            t('SuccessUpdateMessage', {
              name: t('ToastMsg.SupplierOrder'),
            })
          );
        } else {
          toast.success(
            t('SuccessMessage', {
              name: t('ToastMsg.SupplierOrder'),
            })
          );
        }
      }
      if (data && editForm) {
        refetchSupplierOrder();
      }
      navigate(-1);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
      resetForm();
    }
  };

  return (
    <DefaultLayout>
      <Breadcrumb
        pageName={
          editForm
            ? t('SupplierOrderForm.editSupplierOrder')
            : t('SupplierOrderForm.addSupplierOrder')
        }
      />
      {loading ? (
        <Spinner />
      ) : (
        <Formik
          initialValues={formikValuesState}
          validationSchema={validationSchema}
          onSubmit={handleSaveSupplierOrder}
          enableReinitialize={editForm}
        >
          {({ values, errors, handleChange, handleSubmit, resetForm }) => (
            <form onSubmit={handleSubmit}>
              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="text"
                    name="supplier"
                    id="supplier"
                    placeholder=" "
                    onChange={handleChange}
                    errors={errors}
                    value={values.supplier}
                    label={t('SupplierOrderForm.supplierLabel')}
                  />
                </div>
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="text"
                    name="contact"
                    id="contact"
                    placeholder=" "
                    onChange={handleChange}
                    errors={errors}
                    value={values.contact}
                    label={t('SupplierOrderForm.contactLabel')}
                  />
                </div>
              </div>

              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="text"
                    name="companyName"
                    id="companyName"
                    placeholder=" "
                    onChange={handleChange}
                    errors={errors}
                    value={values.companyName}
                    label={t('SupplierOrderForm.companyNameLabel')}
                  />
                </div>
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="text"
                    name="address"
                    id="address"
                    placeholder=" "
                    onChange={handleChange}
                    errors={errors}
                    value={values.address}
                    label={t('SupplierOrderForm.addressLabel')}
                  />
                </div>
              </div>

              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="email"
                    name="email"
                    id="email"
                    placeholder=" "
                    onChange={handleChange}
                    errors={errors}
                    value={values.email}
                    label={t('SupplierOrderForm.emailLabel')}
                  />
                </div>
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="text"
                    name="phone"
                    id="phone"
                    placeholder=" "
                    onChange={handleChange}
                    errors={errors}
                    value={values.phone}
                    label={t('SupplierOrderForm.phoneLabel')}
                  />
                </div>
              </div>

              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="text"
                    name="orderDetail"
                    id="orderDetail"
                    placeholder=" "
                    onChange={handleChange}
                    errors={errors}
                    value={values.orderDetail}
                    label={t('SupplierOrderForm.orderDetailLabel')}
                  />
                </div>
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="number"
                    name="amountHT"
                    id="amountHT"
                    placeholder=" "
                    onChange={handleChange}
                    errors={errors}
                    value={values.amountHT}
                    label={t('SupplierOrderForm.amountHTLabel')}
                  />
                </div>
              </div>

              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="number"
                    name="tva"
                    id="tva"
                    placeholder=" "
                    onChange={handleChange}
                    errors={errors}
                    value={values.tva}
                    label={t('SupplierOrderForm.tvaLabel')}
                  />
                </div>
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="number"
                    name="amountTTC"
                    id="amountTTC"
                    placeholder=" "
                    onChange={handleChange}
                    errors={errors}
                    value={values.amountTTC}
                    label={t('SupplierOrderForm.amountTTCLabel')}
                  />
                </div>
              </div>

              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="group relative  mb-6 w-full">
                  <DatePickerDefault
                    labelDir="Above"
                    label={t('SupplierOrderForm.submissionDateLabel')}
                    labelClassName="w-[50%]"
                    id="submissionDate"
                    name="submissionDate"
                    value={new Date(values.submissionDate)}
                    defaultDate={new Date(values.submissionDate)}
                    onChange={(date) => {
                      handleChange({
                        target: {
                          name: 'submissionDate',
                          value: date,
                        },
                      });
                    }}
                    errors={errors.submissionDate}
                  />
                </div>
                <div className="group relative mb-6 w-full">
                  <DefaultInput
                    type="text"
                    name="invoice"
                    id="invoice"
                    placeholder=" "
                    onChange={handleChange}
                    errors={errors}
                    value={values.invoice}
                    label={t('SupplierOrderForm.invoiceLabel')}
                  />
                </div>
              </div>

              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="group relative z-0 mb-6 w-full">
                  <Dropdown
                    name="offerStatus"
                    id="offerStatus"
                    label={t('SupplierOrderForm.offerStatusLabel')}
                    value={values.offerStatus}
                    options={OfferStatusOptions}
                    onChange={handleChange}
                    error={errors.offerStatus}
                    translatedNameSpace="OfferStatus"
                    isTranslated={true}
                    optionTransalatedKey="name"
                  />
                </div>
                <div className="group relative z-0 mb-6 w-full">
                  <Dropdown
                    name="paymentStatus"
                    id="paymentStatus"
                    label={t('SupplierOrderForm.paymentStatusLabel')}
                    value={values.paymentStatus}
                    options={PaymentStatusOptions}
                    onChange={handleChange}
                    error={errors.paymentStatus}
                    translatedNameSpace="PaymentStatus"
                    isTranslated={true}
                    optionTransalatedKey="name"
                  />
                </div>
              </div>

              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="text"
                    name="serviceType"
                    id="serviceType"
                    placeholder=" "
                    onChange={handleChange}
                    errors={errors}
                    value={values.serviceType}
                    label={t('SupplierOrderForm.serviceTypeLabel')}
                  />
                </div>

                <div className="group relative z-0 mb-6 w-full">
                  <Dropdown
                    labelDir="Above"
                    name="currency"
                    id="currency"
                    label={t('PersonnelCostForm.currencyLabel')}
                    onChange={handleChange}
                    value={values.currency}
                    errors={errors.currency}
                    optionValue="id"
                    labelClassName="pl-0"
                    options={currencyOptions ?? []}
                  />
                </div>
              </div>
              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="text"
                    name="comment"
                    id="comment"
                    placeholder=" "
                    onChange={handleChange}
                    errors={errors}
                    value={values.comment}
                    label={t('SupplierOrderForm.commentLabel')}
                  />
                </div>
              </div>
              <FormButtons resetForm={resetForm} loading={loading} />
            </form>
          )}
        </Formik>
      )}
    </DefaultLayout>
  );
}

export default SupplierOrderForm;
