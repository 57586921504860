import React, { useEffect, useState } from 'react';
import DefaultLayout from '@/layout/DefaultLayout';
import Breadcrumb from '@/components/Breadcrumb';
import DefaultInput from '@/components/Input';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import Spinner from '@/components/Spinner';
import { useNavigate, useParams } from 'react-router-dom';
import Dropdown from '@/components/DropDown';
import { getCompanyId } from '@/services/AuthService';
import { toast } from 'react-hot-toast';
import {
  PersonnelCost,
  getPersonnelCost,
  savePersonnelCost,
} from '@/services/PersonnelCostService';

import { Currency, currencyOptions } from '@/enums/Currency';
import {
  PersonnelCategory,
  PersonnelCategoryOptions,
} from '@/enums/PersonnelCategory';
import { PersonnelType, PersonnelTypeOptions } from '@/enums/PersonnelType';
import {
  CountriesResponse,
  Country,
  getCountries,
} from '@/services/CountryService';
import FormButtons from '@/components/shared/FormButtons';

// Define validation schema using Yup
const validationSchema = Yup.object().shape({
  function: Yup.string().required('Function is required'),
  countryId: Yup.number().required('Country is required'),
  category: Yup.string().required('Category is required'),
  type: Yup.string().required('Type is required'),
  hourlyRate: Yup.number().required('Hourly Rate is required'),
  currency: Yup.string().required('Currency is required'),
});

// Define interface for form values
interface FormValues extends PersonnelCost {}

function PersonnelCostForm() {
  const { id } = useParams();
  const editForm = id !== 'new';
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [countries, setCountries] = React.useState<Country[]>([]);

  // Initial form values state
  const [formikValuesState, setFormikValuesState] = useState<FormValues>({
    function: '',
    countryId: 0,
    category: PersonnelCategory.Subcontractor,
    type: PersonnelType.Local,
    hourlyRate: 0,
    currency: Currency.USD,
    companyId: getCompanyId()!,
  });

  const [loading, setLoading] = useState(false);

  const {
    data: countriesData,
    isLoading: countryLoading,
    isSuccess: isCountriesLoadded,
  } = useQuery<CountriesResponse | undefined>(
    ['countries'],
    () => getCountries({ from: 0, take: 0 }),
    {
      onSuccess: (data) => {
        //setCountries(data?.countries!);
      },
      refetchOnWindowFocus: false,
      staleTime: 5 * (60 * 1000), // 5 mins
      cacheTime: 10 * (60 * 1000),
      keepPreviousData: true,
    }
  );

  const {
    data: personnelCostData,
    isLoading: isQueryLoading,
    refetch: refetchPersonnelCost,
  } = useQuery<FormValues | undefined>(
    ['personnelCost', id],
    async () => {
      return await getPersonnelCost(id!);
    },
    {
      enabled: editForm,
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
      onSuccess: (personnelCost) => {
        // if (personnelCost) {
        //   setFormikValuesState((prev) => ({ ...prev, ...personnelCost }));
        // }
      },
    }
  );

  useEffect(() => {
    if (editForm && personnelCostData && countriesData) {
      console.log('🚀 ~ useEffect ~ countriesData:', countriesData);
      setFormikValuesState((prev) => ({ ...prev, ...personnelCostData }));
    }
  }, [editForm, personnelCostData, countriesData]);

  // Handle form submission
  const handleSavePersonnelCost = async (
    values: FormValues,
    { resetForm, validateForm }: any
  ) => {
    setLoading(true);

    try {
      // Prepare personnel cost object to save
      const personnelCost: FormValues = {
        ...values,
        companyId: getCompanyId()!,
      };

      // Implement your save function here
      await savePersonnelCost(personnelCost); // Replace with actual function

      // Show success message
      toast.success(
        t('SuccessMessage', {
          name: t('ToastMsg.PersonnelCost'),
        })
      );

      // Refetch data if editing existing personnel cost
      if (editForm) {
        refetchPersonnelCost();
      }

      // Navigate back to previous page
      navigate(-1);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
      resetForm(); // Reset form values
    }
  };

  return (
    <DefaultLayout>
      {/* Breadcrumb component */}
      <Breadcrumb
        pageName={
          editForm
            ? t('PersonnelCostForm.editPersonnelCost')
            : t('PersonnelCostForm.addPersonnelCost')
        }
      />
      {/* Show spinner while loading */}
      {loading || countryLoading ? (
        <Spinner />
      ) : (
        <Formik
          initialValues={formikValuesState}
          validationSchema={validationSchema}
          onSubmit={handleSavePersonnelCost}
          enableReinitialize={editForm}
        >
          {({ values, errors, handleChange, handleSubmit, resetForm }) => (
            <form onSubmit={handleSubmit}>
              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="text"
                    name="function"
                    id="function"
                    placeholder=" "
                    onChange={handleChange}
                    value={values.function}
                    label={t('PersonnelCostForm.functionLabel')}
                    errors={errors}
                  />
                </div>
                <div className="group relative z-0 mb-6 w-full">
                  {/* <DefaultInput
                    type="number"
                    name="countryId"
                    id="countryId"
                    placeholder=" "
                    onChange={handleChange}
                    value={values.countryId}
                    label={t('PersonnelCostForm.countryIdLabel')}
                    errors={errors}
                  /> */}

                  {
                    <Dropdown
                      name="countryId"
                      id="countryId"
                      value={values.countryId}
                      optionValue="id"
                      optionLabel="name"
                      labelClassName="w-[40%] pl-0"
                      onChange={handleChange}
                      className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                      options={countriesData?.countries ?? []}
                      label={t('PersonnelCostForm.countryIdLabel')}
                    />
                  }
                </div>
              </div>
              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="group relative z-0 mb-6 w-full">
                  <Dropdown
                    labelDir="Above"
                    name="category"
                    id="category"
                    label={t('PersonnelCostForm.categoryLabel')}
                    onChange={handleChange}
                    value={values.category}
                    errors={errors.category}
                    optionValue="id"
                    labelClassName="pl-0"
                    options={PersonnelCategoryOptions ?? []}
                    translatedNameSpace="PersonnelCategory"
                    isTranslated={true}
                    optionTransalatedKey="name"
                  />
                </div>
                <div className="group relative z-0 mb-6 w-full">
                  <Dropdown
                    labelDir="Above"
                    name="type"
                    id="type"
                    label={t('PersonnelCostForm.typeLabel')}
                    onChange={handleChange}
                    value={values.type}
                    errors={errors.type}
                    optionValue="id"
                    labelClassName="pl-0"
                    options={PersonnelTypeOptions ?? []}
                    translatedNameSpace="PersonnelType"
                    isTranslated={true}
                    optionTransalatedKey="name"
                  />
                </div>
              </div>
              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="number"
                    name="hourlyRate"
                    id="hourlyRate"
                    placeholder=" "
                    onChange={handleChange}
                    value={values.hourlyRate}
                    label={t('PersonnelCostForm.hourlyRateLabel')}
                    errors={errors}
                  />
                </div>
                <div className="group relative z-0 mb-6 w-full">
                  <Dropdown
                    labelDir="Above"
                    name="currency"
                    id="currency"
                    label={t('PersonnelCostForm.currencyLabel')}
                    onChange={handleChange}
                    value={values.currency}
                    errors={errors.currency}
                    optionValue="id"
                    labelClassName="pl-0"
                    options={currencyOptions ?? []}
                  />
                </div>
              </div>
              <FormButtons resetForm={resetForm} loading={loading} />
            </form>
          )}
        </Formik>
      )}
    </DefaultLayout>
  );
}

export default PersonnelCostForm;
