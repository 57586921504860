import React, { useLayoutEffect, useRef, useEffect, useState } from 'react';
import { Layout, OrgChart } from 'd3-org-chart';
import { PrimaryButton } from './shared/Button';
import { EmployeesOrgChart } from '@/services/RapportsServices';
import useColorMode from '@/hooks/useColorMode';
import { siteName } from '@/variables/Urls';
import * as d3 from 'd3';
import { useTranslation } from 'react-i18next';
import Input from './Input';
import {
  ChevronRightIcon,
  ChevronUpIcon,
  ChevronLeftIcon,
  ChevronDownIcon,
} from '@heroicons/react/solid';

const isDevelopment = import.meta.env.MODE === 'development';

const OrgChartComponent = ({ data }: any) => {
  const d3Container = useRef(null);
  let chart: any = null;
  const { t } = useTranslation();
  const [colorMode, _] = useColorMode();
  const [search, setSearch] = useState('');
  // get query for all employees on Company

  // We need to manipulate DOM
  useLayoutEffect(() => {
    if (data && d3Container.current) {
      if (!chart) {
        chart = new OrgChart();
      }

      chart
        .container(d3Container.current)
        .nodeHeight((d) => 85 + 25)
        .nodeWidth((d) => 220 + 2)
        .childrenMargin((d) => 50)
        .compactMarginBetween((d) => 35)
        .compactMarginPair((d) => 30)
        .neighbourMargin((a, b) => 20)
        .linkUpdate((link, index, links) => {
          // Customize link appearance here
          d3.selectAll(links)
            .style('stroke', '#FF00E9')
            .style('stroke-width', '3px');
        })
        .buttonContent(({ node, state }) => {
          const arrowIcon = node.children
            ? `<svg width="8" height="8" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.457 8.07005L3.49199 16.4296C3.35903 16.569 3.28485 16.7543 3.28485 16.9471C3.28485 17.1398 3.35903 17.3251 3.49199 17.4646L3.50099 17.4736C3.56545 17.5414 3.64304 17.5954 3.72904 17.6324C3.81504 17.6693 3.90765 17.6883 4.00124 17.6883C4.09483 17.6883 4.18745 17.6693 4.27344 17.6324C4.35944 17.5954 4.43703 17.5414 4.50149 17.4736L12.0015 9.60155L19.4985 17.4736C19.563 17.5414 19.6405 17.5954 19.7265 17.6324C19.8125 17.6693 19.9052 17.6883 19.9987 17.6883C20.0923 17.6883 20.1849 17.6693 20.2709 17.6324C20.3569 17.5954 20.4345 17.5414 20.499 17.4736L20.508 17.4646C20.641 17.3251 20.7151 17.1398 20.7151 16.9471C20.7151 16.7543 20.641 16.569 20.508 16.4296L12.543 8.07005C12.4729 7.99653 12.3887 7.93801 12.2954 7.89801C12.202 7.85802 12.1015 7.8374 12 7.8374C11.8984 7.8374 11.798 7.85802 11.7046 7.89801C11.6113 7.93801 11.527 7.99653 11.457 8.07005Z" fill="#ffffff" stroke="#ffffff"></path>
              </svg>`
            : `<svg width="8" height="8" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 16.8284L19.6464 9.18201L18.2321 7.7677L12 14L5.7677 7.7677L4.35339 9.18201L12 16.8284Z" fill="#ffffff" stroke="#ffffff"></path>
              </svg>`;

          const content = node.data._directSubordinates || '0'; // Update with actual data property

          return `
            <div style="border: 1px solid rgb(228, 226, 233); border-radius: 3px; padding: 3px; font-size: 9px; margin: auto; background-color: rgb(255, 0, 233); stroke: rgb(255, 0, 233); stroke-width: 3px;">
              <div style="display: flex; stroke: rgb(255, 0, 233); stroke-width: 3px;">
                <span style="align-items:center;display:flex;">
                  ${arrowIcon}
                </span>
                <span style="margin-left:1px;color:#ffffff">${content}</span>
              </div>
            </div>`;
        })

        .nodeContent(function (d, i, arr, state) {
          const color = '#7988a6';
          const cardWidth = 240;
          const cardHeight = d.height || 70;

          return `
            <div style="font-family: 'Poppins', sans-serif; display: flex; align-items: center; width: ${cardWidth}px; height: ${cardHeight}px; padding: 10px; background-color: #fff; border-radius: 10px; border: 1px solid #F0F2F5; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); ">
              
              <!-- Profile Image -->
              <div style="flex-shrink: 0; width: 50px; height: 50px; border-radius: 50%; overflow: hidden; margin-right: 15px;">
                <img src="${
                  isDevelopment
                    ? d.data.profileImgUrl.includes('default')
                      ? d.data.profileImgUrl
                      : siteName + d.data.profileImgUrl
                    : d.data.profileImgUrl
                }" style="width: 100%; height: 100%;" />
              </div>
              
              <!-- Text Content -->
              <div style="flex: 1; display: flex; flex-direction: column; justify-content: center;">
                <div style="font-size: 15px; font-weight: bold; color: #08011E;">${
                  d.data.fullName
                }</div>
                <div style="font-size: 12px; color: #08011E; margin-top: 4px;">${
                  d.data.jobTitle
                }</div>
                <div style="font-size: 10px; color: #08011E; margin-top: 2px;">${
                  d.data.email
                }</div>
              </div>
        
            </div>
          `;
        })

        .data(data)

        .render();

      // const targetDiv = d3.selectAll('.node-button-div');

      // // Change background col
      // targetDiv.select('div').style('background-color', '#FF00E9');

      // // Change text color
      // targetDiv
      //   .select('div')
      //   .select('div')
      //   .selectAll('span')
      //   .style('stroke', '#ffffff');
      // targetDiv
      //   .select('div')
      //   .select('div')
      //   .selectAll('span')
      //   .selectAll('svg')
      //   .selectAll('path')
      //   .style('fill', '#ffffff')
      //   .style('stroke', '#ffffff');
      // targetDiv
      //   .select('div')
      //   .select('div')
      //   .selectAll('span')
      //   .style('color', '#ffffff');
      // targetDiv
      //   .select('div')
      //   .select('div')
      //   .selectAll('span')
      //   .style('font-size', '14px');
    }
  }, [data, d3Container.current]);

  const handleLayoutChange = (layoutDir: Layout) => {
    chart!.layout(layoutDir).render().fit();
  };

  // const handleChange = (e: React.ChangeEvent) => {};

  // const filterChart = (e) => {
  //   const value = e.target.value;

  //   // Clear previous highlighting
  //   chart?.clearHighlighting();

  //   // Get chart nodes
  //   const data = chart?.data();

  //   // Mark nodes for highlighting based on the filter value
  //   data?.forEach((d) => {
  //     if (
  //       value !== '' &&
  //       d.fullName.toLowerCase().includes(value.toLowerCase())
  //     ) {
  //       d._highlighted = true;
  //       d._expanded = true;
  //     }
  //   });

  //   // Update data and re-render graph
  //   chart
  //     ?.data(data ?? [])
  //     ?.render()
  //     .fit();

  //   console.log('Filtering chart', value);
  // };
  return (
    <div>
      <PrimaryButton onClick={(e) => handleLayoutChange('right')}>
        {t('OrgChart.Right')} <ChevronRightIcon className="ml-2 h-5 w-5" />
      </PrimaryButton>

      <PrimaryButton onClick={(e) => handleLayoutChange('top')}>
        {t('OrgChart.Top')} <ChevronUpIcon className="ml-2 h-5 w-5" />
      </PrimaryButton>

      <PrimaryButton onClick={(e) => handleLayoutChange('left')}>
        {t('OrgChart.Left')} <ChevronLeftIcon className="ml-2 h-5 w-5" />
      </PrimaryButton>

      <PrimaryButton onClick={(e) => handleLayoutChange('bottom')}>
        {t('OrgChart.Bottom')} <ChevronDownIcon className="ml-2 h-5 w-5" />
      </PrimaryButton>

      {/* <Input name="search" id="search" label="search" onInput={filterChart} /> */}
      <div ref={d3Container} />
    </div>
  );
};
export default OrgChartComponent;
