import React from 'react';

interface DefaultCheckBoxProps {
  checked: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  className?: string;
}

const DefaultCheckBox: React.FC<DefaultCheckBoxProps> = ({
  checked,
  readOnly = true,
  disabled = true,
  className = '',
}) => (
  <input
    type="checkbox"
    checked={checked}
    readOnly={readOnly}
    disabled={disabled}
    className={`relative h-4 w-4 appearance-none rounded border-gray-300 bg-gray-100 checked:border-transparent checked:bg-primary-500 checked:ring-2 checked:ring-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:border-gray-500 dark:bg-gray-600 dark:ring-offset-gray-700 dark:focus:ring-blue-600 dark:focus:ring-offset-gray-700 ${className}`}
  />
);

export default DefaultCheckBox;
