import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-tailwindcss-datepicker';
import Input from './Input'; // Update this path to the actual path of your Input component
import { v4 as uuidv4 } from 'uuid';
import { DeleteButton, EditButton } from './shared/Button';

export interface DateRange {
  startDate: Date;
  endDate: Date;
  id: string;
}

interface MultiDatePickerRangeProps {
  value: DateRange[];
  onChange: (value: DateRange[]) => void;
  label: string;
  errors: Record<string, string>;
  id: string;
  name: string;
}
function formatDateForSave(date: Date) {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

function MultiDatePickerRange({
  value,
  onChange,
  label,
  errors,
  id,
  name,
}: MultiDatePickerRangeProps) {
  const [dateRanges, setDateRanges] = useState<DateRange[]>([]);
  const { i18n, t } = useTranslation();

  useEffect(() => {
    setDateRanges(value);
  }, [value]);

  const handleAddDateRange = () => {
    setDateRanges([
      ...dateRanges,
      {
        id: uuidv4(),
        startDate: formatDateForSave(new Date()),
        endDate: formatDateForSave(new Date()),
      },
    ]);
  };

  const handleRemoveDateRange = (id: string) => {
    const updatedDateRanges = dateRanges.filter(
      (dateRange) => dateRange.id !== id
    );
    setDateRanges(updatedDateRanges);
  };

  const handleDateChange = (id: string, dateRange: DateRange) => {
    const updatedDateRanges = dateRanges.map((existingRange) =>
      existingRange.id === id
        ? { ...existingRange, ...dateRange }
        : existingRange
    );

    setDateRanges(updatedDateRanges);
    onChange(updatedDateRanges);
  };

  return (
    <div className="relative ">
      {/* Include the label using the Input component */}
      <label
        htmlFor={id}
        className={`
        mb-2 block text-sm font-medium text-gray-900 dark:text-white 
         ${errors && errors[name] ? 'text-red-700 dark:text-red-500' : ''}
        `}
      >
        {label}
      </label>
      {dateRanges.map((dateRange, index) => (
        <div
          key={dateRange.id}
          className="mb-2 flex items-center justify-center"
        >
          <DatePicker
            i18n={i18n.language}
            popoverDirection="down"
            value={dateRange}
            onChange={(newDateRange) =>
              handleDateChange(dateRange.id, newDateRange)
            }
          />
          {
            <DeleteButton
              className="ml-2 "
              onClick={() => handleRemoveDateRange(dateRange.id)}
            >
              {t('Branches.deleteButton')}
            </DeleteButton>
          }
        </div>
      ))}
      <EditButton onClick={handleAddDateRange}>{`+ ${t(
        'degrees.add'
      )}`}</EditButton>
      {errors && errors[name] && (
        <p className="mt-2 text-sm text-red-600 dark:text-red-500">
          {t(errors[name])}
        </p>
      )}
    </div>
  );
}

export default MultiDatePickerRange;
