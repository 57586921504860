import React, { useMemo, useState } from 'react';
import DefaultLayout from '@/layout/DefaultLayout';
import Breadcrumb from '@/components/Breadcrumb';
import { useQueryClient, useQuery, useMutation } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import DynamicTable, { SelectColumnFilter } from '@/components/DynamicTable';
import Pagination from '@/components/Pagination';
import Spinner from '@/components/Spinner';
import { DeleteButton, EditButton } from '@/components/shared/Button';
import DeleteConfirmationModal from '@/components/shared/DeleteConfirmationModal';
import {
  ProjectCharacteristicsResponse,
  deleteProjectCharacteristic,
  getProjectCharacteristics,
  getProjectCharacteristicsByProjectId,
} from '@/services/ProjectCharacteristicsService';
import { useTranslation } from 'react-i18next';

function ProjectCharacteristics() {
  const { id } = useParams();
  console.log('🚀 ~ ProjectCharacteristics ~ id:', id);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState<string>('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState('');
  const navigate = useNavigate();

  const { t } = useTranslation();

  const ActionButtonsCell = ({ value }: any) => {
    return (
      <div>
        <DeleteButton
          onClick={() => {
            setSelectedRow(value);
            setIsModalVisible(true);
          }}
        >
          {t('ProjectCharacteristics.deleteButton')}
        </DeleteButton>

        <EditButton
          type="button"
          onClick={() => {
            navigate(`${value}`);
          }}
        >
          {t('ProjectCharacteristics.editButton')}
        </EditButton>
      </div>
    );
  };

  const handleCancelDelete = () => {
    setIsModalVisible(false);
  };

  const handleDeleteConfirmation = () => {
    handleDeleteProjectCharacteristic.mutate(selectedRow);
    setIsModalVisible(false);
  };

  const columns = useMemo(
    () => [
      {
        Header: t('ProjectCharacteristics.actionsColumn'),
        accessor: 'id',
        Cell: ({ cell: { value } }: any) => (
          <ActionButtonsCell
            value={value}
            handleDelete={handleDeleteProjectCharacteristic}
          />
        ),
      },
      {
        Header: t('ProjectCharacteristics.positivePointsColumn'),
        accessor: 'positivePoints',
        Filter: SelectColumnFilter,
      },

      {
        Header: t('ProjectCharacteristics.negativePointsColumn'),
        accessor: 'negativePoints',
        Filter: SelectColumnFilter,
      },
      {
        Header: t('ProjectCharacteristics.pointsOfVigilanceColumn'),
        accessor: 'pointsOfVigilance',
        Filter: SelectColumnFilter,
      },
    ],
    [t]
  );

  const queryClient = useQueryClient();

  const {
    isLoading,
    isError,
    data: projectCharacteristics,
  } = useQuery<ProjectCharacteristicsResponse | undefined>(
    ['projectCharacteristics', pageIndex, pageSize, search],
    () =>
      getProjectCharacteristicsByProjectId({
        projectId: id!,
        page: pageIndex,

        take: pageSize,
        search: search,
      }),
    {
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
      enabled: id !== null,
    }
  );

  const handleDeleteProjectCharacteristic = useMutation(
    async (id: string) => {
      await deleteProjectCharacteristic(id);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          'projectCharacteristics',
          pageIndex,
          pageSize,
          search,
        ]);
      },
    }
  );

  const pageCount = useMemo(() => {
    return Math.ceil((projectCharacteristics?.count ?? 0) / pageSize);
  }, [pageSize, projectCharacteristics]);
  const nextPage = () => setPageIndex(pageIndex + 1);
  const previousPage = () => setPageIndex(pageIndex - 1);
  const onPageChange = (newPageIndex: number) => setPageIndex(newPageIndex);
  const onPageSizeChange = (newPageSize: number) => {
    setPageIndex(0);
    setPageSize(newPageSize);
  };

  return (
    <DefaultLayout>
      <Breadcrumb
        pageName={t('ProjectCharacteristics.pageName')}
        addbtn={true}
        addRouter="new"
      />
      {isLoading ? (
        <div className="flex items-center justify-center">
          <Spinner height="3rem" width="3rem" />
        </div>
      ) : (
        <div className="flex flex-col">
          <DynamicTable
            dataCount={projectCharacteristics?.count}
            data={projectCharacteristics?.projectCharacteristics ?? []}
            columns={columns}
            setSearch={setSearch}
            exportPdf={false}
          />
          {isModalVisible && (
            <DeleteConfirmationModal
              isOpen={isModalVisible}
              onDelete={handleDeleteConfirmation}
              onCancel={handleCancelDelete}
            />
          )}
          <Pagination
            pageIndex={pageCount === 0 ? -1 : pageIndex}
            pageCount={pageCount}
            pageSize={pageSize}
            onNextPage={nextPage}
            onPreviousPage={previousPage}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
          />
        </div>
      )}
    </DefaultLayout>
  );
}

export default ProjectCharacteristics;
