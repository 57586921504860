import React, { useEffect, useRef, useState } from 'react';
import Datepicker from 'tailwind-datepicker-react';
import moment from 'moment-timezone';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export interface IReactDatePickerDefaultProps {
  label?: string;
  errors?: string;
  name: string;
  onChange: (date: Date) => void;
  touched?: any;
  value: Date;
  defaultDate?: Date;
  id: string;
  disabled?: boolean;
  containerClass?: string;
  labelDir?: 'inLine' | 'Above';
}

const userTimeZone = moment.tz.guess();
const ReactDatePickerDefault = ({
  id,
  label,
  errors,
  onChange,
  value,
  labelDir = 'Above',
  containerClass,
  labelClassName,
}: IReactDatePickerDefaultProps) => {
  const { t } = useTranslation();
  const [showDate, setShowDate] = useState<boolean>(false);
  const datePickerRef = useRef<HTMLDivElement>(null);

  const handleDateChange = (selectedDate: Date) => {
    onChange(selectedDate);
  };

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target as Node)
      ) {
        setShowDate(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const inputClass = classNames(
    ' block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500 ',
    {
      ' text-red-700 dark:text-red-500 bg-red-300 dark:bg-red-300': errors,
    }
  );

  // const isValidDateString = (dateString) => {
  //   // Example: Check if the input string matches the format YYYY-MM-DD
  //   const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
  //   return dateRegex.test(dateString);
  // };

  // const handleInputChange = (e) => {
  //   const date = e.target.value;
  //   onChange(date);
  // };
  return (
    <div
      className={classNames(
        containerClass ?? '',
        labelDir == 'Above'
          ? '  relative'
          : '  relative inline-flex w-full  items-baseline gap-4'
      )}
      ref={datePickerRef}
    >
      <label
        htmlFor={id}
        className={classNames(
          labelClassName,
          `
         mb-2 block w-[30%] text-sm font-medium text-gray-900 dark:text-white
        ${errors ? 'text-red-700 dark:text-red-500' : ''}
      `
        )}
      >
        {label}
      </label>

      <ReactDatePicker
        onChange={handleDateChange}
        dateFormat="dd-MM-yyyy"
        selected={new Date(value)}
        className={inputClass}
        calendarClassName="w-full z-500 relative  "
      ></ReactDatePicker>

      {errors && (
        <p className="mt-2 text-sm text-red-600 dark:text-red-500">
          {t(errors)}
        </p>
      )}
    </div>
  );
};

export default ReactDatePickerDefault;
