import React, { useEffect, useMemo, useRef, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Logo from '../images/logo/logo.svg';

import SidebarLinkGroup from './SidebarLinkGroup';
import { MenuType, MenusItem } from '../routes/Menus';
import { ModuleType } from '@/models/ModuleTypeEnum';
import { stringToEnumArray } from '@/utils/EnumUtils';
import { useLicenseContext } from '@/context/LicenseContext';
import { useTranslation } from 'react-i18next';
import { LockClosedIcon } from '@heroicons/react/solid';
import { UserRoles, UserRolesEnum } from '@/enums/UsersRole';
import { useAuth } from '@/context/AuthContext';
import { useQuery } from '@tanstack/react-query';
import { UserResponsibility } from '@/enums/UserResponsibility';
import SideBarMenuItem from './shared/SideBarMenuItem';
import { useUI } from '@/context/UIContext';

interface SidebarProps {
  sidebarOpen: boolean;
  setSidebarOpen: (arg: boolean) => void;
}

const Sidebar = ({ sidebarOpen, setSidebarOpen }: SidebarProps) => {
  const location = useLocation();
  const { pathname } = location;
  const { t } = useTranslation();
  const { user } = useAuth();
  const { shrinkSideBar, setShrinkSideBar } = useUI();

  // const isTeamLeader = user!.role === UserRoles.TeamLeader;
  // const isAdmin = user!.role === UserRoles.TeamLeader;
  const userClaims = useMemo(() => {
    return (
      user?.claims?.map((e: number) => UserResponsibility[e].toString()) ?? []
    );
  }, [user?.claims]);

  const { loading, validLicense, moduleType } = useLicenseContext(); // Use the useLicenseContext hook to access the license-related data
  const [moduleEnum, setModuleEnum] = useState<number[]>();
  useEffect(() => {
    if (validLicense && moduleType) {
      setModuleEnum(moduleType.split(',').map((m) => parseInt(m)));
    }
  }, [loading, validLicense, moduleType, user?.claims]);
  const trigger = useRef<any>(null);
  const sidebar = useRef<any>(null);

  const storedSidebarExpanded = localStorage.getItem('sidebar-expanded');
  const [sidebarExpanded, setSidebarExpanded] = useState(
    storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true'
  );

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }: MouseEvent) => {
      if (!sidebar.current || !trigger.current) return;
      if (
        !sidebarOpen ||
        sidebar.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setSidebarOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }: KeyboardEvent) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  useEffect(() => {
    localStorage.setItem('sidebar-expanded', sidebarExpanded.toString());
    if (sidebarExpanded) {
      document.querySelector('body')?.classList.add('sidebar-expanded');
    } else {
      document.querySelector('body')?.classList.remove('sidebar-expanded');
    }
  }, [sidebarExpanded]);

  const sideMenuGroup = (
    index: number,
    title: string,
    to: string,
    isDisabled: boolean,
    sidebarExpanded: boolean,
    setSidebarExpanded: React.Dispatch<React.SetStateAction<boolean>>,
    IconSvg:
      | React.ReactElement<any, string | React.JSXElementConstructor<any>>
      | undefined,
    menuItems: MenuType[]
  ) => {
    return (
      <SidebarLinkGroup key={index} activeCondition={pathname.includes(to)}>
        {(handleClick, open) => {
          const isActive = pathname.includes(to);

          return (
            <React.Fragment>
              <NavLink
                to="#"
                className={` group relative flex items-center gap-2.5 rounded-sm px-4 py-2 text-sm font-medium text-bodydark1 duration-300 ease-in-out  hover:border-l-4 hover:border-l-white hover:bg-graydark dark:hover:bg-meta-4 ${
                  isActive && 'bg-indigo-400 dark:bg-indigo-600'
                }
                                  ${
                                    isDisabled
                                      ? 'pointer-events-none opacity-60'
                                      : ''
                                  }
  
                                  `}
                onClick={(e) => {
                  e.preventDefault();
                  sidebarExpanded ? handleClick() : setSidebarExpanded(true);
                }}
              >
                {IconSvg && <IconSvg height={22} width={22} alt="" />}
                {!shrinkSideBar && t(`sidebar.${title}`)}

                {/* open svg */}
                {!isDisabled && (
                  <svg
                    className={`absolute right-4 top-1/2 -translate-y-1/2 fill-current ${
                      open && 'rotate-180'
                    }`}
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M4.41107 6.9107C4.73651 6.58527 5.26414 6.58527 5.58958 6.9107L10.0003 11.3214L14.4111 6.91071C14.7365 6.58527 15.2641 6.58527 15.5896 6.91071C15.915 7.23614 15.915 7.76378 15.5896 8.08922L10.5896 13.0892C10.2641 13.4147 9.73651 13.4147 9.41107 13.0892L4.41107 8.08922C4.08563 7.76378 4.08563 7.23614 4.41107 6.9107Z"
                      fill=""
                    />
                  </svg>
                )}
                {isDisabled && (
                  <LockClosedIcon className="h-4 w-4 text-white" />
                )}
              </NavLink>

              {/* <!-- Dropdown Menu Start --> */}
              <div
                className={`translate transform overflow-hidden ${
                  !open && 'hidden'
                }`}
              >
                <ul className="mb-5.5 mt-4 flex flex-col gap-2.5 pl-6">
                  {menuItems.map((menu, index) => {
                    const hasRequiredRole =
                      menu.allowedRoles?.includes(user?.role!) ?? false;
                    const hasRequiredResponsibility =
                      menu.isResponsibiliy &&
                      (menu.allowedResponsibility?.includes(
                        user?.responsibilityClaim ?? ''
                      ) ??
                        false);
                    const isTeamLeader =
                      menu.allowedRoles?.includes(UserRoles.TeamLeader) &&
                      user?.isTeamLeader == 'True';

                    const userIsAllowed = menu.isAllowed;
                    const isDisabled = userIsAllowed
                      ? false
                      : moduleEnum && moduleType && validLicense
                      ? !moduleEnum.some((x) => x === menu.moduleType)
                      : true;

                    const shouldRenderItem =
                      hasRequiredResponsibility ||
                      hasRequiredRole ||
                      isTeamLeader;

                    if (!shouldRenderItem) {
                      return null;
                    }

                    return (
                      <li key={index}>
                        {/* Check for nested menus */}
                        {menu.isMenu ? (
                          sideMenuGroup(
                            index,
                            menu.title,
                            menu.to,
                            isDisabled,
                            sidebarExpanded,
                            setSidebarExpanded,
                            menu.IconSvg,
                            menu.menuItems ?? []
                          )
                        ) : (
                          <SideBarMenuItem
                            to={menu.to}
                            pathname={pathname}
                            title={menu.title}
                            IconSvg={menu.IconSvg}
                            moduleType={menu.moduleType}
                          />
                        )}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </React.Fragment>
          );
        }}
      </SidebarLinkGroup>
    );
  };

  const SideBarNavMenusItem = (menuItems: MenuType[]) => {
    return menuItems.map(
      (
        {
          to,
          title,
          isMenu = false,
          IconSvg,
          menuItems = [],
          moduleType,
          allowedRoles,
          isAllowed,
          isResponsibiliy,
          allowedResponsibility,
        },
        index
      ) => {
        const hasRequiredRole = allowedRoles?.includes(user?.role!) ?? false;
        const isTeamLeader =
          allowedRoles?.includes(UserRoles.TeamLeader) &&
          user?.isTeamLeader == 'True';
        const hasRequiredResponsibility =
          isResponsibiliy &&
          (allowedResponsibility?.includes(user?.responsibilityClaim ?? '') ??
            false);

        const userIsAllowed = isAllowed;
        const isDisabled = userIsAllowed
          ? false
          : moduleEnum && moduleType && validLicense
          ? !moduleEnum.some((x) => x === moduleType)
          : true;

        const shouldRenderItem =
          hasRequiredResponsibility || hasRequiredRole || isTeamLeader;

        if (!shouldRenderItem) {
          return null;
        }
        if (!isMenu)
          return (
            <li key={index}>
              <SideBarMenuItem
                to={to}
                pathname={pathname}
                title={title}
                isMenu={false}
                IconSvg={IconSvg}
                moduleType={moduleType}
                isDisabled={isDisabled}
                userIsAllowed={userIsAllowed ?? false}
              />
            </li>
          );
        else {
          return sideMenuGroup(
            index,
            title,
            to,
            isDisabled,
            sidebarExpanded,
            setSidebarExpanded,
            IconSvg,
            menuItems
          );
        }
      }
    );
  };

  return (
    <aside
      ref={sidebar}
      className={`absolute left-0 top-0 z-9999 flex h-screen  flex-col overflow-y-hidden bg-black duration-300 ease-linear dark:bg-boxdark lg:static lg:translate-x-0 ${
        sidebarOpen ? 'translate-x-0' : '-translate-x-full'
      }
      ${shrinkSideBar ? 'w-32' : 'w-72'}
      
      `}
    >
      {/* <!-- SIDEBAR HEADER --> */}
      <div className="flex items-center justify-between gap-2 px-6 py-5.5 lg:py-6.5">
        <NavLink to="/">
          <div className="flex items-center justify-center gap-2">
            <Logo className="h-14 w-14 rounded-xl object-contain pt-1" />
            {!shrinkSideBar && (
              <span className="text-2xl font-bold uppercase text-white">
                AllinOne
              </span>
            )}
          </div>
        </NavLink>

        <button
          ref={trigger}
          onClick={() => setSidebarOpen(!sidebarOpen)}
          aria-controls="sidebar"
          aria-expanded={sidebarOpen}
          className=" block lg:hidden"
        >
          <svg
            className="fill-current"
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 8.175H2.98748L9.36248 1.6875C9.69998 1.35 9.69998 0.825 9.36248 0.4875C9.02498 0.15 8.49998 0.15 8.16248 0.4875L0.399976 8.3625C0.0624756 8.7 0.0624756 9.225 0.399976 9.5625L8.16248 17.4375C8.31248 17.5875 8.53748 17.7 8.76248 17.7C8.98748 17.7 9.17498 17.625 9.36248 17.475C9.69998 17.1375 9.69998 16.6125 9.36248 16.275L3.02498 9.8625H19C19.45 9.8625 19.825 9.4875 19.825 9.0375C19.825 8.55 19.45 8.175 19 8.175Z"
              fill=""
            />
          </svg>
        </button>
      </div>
      {/* <!-- SIDEBAR HEADER --> */}

      <div className=" no-scrollbar flex flex-col overflow-y-auto duration-300 ease-linear">
        {/* <!-- Sidebar Menu --> */}
        <nav className="mt-5 px-4 py-4 lg:mt-9 lg:px-6">
          {/* <!-- Menu Group --> */}
          <div>
            <h3 className="mb-4 ml-4 text-sm font-semibold text-bodydark2">
              {t('sidebar.menu')}
            </h3>

            <ul className="mb-6 flex  flex-col gap-1.5">
              {/* <!-- Menu Item Dashboard --> */}

              {SideBarNavMenusItem(MenusItem)}

              {/* <!-- Menu Item Settings --> */}
            </ul>
          </div>
        </nav>
        {/* <!-- Sidebar Menu --> */}
      </div>
    </aside>
  );
};

export default Sidebar;
