import React, { ReactElement } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { LockClosedIcon } from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';
import { ModuleType } from '@/models/ModuleTypeEnum';
import { useUI } from '@/context/UIContext';

interface SideBarMenuItemProps {
  to: string;
  pathname?: string;
  title: string;
  isMenu?: boolean;
  IconSvg?: any;
  moduleType?: string | number;
  isDisabled?: boolean;
  userIsAllowed?: boolean;
}

const SideBarMenuItem: React.FC<SideBarMenuItemProps> = ({
  to,
  title,
  isMenu,
  IconSvg,
  moduleType,
  isDisabled,
  userIsAllowed,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { pathname } = location;
  const { setShrinkSideBar, shrinkSideBar } = useUI();

  // hover:bg-fuchsia-500 dark:hover:bg-fuchsia-500
  return (
    <NavLink
      to={to}
      className={`group relative flex items-center 
        gap-2.5 rounded-sm border-0 px-4 py-2 
        text-sm font-medium text-bodydark1
        duration-300 ease-in-out
    hover:border-l-4 hover:border-l-white hover:bg-graydark dark:hover:bg-meta-4
        
        ${pathname === to && 'border-l-4 bg-fuchsia-500 dark:bg-fuchsia-500'}
        ${isDisabled ? 'pointer-events-none opacity-60' : ''}
      `}
    >
      {IconSvg && !isDisabled && <IconSvg height={22} width={22} alt="" />}

      {!shrinkSideBar &&
        t(
          `sidebar.${ModuleType[moduleType]?.toString().toLowerCase() ?? title}`
        )}

      {isDisabled && <LockClosedIcon className="h-4 w-4 text-white" />}
    </NavLink>
  );
};

export default SideBarMenuItem;
