import React, { useMemo } from 'react';
import 'tailwindcss/tailwind.css'; // Make sure to import Tailwind CSS styles
import { RatingDataModel } from '@/models/CompanyRHDataModel'; // Replace with the correct path
import { useTranslation } from 'react-i18next';
import { RatingState } from '@/enums/RatingState';

const RatingTable: React.FC<{
  data: RatingDataModel[];
}> = ({ data }) => {
  const { t } = useTranslation();

  const totalRatingEmployees = useMemo(() => {
    if (!data || data.length === 0) {
      return 0;
    }

    // Calculate the total number of employees
    return data.reduce((total, rating) => total + rating.count, 0);
  }, [data]);
  return (
    <div className="mt-4 flex flex-col">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-3 lg:px-3">
          <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="group px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('RatingTable.employeeMetric')}
                  </th>
                  <th
                    scope="col"
                    className="group px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('RatingTable.numberOfEmployees')}
                  </th>
                  <th
                    scope="col"
                    className="group px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('RatingTable.rate')}
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white dark:border-gray-700 dark:bg-boxdark-2 dark:text-bodydark">
                {data.map((rating, index) => {
                  console.log('🚀 ~ rating, index:', rating, index);
                  return (
                    <tr key={index}>
                      <td className="whitespace-nowrap px-6 py-4">
                        {t(`RatingTable.${RatingState[rating.ratingValue]}`)}
                      </td>
                      <td className="whitespace-nowrap px-6 py-4">
                        {rating.count}
                      </td>
                      <td className="whitespace-nowrap px-6 py-4">
                        <td className="whitespace-nowrap px-6 py-4">
                          {totalRatingEmployees !== 0
                            ? `${(
                                (rating.count / totalRatingEmployees) *
                                100
                              ).toFixed(2)}%`
                            : 'N/A'}
                        </td>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RatingTable;
