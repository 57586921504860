import React from 'react';
import { useNavigate } from 'react-router-dom';
import { LabelButton, PrimaryButton } from './Button';
import { useTranslation } from 'react-i18next';

export interface IFormButtons {
  loading: boolean;
  resetForm: Function;
}

function FormButtons({ loading, resetForm }: IFormButtons) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className="mt-5">
      <PrimaryButton type="submit" className=" float-right " disabled={loading}>
        {t('Vacation.submit')}
      </PrimaryButton>
      <LabelButton
        type="button"
        id="cancelForm"
        className="!focus:border-0 !focus:outline-none !focus:ring-0 !border-0 !bg-gray-300 !text-gray-500"
        onClick={() => {
          resetForm();
          navigate(-1);
        }}
      >
        {t('Calendar.cancel')}
      </LabelButton>
    </div>
  );
}

export default FormButtons;
