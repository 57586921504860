import React, { useEffect, useState } from 'react';
import DefaultLayout from '@/layout/DefaultLayout';
import Breadcrumb from '@/components/Breadcrumb';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Spinner from '@/components/Spinner';
import { toast } from 'react-hot-toast';
import { useQuery } from '@tanstack/react-query';
import {
  QuestionResponseDto,
  getQuestionResponse,
  saveQuestionResponse,
} from '@/services/QuestionResponsesService';
import DefaultInput from '@/components/Input';
import DatePicker from '@/components/ReactDatePickerDefault';
import TimePicker from '@/components/ReactTimePickerDefault';
import moment from 'moment';
import ReactDatePickerDefault from '@/components/ReactDatePickerDefault';
import { QuestionStatus, QuestionStatusOptions } from '@/enums/QuestionStatus';
import { EmployeesResponse, getEmployees } from '@/services/EmployeeService';
import Dropdown from '@/components/DropDown';
import {
  ProjectTeamMemberResponse,
  getProjectTeamMembersByProjectId,
} from '@/services/ProjectTeamMemberService';
import FormButtons from '@/components/shared/FormButtons';
import TextArea from '@/components/TextArea';

const validationSchema = Yup.object().shape({
  questionDate: Yup.date().required(
    'QuestionResponseForm.questionDateRequired'
  ),
  questionAuthor: Yup.string().required(
    'QuestionResponseForm.questionAuthorRequired'
  ),
  question: Yup.string().required('QuestionResponseForm.questionRequired'),
  responseDate: Yup.date().required(
    'QuestionResponseForm.responseDateRequired'
  ),
  response: Yup.string().required('QuestionResponseForm.responseRequired'),
  responderId: Yup.string().required(
    'QuestionResponseForm.responderNameRequired'
  ),
  status: Yup.string().required('QuestionResponseForm.statusRequired'),
  comments: Yup.string().optional(),
});
const userTimeZone = moment.tz.guess();

interface FormValues extends QuestionResponseDto {}

function QuestionResponseForm() {
  const { responseId, id } = useParams();
  const editForm = responseId !== 'new';
  const navigate = useNavigate();

  const { t } = useTranslation();

  const [formikValuesState, setFormikValuesState] = useState<FormValues>({
    questionDate: new Date(),
    questionAuthor: '',
    question: '',
    responseDate: new Date(),
    response: '',
    responderName: '',
    status: QuestionStatus.Pending,
    comments: '',
    projectId: id!,
    responderId: '',
  });
  const [loading, setLoading] = useState(false);

  const {
    data: questionResponseData,
    isLoading,
    refetch: refetchQuestionResponse,
  } = useQuery<QuestionResponseDto | undefined>(
    ['questionResponse', responseId],
    async () => await getQuestionResponse(responseId!),
    {
      enabled: editForm,
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
      onSuccess: (questionResponse) => {
        if (questionResponse) {
          setFormikValuesState((prev) => ({ ...prev, ...questionResponse }));
        }
      },
    }
  );

  useEffect(() => {
    if (editForm && questionResponseData) {
      setFormikValuesState((prev) => ({
        ...prev,
        ...questionResponseData,

        questionDate: moment
          .utc(questionResponseData.questionDate)
          .tz(userTimeZone)
          .toDate(),
        responseDate: moment
          .utc(questionResponseData.responseDate)
          .tz(userTimeZone)
          .toDate(),
      }));
    }
  }, [editForm, questionResponseData]);

  const handleSaveQuestionResponse = async (
    values: FormValues,
    { resetForm, validateForm }: any
  ) => {
    setLoading(true);

    try {
      const questionResponse: QuestionResponseDto = {
        ...values,
      };

      const data = await saveQuestionResponse(questionResponse);
      if (data) {
        if (editForm) {
          toast.success(
            t('SuccessUpdateMessage', {
              name: t('ToastMsg.QuestionResponse'),
            })
          );
        } else {
          toast.success(
            t('SuccessMessage', {
              name: t('ToastMsg.QuestionResponse'),
            })
          );
        }
      }
      if (data && editForm) {
        refetchQuestionResponse();
      }
      navigate(-1);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
      resetForm();
    }
  };

  const { data: projectTeamMemebers, isLoading: isLoadingEmployees } = useQuery<
    ProjectTeamMemberResponse | undefined
  >(
    ['projectTeamMemebers', id],
    async () =>
      await getProjectTeamMembersByProjectId({
        page: 0,
        take: 0,
        search: '',
        projectId: id!,
      }),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );
  return (
    <DefaultLayout>
      <Breadcrumb
        pageName={
          editForm
            ? t('QuestionResponseForm.editQuestionResponse')
            : t('QuestionResponseForm.addQuestionResponse')
        }
      />
      {loading && isLoading && isLoadingEmployees ? (
        <Spinner />
      ) : (
        <Formik
          initialValues={formikValuesState}
          validationSchema={validationSchema}
          onSubmit={handleSaveQuestionResponse}
          enableReinitialize={editForm}
        >
          {({ values, errors, handleChange, handleSubmit, resetForm }) => (
            <form onSubmit={handleSubmit}>
              <div className="grid gap-2 md:grid-cols-2">
                <div className="group relative mb-6 w-full">
                  <ReactDatePickerDefault
                    name="questionDate"
                    id="questionDate"
                    label={t('QuestionResponseForm.questionDateLabel')}
                    value={values.questionDate ?? new Date()}
                    onChange={(date: Date) => {
                      handleChange({
                        target: {
                          name: 'questionDate',
                          value: date,
                        },
                      });
                    }}
                    errors={errors['questionDate']}
                  />
                </div>
                <div className="group relative mb-6 w-full">
                  <DefaultInput
                    type="text"
                    name="questionAuthor"
                    id="questionAuthor"
                    placeholder=" "
                    onChange={handleChange}
                    errors={errors}
                    value={values.questionAuthor}
                    label={t('QuestionResponseForm.questionAuthorLabel')}
                  />
                </div>
              </div>
              <div className="group relative mb-6 w-full">
                <DefaultInput
                  type="text"
                  name="question"
                  id="question"
                  placeholder=" "
                  onChange={handleChange}
                  errors={errors}
                  value={values.question}
                  label={t('QuestionResponseForm.questionLabel')}
                />
              </div>
              <div className="grid gap-2 md:grid-cols-2">
                <div className="group relative mb-6 w-full">
                  <ReactDatePickerDefault
                    name="responseDate"
                    id="responseDate"
                    label={t('QuestionResponseForm.responseDateLabel')}
                    value={values.responseDate ?? new Date()}
                    onChange={(date: Date) => {
                      handleChange({
                        target: {
                          name: 'responseDate',
                          value: date,
                        },
                      });
                    }}
                    errors={errors['responseDate']}
                  />
                </div>
                <div className="group relative mb-6 w-full">
                  {/* <DefaultInput
                    type="text"
                    name="responderName"
                    id="responderName"
                    placeholder=" "
                    onChange={handleChange}
                    errors={errors}
                    value={values.responderName}
                    label={t('QuestionResponseForm.responderNameLabel')}
                  /> */}

                  <Dropdown
                    name="responderId"
                    id="responderId"
                    optionValue="employeeId"
                    optionLabel="email"
                    options={projectTeamMemebers?.projectTeamMembers ?? []}
                    onChange={handleChange}
                    label={t('QuestionResponseForm.responderNameLabel')}
                    labelClassName="w-[50%]"
                    error={errors.responderId}
                    value={values.responderId}
                  />
                </div>
              </div>
              <div className="group relative mb-6 w-full">
                <DefaultInput
                  type="text"
                  name="response"
                  id="response"
                  placeholder=" "
                  onChange={handleChange}
                  errors={errors}
                  value={values.response}
                  label={t('QuestionResponseForm.responseLabel')}
                />
              </div>
              <div className="group relative mb-6 w-full">
                {/* <DefaultInput
                  type="text"
                  name="status"
                  id="status"
                  placeholder=" "
                  onChange={handleChange}
                  errors={errors}
                  value={values.status}
                  label={t('QuestionResponseForm.statusLabel')}
                /> */}

                <Dropdown
                  name="status"
                  id="status"
                  label={t('QuestionResponseForm.statusLabel')}
                  value={values.status}
                  translatedNameSpace="QuestionStatus"
                  isTranslated={true}
                  optionTransalatedKey="name"
                  optionValue="id"
                  onChange={handleChange}
                  labelClassName="pl-0"
                  error={errors.status}
                  options={QuestionStatusOptions ?? []}
                />
              </div>
              <div className="group relative mb-6 w-full">
                <TextArea
                  //type="text"
                  name="comments"
                  id="comments"
                  placeholder=" "
                  onChange={handleChange}
                  errors={errors}
                  value={values.comments}
                  label={t('QuestionResponseForm.commentsLabel')}
                />
              </div>
              <FormButtons resetForm={resetForm} loading={loading} />
            </form>
          )}
        </Formik>
      )}
    </DefaultLayout>
  );
}

export default QuestionResponseForm;
