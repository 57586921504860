import Breadcrumb from '@/components/Breadcrumb';
import DynamicTable, { SelectColumnFilter } from '@/components/DynamicTable';
import Pagination from '@/components/Pagination';
import Spinner from '@/components/Spinner';
import { DeleteButton, EditButton } from '@/components/shared/Button';
import DeleteConfirmationModal from '@/components/shared/DeleteConfirmationModal';
import DefaultLayout from '@/layout/DefaultLayout';
import {
  WorkCalendarsBriefResponse,
  deleteWorkCalendar,
  getWorkCalendarsBreif,
} from '@/services/WorkCalendarsService';
import { useQueryClient, useQuery, useMutation } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook
import { storedLanguage } from '@/i18n/config';
function WorkCalendar() {
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState<string>('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState('');
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { t, i18n } = useTranslation(); // Initialize the useTranslation hook

  const ActionButtonsCell = ({ value }: any) => {
    return (
      <div>
        <DeleteButton
          onClick={() => {
            setSelectedRow(value);
            setIsModalVisible(true);
          }}
        >
          {t('CalendarTypes.deleteButton')}
        </DeleteButton>
        <EditButton
          type="button"
          onClick={() => {
            navigate(`${value}`);
          }}
        >
          {t('CalendarTypes.editButton')}
        </EditButton>
      </div>
    );
  };

  const handleCancelDelete = () => {
    setIsModalVisible(false);
  };

  const handleDeleteConfirmation = () => {
    handleDeleteCalendarType.mutate(selectedRow);
    setIsModalVisible(false);
  };

  const columns = useMemo(
    () => [
      {
        Header: t('CalendarTypes.actionsColumn'),
        accessor: 'id',
        Cell: ({ cell: { value } }: any) => (
          <ActionButtonsCell
            value={value}
            handleDelete={handleDeleteCalendarType}
          />
        ),
      },
      {
        Header: t('CalendarTypes.nameColumn'),
        accessor: 'name',
        Filter: SelectColumnFilter,
      },
      {
        Header: t('CalendarTypes.weekendDaysColumn'),
        accessor: 'weekends',
        Cell: ({ cell: { value } }) => (
          <div
            className={'max-h-20 w-[100px] overflow-y-auto whitespace-pre-wrap'}
          >
            {value?.split(',').map((dayName, index) => (
              <div key={index}>{t(`Calendar.${dayName}`)}</div>
            ))}
          </div>
        ),
      },
      {
        Header: t('CalendarTypes.holidaysColumn'),
        accessor: 'holidays',
        Cell: ({ cell: { value } }) => (
          <div
            className={'max-h-20 w-[250px] overflow-y-auto whitespace-pre-wrap'}
          >
            {value?.split(',').map((dateRange, index) => (
              <div key={index}>{dateRange.replace('to', '->')}</div>
            ))}
          </div>
        ),
      },
      {
        Header: t('CalendarTypes.enterpriseClosingColumn'),
        accessor: 'enterpriseClosingDates',
        Cell: ({ cell: { value } }) => (
          <div
            className={'max-h-20 w-[250px] overflow-y-auto whitespace-pre-wrap'}
          >
            {value?.split(',').map((dateRange, index) => (
              <div key={index}>{dateRange.replace('to', '->')}</div>
            ))}
          </div>
        ),
      },
    ],
    [t]
  );

  const {
    isLoading,
    isError,
    data: calendarTypes,
  } = useQuery<WorkCalendarsBriefResponse | undefined>(
    ['workCalendarsBrief', pageIndex, pageSize, search, i18n.language],
    () =>
      getWorkCalendarsBreif({
        fromvalue: pageIndex,
        takevalue: pageSize,
        search: search,
      }),
    {
      refetchOnWindowFocus: false,
      staleTime: 4000,
      keepPreviousData: true,
    }
  );

  const handleDeleteCalendarType = useMutation(
    async (id: string) => {
      await deleteWorkCalendar(id);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          'workCalendars',
          pageIndex,
          pageSize,
          search,
        ]);
      },
    }
  );

  let pageCount = Math.ceil((calendarTypes?.count ?? 0) / pageSize);

  const nextPage = () => setPageIndex(pageIndex + 1);
  const previousPage = () => setPageIndex(pageIndex - 1);
  const onPageChange = (newPageIndex: number) => setPageIndex(newPageIndex);
  const onPageSizeChange = (newPageSize: number) => {
    setPageIndex(0);
    setPageSize(newPageSize);
  };

  return (
    <DefaultLayout>
      <Breadcrumb
        pageName={t('CalendarTypes.pageName')}
        addbtn={true}
        addRouter="new"
      />
      {isLoading ? (
        <div className="flex items-center justify-center">
          <Spinner height="3rem" width="3rem" />
        </div>
      ) : (
        <div className="flex flex-col">
          <DynamicTable
            dataCount={calendarTypes?.count}
            data={calendarTypes?.workCalendars ?? []}
            columns={columns}
            setSearch={setSearch}
            exportPdf={false}
          />
          {isModalVisible && (
            <DeleteConfirmationModal
              isOpen={isModalVisible}
              onDelete={handleDeleteConfirmation}
              onCancel={handleCancelDelete}
            />
          )}
          <Pagination
            pageIndex={pageCount === 0 ? -1 : pageIndex}
            pageCount={pageCount}
            pageSize={pageSize}
            onNextPage={nextPage}
            onPreviousPage={previousPage}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
          />
        </div>
      )}
    </DefaultLayout>
  );
}

export default WorkCalendar;
