import React, { useEffect, useState } from 'react';
import { MultiStepFormProps } from '../EmployeeForm';

import { Formik } from 'formik';
import * as Yup from 'yup';
import DatePickerDefault from '@/components/DatePicker';
import DefaultInput from '@/components/Input'; // Assuming you have the DefaultInput component
import Dropdown from '@/components/DropDown';
import { useTranslation } from 'react-i18next';
import TextArea from '@/components/TextArea';
import { useDispatch, useSelector } from 'react-redux';
import { updateContractValue } from '@/state/slices/employeeSlice';
import { useParams } from 'react-router-dom';
import { RootState } from '@/state';
import { ContractType, ContractTypeOptions } from '@/enums/contractType';
import {
  ProfessionalStatus,
  professionalStatusOptions,
} from '@/enums/ProfessionalStatus';
import { genderOptions } from '@/enums/genderEnum';
import ReactDatePickerDefault from '@/components/ReactDatePickerDefault';

const validationSchema = Yup.object().shape({
  startDate: Yup.date().required('Contract.endDateRequired'),

  endDate: Yup.date()
    .min(Yup.ref('startDate'), 'Contract.endDateMin')
    .test(
      'is-after-start-date',
      'Contract.endDateAfterStartDate',
      function (value) {
        const startDate = this.resolve(Yup.ref('startDate'));
        if (!startDate || !value) return true; // Let the required validation handle empty values

        const oneDayMilliseconds = 24 * 60 * 60 * 1000; // One day in milliseconds
        return value.getTime() >= startDate.getTime() + oneDayMilliseconds;
      }
    )
    .required('Contract.endDateRequired'),
  contractType: Yup.string().required('Contract.contractTypeRequired'),
  //professionalStatus: Yup.string().required(
  //   'Contract.professionalStatusRequired'
  //),
  //qualification: Yup.string().required('Contract.qualificationRequired'),
  coefficient: Yup.number()
    .typeError('Contract.coefficientInvalid')
    .required('Contract.coefficientRequired')
    .min(0, 'Contract.coefficientMin'),
  //contractReason: Yup.string().required('Contract.contractReasonRequired'),
  comment: Yup.string().optional(),
});

interface ContractFormValues {
  startDate: Date;
  endDate: Date;
  contractType?: string;
  professionalStatus?: string;
  qualification?: string;
  coefficient?: number;
  contractReason?: string;
  comment?: string;
}

function ContractEmployeeInformation({
  currentStep,
  setCurrentStep,
}: MultiStepFormProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { id } = useParams();
  const employeeId = id !== 'new';

  const {
    employee,
    contract,
    user,
    loading: loadingRedux,
  } = useSelector((state: RootState) => state.employees);

  const [initialValues, setInitialValues] = useState<ContractFormValues>({
    ...contract,
  });
  useEffect(() => {
    if (employeeId && contract) {
      setInitialValues({
        ...initialValues,
        ...contract,
      });
    }
  }, [contract, employeeId, loadingRedux]);
  const handleMoveNext = (
    values: ContractFormValues,
    { resetForm, validateForm }: any
  ) => {
    // Handle form submission and state updates here
    dispatch(
      updateContractValue({ contractForm: { ...values, employeeId: '' } })
    );
    setCurrentStep((prevStep) => prevStep + 1);
    resetForm();
  };

  return (
    <div className="m-4 pt-5">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleMoveNext}
        enableReinitialize={employeeId}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue,
          resetForm,
        }) => (
          <form onSubmit={handleSubmit}>
            {/* Input fields */}
            <div className="grid md:grid-cols-2 md:gap-6">
              <div className="group relative  mb-6 w-full">
                <ReactDatePickerDefault
                  id="startDate"
                  value={values.startDate}
                  name="startDate"
                  label={t('Contract.startDate')}
                  defaultDate={values.startDate}
                  onChange={(date: any) => {
                    handleChange({
                      target: {
                        name: 'startDate',
                        value: date,
                      },
                    });
                  }}
                  errors={errors.startDate}
                />
              </div>
              <div className="group relative  mb-6 w-full">
                <ReactDatePickerDefault
                  id="endDate"
                  value={values.endDate}
                  name="endDate"
                  label={t('Contract.endDate')}
                  defaultDate={values.endDate}
                  onChange={(date: any) => {
                    handleChange({
                      target: {
                        name: 'endDate',
                        value: date,
                      },
                    });
                  }}
                  errors={errors.endDate}
                />
              </div>
            </div>
            <div className="grid md:grid-cols-2 md:gap-6">
              <div className="group relative z-0 mb-6 w-full">
                <Dropdown
                  id="contractType"
                  name="contractType"
                  optionValue="id"
                  optionLabel="name"
                  onChange={(e) => {
                    setFieldValue('contractType', e.target.value);
                  }}
                  className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  options={ContractTypeOptions.map((g) => {
                    return {
                      ...g,
                      name: t(`ContractType.${ContractType[g.id ?? 1]}`),
                    };
                  })}
                  label={t('Contract.contractType')}
                  error={errors.contractType}
                  value={values.contractType}
                />
              </div>
              <div className="group relative z-0 mb-6 w-full">
                {/* <DefaultInput
                  type="text"
                  name="professionalStatus"
                  id="professionalStatus"
                  placeholder=" "
                  onChange={handleChange}
                  value={values.professionalStatus}
                  label={t('Contract.professionalStatus')}
                  errors={errors}
                /> */}

                <Dropdown
                  id="professionalStatus"
                  name="professionalStatus"
                  value={
                    values.professionalStatus ??
                    ProfessionalStatus.BachelorPlus5
                  }
                  optionValue="id"
                  onChange={handleChange}
                  labelClassName="w-[70%]"
                  className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  options={professionalStatusOptions.map((g) => {
                    return {
                      ...g,
                      name: t(
                        `ProfessionalStatus.${ProfessionalStatus[g.id ?? 2]}`
                      ),
                    };
                  })}
                  label={t('Employee.professionalStatus')}
                />
              </div>
            </div>
            <div className="grid md:grid-cols-2 md:gap-6">
              <div className="group relative z-0 mb-6 w-full">
                <DefaultInput
                  type="text"
                  name="qualification"
                  id="qualification"
                  placeholder=" "
                  onChange={handleChange}
                  value={values.qualification}
                  label={t('Contract.qualification')}
                  errors={errors}
                />
              </div>
              <div className="group relative z-0 mb-6 w-full">
                <DefaultInput
                  type="number"
                  name="coefficient"
                  id="coefficient"
                  placeholder=" "
                  onChange={handleChange}
                  value={values.coefficient}
                  label={t('Contract.coefficient')}
                  errors={errors}
                />
              </div>
            </div>
            <div className="grid md:grid-cols-2 md:gap-6">
              <div className="group relative z-0 mb-6 w-full">
                <DefaultInput
                  type="text"
                  name="contractReason"
                  id="contractReason"
                  placeholder=" "
                  onChange={handleChange}
                  value={values.contractReason}
                  label={t('Contract.contractReason')}
                  errors={errors}
                />
              </div>
              <div className="group relative z-0 mb-6 w-full">
                <TextArea
                  id="comment"
                  name="comment"
                  label={t('Contract.comment')}
                  errors={errors}
                  value={values.comment}
                  onChange={handleChange}
                />

                {/* <textarea
                  name="comment"
                  id="comment"
                  placeholder=" "
                  onChange={handleChange}
                  value={values.comment}
                  className="block w-full resize-none rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500"
                />
                <label
                  htmlFor="comment"
                  className="absolute left-3 top-2 bg-white px-1"
                >
                  {t('Contract.comment')}
                </label> */}
              </div>
            </div>

            {/* Buttons */}
            <button
              type="submit"
              className="float-right mt-4 rounded-lg bg-blue-600 px-4 py-2 text-white"
            >
              {t('stepper.next')}
            </button>
            <button
              type="button"
              onClick={() => setCurrentStep(currentStep - 1)}
              className=" mt-4 rounded-lg bg-blue-600 px-4 py-2 text-white"
            >
              {t('stepper.back')}
            </button>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default ContractEmployeeInformation;
