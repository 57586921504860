import { useEffect, useState } from 'react';
import DefaultLayout from '@/layout/DefaultLayout';
import Breadcrumb from '@/components/Breadcrumb';
import DefaultInput from '@/components/Input';
import { Formik, FormikState } from 'formik';
import * as Yup from 'yup';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import Spinner from '@/components/Spinner';
import { useNavigate, useParams } from 'react-router-dom';
import DatePickerDefault from '@/components/DatePicker';
import Checkbox from '@/components/Checkbox';
import TextArea from '@/components/TextArea';
import { useAuth } from '@/context/AuthContext';
import toast from 'react-hot-toast';
import moment from 'moment-timezone';
import {
  TrainingRequest,
  getTrainingRequest,
  saveTrainingRequest,
} from '@/services/TrainingRequestService';
import { getCompanyId } from '@/services/AuthService';
import Dropdown from '@/components/DropDown';
import { TrainingTypeOptions } from '@/enums/TrainingType';
import { TrainingRequestTypeOptions } from '@/enums/TrainingRequestType';
import { LabelButton, PrimaryButton } from '@/components/shared/Button';

const userTimeZone = moment.tz.guess();
const timeZoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;
const validationSchema = Yup.object().shape({
  trainingProviderName: Yup.string().required(
    'TrainingRequest.trainingProviderNameRequired'
  ),
  requestedTrainingTitle: Yup.string().required(
    'TrainingRequest.requestedTrainingTitle'
  ),
  trainingProviderAddress: Yup.string().required(
    'TrainingRequest.trainingProviderAddressRequired'
  ),
  trainingProviderPhoneNumber: Yup.string()
    .test(
      'phone-format',
      'TrainingRequest.trainingProviderPhoneNumberInvalid',
      (value) => {
        if (!value || value.trim() === '') {
          return true; // Allow empty values
        }
        return /^\+[0-9]+$/.test(value);
      }
    )
    .required('TrainingRequest.trainingProviderPhoneNumberRequired'),
  trainingProviderEmail: Yup.string()
    .email()
    .required('TrainingRequest.trainingProviderEmailInvalid'),
  trainingProviderWebsite: Yup.string().url(
    'TrainingRequest.trainingProviderWebsiteInvalid'
  ),
  trainingManagerName: Yup.string().required(
    'TrainingRequest.trainingManagerNameRequired'
  ),
  trainingObjectives: Yup.string(),
  trainingStartDate: Yup.date().required(
    'TrainingRequest.trainingStartDateRequired'
  ),
  trainingEndDate: Yup.date()
    .min(Yup.ref('trainingStartDate'), 'TrainingRequest.trainingEndDateMin')
    .required('TrainingRequest.trainingEndDateRequired'),
  totalTrainingHours: Yup.number()
    .required('TrainingRequest.totalTrainingHoursRequired')
    .min(1, 'TrainingRequest.totalTrainingHoursMin'),

  trainingCost: Yup.number()
    .required('TrainingRequest.trainingCostRequired')
    .min(0, 'TrainingRequest.trainingCostMin'),
  cpfFinanced: Yup.boolean(),
  diplomaIssued: Yup.boolean(),
  obtainedDiplomaTitle: Yup.string().when(
    'diplomaIssued',
    (diplomaIssued, schema) => {
      return diplomaIssued
        ? schema.required('TrainingRequest.obtainedDiplomaTitleRequired')
        : schema.default('');
    }
  ),
  trainingLevel: Yup.string().required('TrainingRequest.trainingLevelRequired'),
  workApplicationTraining: Yup.boolean(),
  satisfactionFormSent: Yup.boolean(),
  trainingType: Yup.string().required('TrainingRequest.trainingTypeRequired'),
  trainingRequestType: Yup.string().required(
    'TrainingRequest.trainingRequestTypeRequired'
  ),
});

interface FormValues extends TrainingRequest {}

function TrainingRequestForm() {
  const { id } = useParams();
  const editForm = id !== 'new';
  const navigate = useNavigate();
  const { user } = useAuth();
  const { t, i18n } = useTranslation();

  const [formikValuesState, setFormikValuesState] = useState<FormValues>({
    trainingProviderName: '',
    trainingProviderAddress: '',
    trainingProviderPhoneNumber: '',
    trainingProviderEmail: '',
    trainingProviderWebsite: '',
    trainingManagerName: '',
    trainingObjectives: '',
    trainingStartDate: new Date(),
    trainingEndDate: new Date(),
    totalTrainingHours: 0,
    rating: undefined,
    trainingCost: 0,
    cpfFinanced: false,
    diplomaIssued: false,
    obtainedDiplomaTitle: '',
    trainingLevel: '',
    internalTraining: false,
    externalTraining: false,
    employeeRequestedTraining: false,
    managerRequestedTraining: false,
    workApplicationTraining: false,
    satisfactionFormSent: false,
    requestedTrainingTitle: '',
    remarks: '',
  });

  const {
    data: trainingData,
    isLoading,
    isError,
    refetch: refetchTraining,
  } = useQuery<FormValues>(
    ['training', id],
    async () => await getTrainingRequest(id!),
    {
      enabled: editForm,
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
      onSuccess: (training) => {
        if (training) {
          setFormikValuesState((prev) => ({ ...prev, ...training }));
        }
      },
    }
  );

  useEffect(() => {
    if (editForm && trainingData) {
      // Update form values with training data
      setFormikValuesState((prev) => ({ ...prev, ...trainingData }));
    }
  }, [editForm, trainingData]);

  const handleSaveTraining = async (
    values: FormValues,
    { resetForm, validateForm }: any
  ) => {
    // Handle saving training data
    try {
      const training: TrainingRequest = {
        ...values,
        employeeId: user?.id,
        companyId: getCompanyId()!.toString(),
      };
      // Use the saveTraining function to save the data
      const data = await saveTrainingRequest(training);
      if (data) {
        if (editForm) {
          toast.success(
            t('SuccessUpdateMessage', {
              name: t('ToastMsg.trainingRequestForm'),
            })
          );
        } else {
          toast.success(
            t('SuccessMessage', {
              name: t('ToastMsg.trainingRequestForm'),
            })
          );
        }
      }
      // Additional logic after saving if needed
      // Navigate back to the previous page
      navigate(-1);
    } catch (error) {
      console.error('Error saving training:', error);
    } finally {
      resetForm();
    }
  };

  const formButtons = (
    resetForm: (
      nextState?: Partial<FormikState<FormValues>> | undefined
    ) => void,
    values: FormValues
  ) => {
    return (
      <>
        <PrimaryButton type="submit" className=" float-right ">
          {editForm
            ? t('TrainingRequest.SaveChanges')
            : t('TrainingRequest.SubmitRequest')}
        </PrimaryButton>
        <LabelButton
          type="button"
          id="cancelForm"
          className="!focus:border-0 !focus:outline-none !focus:ring-0 !border-0 !bg-gray-300 !text-gray-500"
          onClick={() => {
            resetForm();
            navigate(-1);
          }}
        >
          {t('TrainingRequest.Cancel')}
        </LabelButton>
      </>
    );
  };
  return (
    <DefaultLayout>
      <Breadcrumb
        pageName={
          editForm
            ? t('TrainingRequest.EditTrainingRequest')
            : t('TrainingRequest.AddTrainingRequest')
        }
      />

      {editForm && isLoading ? (
        <Spinner />
      ) : (
        <Formik
          initialValues={formikValuesState}
          validationSchema={validationSchema}
          onSubmit={handleSaveTraining}
          enableReinitialize={editForm}
        >
          {({
            values,
            errors,
            handleChange,
            handleSubmit,
            resetForm,
            setFieldValue,
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div className="grid md:grid-cols-2 md:gap-6">
                  <div className="group relative z-0 mb-6 w-full">
                    <DefaultInput
                      labelClassName="w-[80%]"
                      type="text"
                      name="requestedTrainingTitle"
                      id="requestedTrainingTitle"
                      placeholder=" "
                      onChange={handleChange}
                      errors={errors}
                      value={values.requestedTrainingTitle}
                      label={t('TrainingRequest.requestedTrainingTitle')}
                    />
                  </div>
                  <div className="group relative z-0 mb-6 w-full">
                    <DefaultInput
                      labelClassName="w-[80%]"
                      type="text"
                      name="trainingProviderName"
                      id="trainingProviderName"
                      placeholder=" "
                      onChange={handleChange}
                      errors={errors}
                      value={values.trainingProviderName}
                      label={t('TrainingRequest.trainingProviderName')}
                    />
                  </div>
                </div>
                <div className="grid md:grid-cols-2 md:gap-6">
                  <div className="group relative z-0 mb-6 w-full">
                    <DefaultInput
                      labelClassName="w-[80%]"
                      type="email"
                      name="trainingProviderEmail"
                      id="trainingProviderEmail"
                      placeholder=" "
                      onChange={handleChange}
                      errors={errors}
                      value={values.trainingProviderEmail}
                      label={t('TrainingRequest.trainingProviderEmail')}
                    />
                  </div>
                  <div className="group relative z-0 mb-6 w-full">
                    <DefaultInput
                      labelClassName="w-[80%]"
                      type="text"
                      name="trainingProviderPhoneNumber"
                      id="trainingProviderPhoneNumber"
                      placeholder=" "
                      onChange={handleChange}
                      errors={errors}
                      value={values.trainingProviderPhoneNumber}
                      label={t('TrainingRequest.trainingProviderPhoneNumber')}
                    />
                  </div>
                </div>

                <div className="grid md:grid-cols-2 md:gap-6">
                  <div className="group relative z-0 mb-6 w-full">
                    <DefaultInput
                      labelClassName="w-[80%]"
                      type="text"
                      name="trainingProviderAddress"
                      id="trainingProviderAddress"
                      placeholder=" "
                      onChange={handleChange}
                      errors={errors}
                      value={values.trainingProviderAddress}
                      label={t('TrainingRequest.trainingProviderAddress')}
                    />
                  </div>
                  <div className="group relative z-0 mb-6 w-full">
                    <DefaultInput
                      labelClassName="w-[80%]"
                      type="text"
                      name="trainingProviderWebsite"
                      id="trainingProviderWebsite"
                      placeholder=" "
                      onChange={handleChange}
                      errors={errors}
                      value={values.trainingProviderWebsite}
                      label={t('TrainingRequest.trainingProviderWebsite')}
                    />
                  </div>
                </div>
                <div className="grid md:grid-cols-2 md:gap-6">
                  <div className="group relative z-0 mb-6 w-full">
                    <DefaultInput
                      labelClassName="w-[80%]"
                      type="text"
                      name="trainingManagerName"
                      id="trainingManagerName"
                      placeholder=" "
                      onChange={handleChange}
                      errors={errors}
                      value={values.trainingManagerName}
                      label={t('TrainingRequest.trainingManagerName')}
                    />
                  </div>
                  <div className="group relative z-0 mb-6 w-full">
                    <TextArea
                      id="trainingObjectives"
                      name="trainingObjectives"
                      label={t('TrainingRequest.trainingObjectives')}
                      errors={errors}
                      value={values.trainingObjectives}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="grid md:grid-cols-2 md:gap-6">
                  <div className="group relative mb-6 w-full">
                    <DatePickerDefault
                      id="trainingStartDate"
                      labelClassName="w-[80%]"
                      value={new Date(values.trainingStartDate)}
                      name="trainingStartDate"
                      label={t('TrainingRequest.trainingStartDate')}
                      defaultDate={values.trainingStartDate}
                      onChange={(date: any) => {
                        handleChange({
                          target: {
                            name: 'trainingStartDate',
                            value: date,
                          },
                        });
                      }}
                      errors={errors.trainingStartDate}
                    />
                  </div>
                  <div className="group relative  mb-6 w-full">
                    <DatePickerDefault
                      id="trainingEndDate"
                      labelClassName="w-[80%]"
                      value={new Date(values.trainingEndDate)}
                      name="trainingEndDate"
                      label={t('TrainingRequest.trainingEndDate')}
                      defaultDate={values.trainingEndDate}
                      onChange={(date: any) => {
                        handleChange({
                          target: {
                            name: 'trainingEndDate',
                            value: date,
                          },
                        });
                      }}
                      errors={errors.trainingEndDate}
                    />
                  </div>
                </div>

                <div className="grid md:grid-cols-2 md:gap-6">
                  <div className="group relative z-0 mb-6 w-full">
                    <DefaultInput
                      labelClassName="w-[80%]"
                      type="number"
                      name="totalTrainingHours"
                      id="totalTrainingHours"
                      placeholder=" "
                      onChange={handleChange}
                      errors={errors}
                      value={values.totalTrainingHours}
                      label={t('TrainingRequest.totalTrainingHours')}
                    />
                  </div>
                  <div className="group relative z-0 mb-6 w-full">
                    <DefaultInput
                      labelClassName="w-[80%]"
                      type="number"
                      name="trainingCost"
                      id="trainingCost"
                      placeholder=" "
                      onChange={handleChange}
                      errors={errors}
                      value={values.trainingCost}
                      label={t('TrainingRequest.trainingCost')}
                    />
                  </div>
                </div>

                <div className="grid md:grid-cols-2 md:gap-6">
                  <div className="group relative z-0 mb-6 w-full">
                    <DefaultInput
                      labelClassName="w-[80%]"
                      type="text"
                      name="trainingLevel"
                      id="trainingLevel"
                      placeholder=" "
                      onChange={handleChange}
                      errors={errors}
                      value={values.trainingLevel}
                      label={t('TrainingRequest.trainingLevel')}
                    />
                  </div>
                  <div className="group relative z-0 mb-6 w-full">
                    <DefaultInput
                      labelClassName="w-[80%]"
                      type="text"
                      name="obtainedDiplomaTitle"
                      id="obtainedDiplomaTitle"
                      placeholder=" "
                      onChange={handleChange}
                      errors={errors}
                      value={values.obtainedDiplomaTitle}
                      label={t('TrainingRequest.obtainedDiplomaTitle')}
                    />
                  </div>
                </div>

                <div className="grid md:grid-cols-2 md:gap-6">
                  <div className="group relative z-0 mb-6 w-full">
                    <Dropdown
                      id="trainingType"
                      name="trainingType"
                      optionValue="id"
                      onChange={(e) => {
                        setFieldValue('trainingType', e.target.value);
                      }}
                      className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                      options={TrainingTypeOptions}
                      label={t('TrainingRequest.trainingType')}
                      error={errors.trainingType}
                      value={values.trainingType}
                      labelClassName="w-[80%]"
                      isTranslated={true}
                      translatedNameSpace="TrainingTypes"
                      optionTransalatedKey="name"
                    />
                    {/* <Checkbox
                      id="internalTraining"
                      name="internalTraining"
                      checked={values.internalTraining}
                      onChange={handleChange}
                      label={t('TrainingRequest.internalTraining')}
                    /> */}
                  </div>

                  <div className="group relative z-0 mb-6 w-full">
                    <Dropdown
                      id="trainingRequestType"
                      name="trainingRequestType"
                      optionValue="id"
                      onChange={(e) => {
                        setFieldValue('trainingRequestType', e.target.value);
                      }}
                      isTranslated={true}
                      labelClassName="w-[80%]"
                      translatedNameSpace="TrainingDemender"
                      optionTransalatedKey="name"
                      className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                      options={TrainingRequestTypeOptions}
                      label={t('TrainingRequest.trainingRequestType')}
                      error={errors.trainingRequestType}
                      value={values.trainingRequestType}
                    />
                    {/* <Checkbox
                      id="externalTraining"
                      name="externalTraining"
                      checked={values.externalTraining}
                      onChange={handleChange}
                      label={t('TrainingRequest.externalTraining')}
                    /> */}
                  </div>
                </div>
                <div className="grid md:grid-cols-4 md:gap-6">
                  <div className="group relative z-0 mb-6 w-full">
                    <Checkbox
                      id="diplomaIssued"
                      name="diplomaIssued"
                      checked={values.diplomaIssued}
                      onChange={handleChange}
                      label={t('TrainingRequest.diplomaIssued')}
                    />
                  </div>
                  <div className="group relative z-0 mb-6 w-full">
                    <Checkbox
                      id="cpfFinanced"
                      name="cpfFinanced"
                      checked={values.cpfFinanced}
                      onChange={handleChange}
                      label={t('TrainingRequest.cpfFinanced')}
                    />
                  </div>
                </div>

                {/* <div className="grid md:grid-cols-4 md:gap-6">
                  <div className="group relative z-0 mb-6 w-full">
                    <Checkbox
                      id="employeeRequestedTraining"
                      name="employeeRequestedTraining"
                      checked={values.employeeRequestedTraining}
                      onChange={handleChange}
                      label={t('TrainingRequest.employeeRequestedTraining')}
                    />
                  </div>
                  <div className="group relative z-0 mb-6 w-full">
                    <Checkbox
                      id="managerRequestedTraining"
                      name="managerRequestedTraining"
                      checked={values.managerRequestedTraining}
                      onChange={handleChange}
                      label={t('TrainingRequest.managerRequestedTraining')}
                    />
                  </div>
                  <div className="group relative z-0 mb-6 w-full">
                    <Checkbox
                      id="workApplicationTraining"
                      name="workApplicationTraining"
                      checked={values.workApplicationTraining}
                      onChange={handleChange}
                      label={t('TrainingRequest.workApplicationTraining')}
                    />
                  </div>
                </div> */}

                <div className="group relative z-0 mb-6 w-full">
                  <TextArea
                    id="remarks"
                    name="remarks"
                    label={t('TrainingRequest.remarks')}
                    errors={errors}
                    value={values.remarks}
                    onChange={handleChange}
                  />
                </div>
                {formButtons(resetForm, values)}
              </form>
            );
          }}
        </Formik>
      )}
    </DefaultLayout>
  );
}

export default TrainingRequestForm;
