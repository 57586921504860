import { useMemo, useState } from 'react';
import DefaultLayout from '@/layout/DefaultLayout';
import Breadcrumb from '@/components/Breadcrumb';
import DynamicTable, { SelectColumnFilter } from '@/components/DynamicTable';
import Pagination from '@/components/Pagination';
import Spinner from '@/components/Spinner';
import DeleteConfirmationModal from '@/components/shared/DeleteConfirmationModal';
import { useQueryClient, useQuery, useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAuth } from '@/context/AuthContext';
import { UserRoles } from '@/enums/UsersRole';
import { stateColors } from '@/enums/RequestLeaveState';
import moment from 'moment-timezone';
import {
  AnnualInterviewsBriefResponse,
  ChangeInterviewStateProps,
  changeInterviewState,
  deleteAnnualInterview,
  getAnnualInterviewsBrief,
  getAnnualInterviewsByEmployeeId,
  getAnnualInterviewsByResponsibleId,
} from '@/services/AnnualInterviewService';
import { InterviewState } from '@/enums/InterviewState';
import {
  DeleteButton,
  EditButton,
  PrimaryButton,
} from '@/components/shared/Button';
const userTimeZone = moment.tz.guess();
// const timeZoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;

function AnnualInterviews() {
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState<string>('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState('');
  const [annualInerviewsData, setAnnualInerviewsData] =
    useState<AnnualInterviewsBriefResponse>();
  const [isAnnualInerviewsLoading, setAnnualInerviewsLoading] = useState(true);
  const navigate = useNavigate();
  const { user } = useAuth();
  const { t } = useTranslation();
  const isAnnualinterviewAdmin =
    user?.isTeamLeader == 'True' || user?.role == UserRoles.Admin;
  const ActionButtonsCell = ({ value, state, rowData }: any) => {
    var isInterviewTime = moment
      .utc(rowData.original['interviewDate'])
      .tz(userTimeZone)
      .toDate();

    const responsableId = rowData.original['responsableId'];
    var currentTime = new Date();
    var isTime = currentTime >= isInterviewTime;

    const handleStartAInterview = async () => {
      const aInterviewState: ChangeInterviewStateProps = {
        id: value,
        interviewState: InterviewState.InProgress,
      };

      await changeInterviewState(aInterviewState);
      navigate(`start-annual-interview/${value}`);
    };
    return (
      <div className="flex ">
        {isTime && responsableId && (
          <PrimaryButton
            disabled={state !== InterviewState.Scheduled}
            type="button"
            onClick={handleStartAInterview}
          >
            {t('AnnualInterviews.start')}
          </PrimaryButton>
        )}

        {state !== InterviewState.Scheduled && (
          <PrimaryButton
            type="button"
            onClick={() => navigate(`start-annual-interview/${value}`)}
          >
            {t('AnnualInterviews.interviewDetail')}
          </PrimaryButton>
        )}

        {responsableId && state == InterviewState.Scheduled ? (
          <div>
            <DeleteButton
              onClick={() => {
                setSelectedRow(value);
                setIsModalVisible(true);
              }}
            >
              {t('Appointments.deleteButton')}
            </DeleteButton>

            <EditButton
              type="button"
              onClick={() => {
                navigate(`${value}`);
              }}
            >
              {t('Appointments.editButton')}
            </EditButton>
          </div>
        ) : (
          <div>
            <PrimaryButton
              type="button"
              disabled={state !== InterviewState.Scheduled}
              onClick={() => {
                navigate(`${value}`);
              }}
            >
              {t('Appointments.Schedule')}
            </PrimaryButton>
          </div>
        )}
      </div>
    );
  };

  const handleCancelDelete = () => {
    setIsModalVisible(false);
  };

  const handleDeleteConfirmation = () => {
    handleDeleteAI.mutate(selectedRow);
    setIsModalVisible(false);
  };

  const columns = useMemo(
    () => [
      {
        Header: t('Appointments.actionsColumn'),
        accessor: 'id',
        Cell: ({ row, cell: { value } }: any) => {
          return (
            <ActionButtonsCell
              value={value}
              rowData={row}
              handleDelete={handleDeleteAI}
              state={row.original['interviewState']}
            />
          );
        },
      },
      {
        Header: t('AnnualInterviews.employeeFullName'),
        accessor: 'employeeFullName',
        Filter: SelectColumnFilter,
      },
      {
        Header: t('AnnualInterviews.responsibleFullName'),
        accessor: 'responsibleFullName',
        Filter: SelectColumnFilter,
      },
      {
        Header: t('AnnualInterviews.interviewDate'),
        accessor: 'interviewDate',
        Cell: ({ cell: { value } }: any) => {
          const formattedValue = moment
            .utc(value)
            .tz(userTimeZone)
            .format('YYYY-MM-DD hh:mm');
          return <div>{formattedValue}</div>;
        },
      },
      {
        Header: t('AnnualInterviews.interviewState'),
        accessor: 'interviewState',
        Cell: ({ cell: { value } }: any) => {
          const frontendState = InterviewState[value];
          //@ts-ignore
          const bgColor = stateColors[frontendState] || 'bg-gray-300';

          return (
            <div
              className={`rounded-md text-center ${bgColor} px-2.5  py-2.5 text-sm text-white`}
            >
              {frontendState !== null
                ? //@ts-ignore
                  t(`InterviewState.${frontendState}`)
                : ''}
            </div>
          );
        },
      },
    ],
    [t]
  );

  const queryClient = useQueryClient();

  const { data: annualInterviews } = useQuery<
    AnnualInterviewsBriefResponse | undefined
  >(
    ['annaulInterviews', pageIndex, pageSize, search, user?.id],
    () => {
      if (user?.role == UserRoles.Admin) {
        return getAnnualInterviewsBrief({
          fromvalue: pageIndex,
          takevalue: pageSize,
          search: search,
        });
      } else {
        if (user?.isTeamLeader == 'True') {
          return getAnnualInterviewsByResponsibleId({
            fromvalue: pageIndex,
            takevalue: pageSize,
            search: search,
            responsibleId: user.id,
          });
        } else {
          return getAnnualInterviewsByEmployeeId({
            fromvalue: pageIndex,
            takevalue: pageSize,
            search: search,
            employeeId: user!.id,
          });
        }
      }
    },

    {
      onSuccess: (data) => {
        if (data) {
          setAnnualInerviewsData(data);
          setAnnualInerviewsLoading(false);
        }
      },
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
      //enabled: user?.id !== null,
    }
  );

  //   const { isLoading: isUserAppointmentsLoading, data: userAppointments } =
  //     useQuery<AppointmentsResponse | undefined>(
  //       ['UserAppointments', pageIndex, pageSize, search],
  //       () =>
  //         getAppointmentsByUserId({
  //           fromvalue: pageIndex,
  //           takevalue: pageSize,
  //           search: search,
  //           userId: user!.id,
  //         }),
  //       {
  //         refetchOnWindowFocus: false,
  //         staleTime: 6000,
  //         keepPreviousData: true,
  //         enabled: !isMedicalVisiteAdmin,
  //         onSuccess: (data) => {
  //           if (data) {
  //             setAppointmentsData(data);
  //             setAppointmentsLoading(false);
  //           }
  //         },
  //       }
  //     );

  const handleDeleteAI = useMutation(
    async (id: string) => {
      await deleteAnnualInterview(id);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          'annualinterviews',
          pageIndex,
          pageSize,
          search,
        ]);
      },
    }
  );

  const pageCount = useMemo(() => {
    return Math.ceil((annualInterviews?.count ?? 0) / pageSize);
  }, [pageSize, annualInterviews]);

  const nextPage = () => setPageIndex(pageIndex + 1);
  const previousPage = () => setPageIndex(pageIndex - 1);
  const onPageChange = (newPageIndex: number) => setPageIndex(newPageIndex);
  const onPageSizeChange = (newPageSize: number) => {
    setPageIndex(0);
    setPageSize(newPageSize);
  };

  return (
    <DefaultLayout>
      <Breadcrumb
        pageName={t('AnnualInterviews.pageName')}
        addbtn={isAnnualinterviewAdmin ? true : false}
        addRouter="new"
      />
      {isAnnualInerviewsLoading && user?.id === null ? (
        <div className="flex items-center justify-center">
          <Spinner height="3rem" width="3rem" />
        </div>
      ) : (
        <div className="flex flex-col">
          <DynamicTable
            dataCount={annualInerviewsData?.count ?? 0}
            data={annualInerviewsData?.annualInterviews ?? []}
            columns={columns}
            setSearch={setSearch}
            exportPdf={false}
          />
          {isModalVisible && (
            <DeleteConfirmationModal
              isOpen={isModalVisible}
              onDelete={handleDeleteConfirmation}
              onCancel={handleCancelDelete}
            />
          )}
          <Pagination
            pageIndex={pageCount === 0 ? -1 : pageIndex}
            pageCount={pageCount}
            pageSize={pageSize}
            onNextPage={nextPage}
            onPreviousPage={previousPage}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
          />
        </div>
      )}
    </DefaultLayout>
  );
}

export default AnnualInterviews;
