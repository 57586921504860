import React, { useEffect, useState } from 'react';
import DefaultLayout from '@/layout/DefaultLayout';
import Breadcrumb from '@/components/Breadcrumb';
import { default as DefaultInput } from '@/components/Input';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook
import {
  ProjectExecutor,
  getProjectExecutor,
  saveProjectExecutor,
} from '@/services/ProjectExecutorService'; // Assuming this service exists
import Spinner from '@/components/Spinner';
import { useNavigate, useParams } from 'react-router-dom';
import { getCompanyId } from '@/services/AuthService';
import { toast } from 'react-hot-toast';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import FormButtons from '@/components/shared/FormButtons';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('ProjectExecutorForm.nameRequired'),
});

interface FormValues extends ProjectExecutor {
  name: string;
}

function ProjectExecutorForm() {
  const { id } = useParams();
  const editForm = id !== 'new';
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { t } = useTranslation(); // Initialize the useTranslation hook

  const [formikValuesState, setFormikValuesState] = useState<FormValues>({
    name: '',
    id: '',
  });
  const [loading, setLoading] = useState(false);

  const {
    data: projectExecutorData,
    isLoading,
    refetch: refetchProjectExecutor,
  } = useQuery<ProjectExecutor | undefined>(
    ['projectExecutor', id],
    async () => await getProjectExecutor(id!),
    {
      enabled: editForm,
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
      onSuccess: (projectExecutor) => {
        if (projectExecutor) {
          setFormikValuesState((prev) => ({ ...prev, ...projectExecutor }));
        }
      },
    }
  );

  useEffect(() => {
    if (editForm && projectExecutorData) {
      // Update form values with project executor data
      setFormikValuesState((prev) => ({ ...prev, ...projectExecutorData }));
    }
  }, [editForm, projectExecutorData]);

  const handleSaveProjectExecutor = async (
    values: FormValues,
    { resetForm, validateForm }: any
  ) => {
    setLoading(true);

    try {
      const companyId = getCompanyId();
      const projectExecutor: ProjectExecutor = {
        ...values,
        companyId: companyId!,
      };

      const data = await saveProjectExecutor(projectExecutor);
      if (data) {
        if (editForm) {
          toast.success(
            t('SuccessUpdateMessage', {
              name: t('ToastMsg.ProjectExecutor'),
            })
          );
        } else {
          toast.success(
            t('SuccessMessage', {
              name: t('ToastMsg.ProjectExecutor'),
            })
          );
        }
      }
      if (data && editForm) {
        refetchProjectExecutor();
      }
      queryClient.invalidateQueries(['projectExecutors']);
      navigate(-1);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
      resetForm();
    }
  };

  return (
    <DefaultLayout>
      <Breadcrumb
        pageName={
          editForm
            ? t('ProjectExecutorForm.editProjectExecutor')
            : t('ProjectExecutorForm.addProjectExecutor')
        }
      />
      {loading ? (
        <Spinner />
      ) : (
        <Formik
          initialValues={formikValuesState}
          validationSchema={validationSchema}
          onSubmit={handleSaveProjectExecutor}
          enableReinitialize={editForm}
        >
          {({ values, errors, handleChange, handleSubmit, resetForm }) => (
            <form onSubmit={handleSubmit}>
              <div className="group relative z-0 mb-6 w-full">
                <DefaultInput
                  type="text"
                  name="name"
                  id="name"
                  placeholder=" "
                  onChange={handleChange}
                  errors={errors}
                  value={values.name}
                  label={t('ProjectExecutorForm.nameLabel')}
                />
              </div>

              <FormButtons resetForm={resetForm} loading={loading} />
            </form>
          )}
        </Formik>
      )}
    </DefaultLayout>
  );
}

export default ProjectExecutorForm;
