import { useEffect, useMemo, useState } from 'react';
import DefaultLayout from '@/layout/DefaultLayout';
import Breadcrumb from '@/components/Breadcrumb';
import { useQueryClient, useQuery, useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import DynamicTable, { SelectColumnFilter } from '@/components/DynamicTable';
import Pagination from '@/components/Pagination';
import Spinner from '@/components/Spinner';
import { EditButton, PrimaryButton } from '@/components/shared/Button';
import { EyeIcon, PaperAirplaneIcon } from '@heroicons/react/solid';

import DeleteConfirmationModal from '@/components/shared/DeleteConfirmationModal';
import {
  getTrainingRequests,
  TrainingRequestResponse,
  getTrainingRequestsByEmployeeId,
  deleteTrainingRequest,
} from '@/services/TrainingRequestService';
import { useTranslation } from 'react-i18next';
import { TableState } from 'react-table';
import moment from 'moment';
import { UserRoles } from '@/enums/UsersRole';
import { useAuth } from '@/context/AuthContext';
import { TrainingRequestState } from '@/enums/TrainingRequestState';
import { stateColors } from '@/enums/RequestLeaveState';
import { saveTrainingSatisfactionForm } from '@/services/TrainingSatisfactionFormService';
import { UserResponsibility } from '@/enums/UserResponsibility';

function TrainingRequests() {
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState<string>('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRow, _] = useState('');
  const [trainignRequestsData, setTrainignRequestsData] =
    useState<TrainingRequestResponse>();
  const { user } = useAuth();

  const [isTRLoading, setIsTRLoading] = useState(false);

  const navigate = useNavigate();

  const isTrainingRequestAdmin =
    user?.responsibilityClaim ===
      UserResponsibility[UserResponsibility.GestionDesFormations].toString() ||
    user?.role === UserRoles.Admin;
  const { t } = useTranslation();
  const isTeamLeader = useMemo(() => user?.isTeamLeader == 'True', [user]);

  const ActionButtonsCell = ({
    value,
    state,
    teamLeaderId,
    trainingAdminId,
  }: any) => {
    const isTeamLeaderOrTrainingAdmin =
      teamLeaderId === user?.id || trainingAdminId === user?.id;

    const isOwnRequest =
      value === user?.id &&
      (user?.id === trainingAdminId || user?.isTeamLeader == 'True');

    const renderDetailButton = () => (
      // <PrimaryButton
      //   type="button"
      //   onClick={() => {
      //     navigate(`details/${value}`);
      //   }}
      // >
      //   {t('TrainingRequests.Details')}
      // </PrimaryButton>
      <EyeIcon
        onClick={() => navigate(`details/${value}`)}
        className="h-5 w-5 text-gray-600 dark:text-gray-300"
      />
    );

    const renderEditAndDeleteButtons = () => (
      <div>
        {/* <DeleteButton
          onClick={() => {
            setSelectedRow(value);
            setIsModalVisible(true);
          }}
        >
          {t('TrainingRequests.deleteButton')}
        </DeleteButton> */}

        <EditButton
          type="button"
          onClick={() => {
            navigate(`${value}`);
          }}
        >
          {t('TrainingRequests.editButton')}
        </EditButton>
      </div>
    );

    if (isTeamLeaderOrTrainingAdmin) {
      if (isOwnRequest) {
        return state === TrainingRequestState.Pending
          ? renderEditAndDeleteButtons()
          : renderDetailButton();
      } else {
        return renderDetailButton();
      }
    } else {
      return state === TrainingRequestState.Pending &&
        user?.role !== UserRoles.Admin
        ? renderEditAndDeleteButtons()
        : renderDetailButton();
    }
  };

  const handleCancelDelete = () => {
    setIsModalVisible(false);
  };

  const handleSendSatifactionForm = (id: string) => {
    handleSendTrainingSF.mutate(id);
  };

  const handleDeleteConfirmation = () => {
    handleDeleteTrainingRequest.mutate(selectedRow);
    setIsModalVisible(false);
  };
  const initialState: Partial<TableState<object>> | undefined = {
    hiddenColumns: isTrainingRequestAdmin ? ['employeeId'] : undefined,
  };

  const columns = useMemo(
    () => [
      {
        Header: t('TrainingRequests.actionsColumn'),
        accessor: 'id',
        Cell: ({ row, cell: { value } }: any) => (
          <ActionButtonsCell
            state={row.original['approvedByTeamLeader']}
            value={value}
            handleDelete={handleDeleteTrainingRequest}
            teamLeaderId={row.original['teamLeaderId']}
            trainingAdminId={row.original['trainingAdminId']}
          />
        ),
      },
      {
        Header: t('TrainingRequests.employeeFullName'),
        accessor: 'employeeFullName',
        Filter: SelectColumnFilter,
      },
      {
        Header: t('TrainingRequests.satisfactionFormSent'),
        accessor: 'satisfactionFormSent',
        Cell: ({ cell: { value } }: any) => {
          const frontendState = value ? 'bg-green-400' : 'bg-red-400';

          return (
            <div
              className={`w-50 rounded-md text-center ${frontendState} px-2.5  py-2.5 text-sm text-white`}
            >
              {value ? t('TrainingRequests.Yes') : t('TrainingRequests.No')}
            </div>
          );
        },
      },
      {
        Header: t('TrainingRequests.approvedByTeamLeader'),
        accessor: 'approvedByTeamLeader',
        Cell: ({ cell: { value } }: any) => {
          const frontendState = TrainingRequestState[value];
          //@ts-ignore
          const bgColor = stateColors[frontendState] || 'bg-gray-300';

          return (
            <div
              className={`w-50 rounded-md text-center ${bgColor} px-2.5  py-2.5 text-sm text-white`}
            >
              {frontendState !== null
                ? //@ts-ignore

                  t(`RequestLeaveState.${frontendState}`)
                : ''}
            </div>
          );
        },
      },
      {
        Header: t('TrainingRequests.approvedByTrainingAdmin'),
        accessor: 'approvedByTrainingAdmin',
        Cell: ({ cell: { value } }: any) => {
          const frontendState = TrainingRequestState[value];
          //@ts-ignore
          const bgColor = stateColors[frontendState] || 'bg-gray-300';

          return (
            <div
              className={`w-50 rounded-md text-center ${bgColor} px-2.5  py-2.5 text-sm text-white`}
            >
              {frontendState !== null
                ? //@ts-ignore
                  t(`RequestLeaveState.${frontendState}`)
                : ''}
            </div>
          );
        },
      },
      {
        Header: t('TrainingRequests.SatisfactionForm'),
        accessor: 'employeeId',
        show: isTrainingRequestAdmin,
        Cell: ({ row }: any) => {
          const apporvedByTL = row.original['approvedByTeamLeader'];
          const apporvedByTA = row.original['approvedByTrainingAdmin'];
          const trainingEndDate = row.original['trainingEndDate'];
          const isApporved = apporvedByTL && apporvedByTA;
          const isSent = row.original['satisfactionFormSent'];
          const trainigId = row.original['id'];

          const isCompleted =
            isApporved && new Date() > new Date(trainingEndDate);

          const btColor =
            isCompleted && !isSent
              ? 'bg-purple-700 dark:bg-purple-500'
              : 'bg-gray-400 dark:bg-gray-500';
          return (
            <PrimaryButton
              className={`${btColor} border-0 text-white dark:text-white 
                
              `}
              onClick={() => handleSendSatifactionForm(trainigId)}
              disabled={!isCompleted || !isTrainingRequestAdmin || isSent}
            >
              <PaperAirplaneIcon className="mr-2 h-5 w-5 rotate-90" />
              {isSent
                ? t('TrainingRequests.hasSent')
                : t('TrainingRequests.sendButton')}
            </PrimaryButton>
          );
        },
      },

      {
        Header: t('TrainingRequests.requestedTrainingTitleColumn'),
        accessor: 'requestedTrainingTitle',
      },
      {
        Header: t('TrainingRequests.trainingProviderName'),
        accessor: 'trainingProviderName',
        Filter: SelectColumnFilter,
        Cell: ({ cell: { value } }: any) => (
          <input
            type="text"
            className="min-w-40 dark:bg-boxdark-2 dark:text-bodydark"
            value={value}
            readOnly
          />
        ),
      },
      {
        Header: t('TrainingRequests.trainingManagerName'),
        accessor: 'trainingManagerName',
        Cell: ({ cell: { value } }: any) => (
          <input
            type="text"
            className="min-w-40 dark:bg-boxdark-2 dark:text-bodydark"
            value={value}
            readOnly
          />
        ),
      },
      {
        Header: t('TrainingRequests.trainingProviderPhoneNumber'),
        accessor: 'trainingProviderPhoneNumber',
        Cell: ({ cell: { value } }: any) => (
          <input
            type="text"
            className="min-w-50 w-40 dark:bg-boxdark-2 dark:text-bodydark"
            value={value}
            readOnly
          />
        ),
      },
      {
        Header: t('TrainingRequests.trainingProviderAddress'),
        accessor: 'trainingProviderAddress',
        Cell: ({ cell: { value } }: any) => (
          <input
            type="text"
            className="min-w-40 dark:bg-boxdark-2 dark:text-bodydark"
            value={value}
            readOnly
          />
        ),
      },
      {
        Header: t('TrainingRequests.trainingStartDate'),
        accessor: 'trainingStartDate',
        Cell: ({ cell: { value } }: any) => {
          const formattedValue = moment(value).format('YYYY-MM-DD'); // Customize the format as you need
          return (
            <input
              type="text"
              className="min-w-40 dark:bg-boxdark-2 dark:text-bodydark"
              value={formattedValue}
              readOnly
            />
          );
        },
      },
      {
        Header: t('TrainingRequests.trainingEndDate'),
        accessor: 'trainingEndDate',
        Cell: ({ cell: { value } }: any) => {
          const formattedValue = moment(value).format('YYYY-MM-DD'); // Customize the format as you need
          return (
            <input
              type="text"
              className="min-w-40 dark:bg-boxdark-2 dark:text-bodydark"
              value={formattedValue}
              readOnly
            />
          );
        },
      },
      {
        Header: t('TrainingRequests.remarks'),
        accessor: 'remarks',
      },
      {
        Header: t('TrainingRequests.obtainedDiplomaTitle'),
        accessor: 'obtainedDiplomaTitle',
        Cell: ({ cell: { value } }: any) => (
          <input
            type="text"
            className="min-w-40 dark:bg-boxdark-2 dark:text-bodydark"
            value={value}
            readOnly
          />
        ),
      },
    ],
    [t]
  );

  const queryClient = useQueryClient();

  const { data: trainingRequests } = useQuery<
    TrainingRequestResponse | undefined
  >(
    ['trainingRequests', pageIndex, pageSize, search],
    () => {
      if (isTeamLeader && !isTrainingRequestAdmin) {
        return getTrainingRequests({
          fromvalue: pageIndex,
          takevalue: pageSize,
          search: search,
          teamLeaderId: user?.id,
        });
      } else {
        return getTrainingRequests({
          fromvalue: pageIndex,
          takevalue: pageSize,
          search: search,
        });
      }
    },
    {
      onSuccess: (data) => {
        if (data) {
          setTrainignRequestsData(data);
        }
      },
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
      enabled:
        isTrainingRequestAdmin ||
        isTeamLeader ||
        user?.role === UserRoles.Admin,
    }
  );

  useQuery<TrainingRequestResponse | undefined>(
    ['UsertrainingRequests', pageIndex, pageSize, search],
    () =>
      getTrainingRequestsByEmployeeId({
        fromvalue: pageIndex,
        takevalue: pageSize,
        search: search,
        employeeId: user?.id,
      }),
    {
      onSuccess: (data) => {
        if (data) {
          setTrainignRequestsData(data);
        }
      },
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
      enabled:
        !isTrainingRequestAdmin &&
        user?.role === UserRoles.Employee &&
        !isTeamLeader,
    }
  );

  useEffect(() => {
    if (trainignRequestsData !== null && trainignRequestsData !== undefined) {
      setIsTRLoading(false);
    }
  }, [trainignRequestsData]);

  const handleDeleteTrainingRequest = useMutation(
    async (id: string) => {
      await deleteTrainingRequest(id);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          'trainingRequests',
          pageIndex,
          pageSize,
          search,
        ]);
        queryClient.invalidateQueries([
          'UsertrainingRequests',
          pageIndex,
          pageSize,
          search,
        ]);
      },
    }
  );
  const handleSendTrainingSF = useMutation(
    async (id: string) => {
      await saveTrainingSatisfactionForm(id);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          'trainingRequests',
          pageIndex,
          pageSize,
          search,
        ]);
        queryClient.invalidateQueries([
          'UsertrainingRequests',
          pageIndex,
          pageSize,
          search,
        ]);
      },
    }
  );
  const pageCount = useMemo(() => {
    return Math.ceil((trainingRequests?.count ?? 0) / pageSize);
  }, [pageSize, trainingRequests]);

  const nextPage = () => setPageIndex(pageIndex + 1);
  const previousPage = () => setPageIndex(pageIndex - 1);
  const onPageChange = (newPageIndex: number) => setPageIndex(newPageIndex);
  const onPageSizeChange = (newPageSize: number) => {
    setPageIndex(0);
    setPageSize(newPageSize);
  };

  return (
    <DefaultLayout>
      <Breadcrumb
        pageName={t('TrainingRequests.pageName')}
        addbtn={user?.role !== UserRoles.Admin ? true : false}
        addRouter="new"
      />
      {isTRLoading || user === null ? (
        <div className="flex items-center justify-center">
          <Spinner height="3rem" width="3rem" />
        </div>
      ) : (
        <div className="flex flex-col">
          <DynamicTable
            dataCount={trainignRequestsData?.count}
            data={trainignRequestsData?.trainingRequests ?? []}
            columns={columns}
            exportPdf={false}
            setSearch={setSearch}
            initialeStateColumn={initialState}
          />
          {isModalVisible && (
            <DeleteConfirmationModal
              isOpen={isModalVisible}
              onDelete={handleDeleteConfirmation}
              onCancel={handleCancelDelete}
            />
          )}
          <Pagination
            pageIndex={pageCount === 0 ? -1 : pageIndex}
            pageCount={pageCount}
            pageSize={pageSize}
            onNextPage={nextPage}
            onPreviousPage={previousPage}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
          />
        </div>
      )}
    </DefaultLayout>
  );
}

export default TrainingRequests;
