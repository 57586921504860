import DynamicTable, {
  EnumRequestLeaveStatesFilter,
} from '@/components/DynamicTable';
import Pagination from '@/components/Pagination';
import Spinner from '@/components/Spinner';
import { DeleteButton, EditButton } from '@/components/shared/Button';
import DeleteConfirmationModal from '@/components/shared/DeleteConfirmationModal';
import { useAuth } from '@/context/AuthContext';
import { RequestLeaveState, stateColors } from '@/enums/RequestLeaveState';
import {
  RequestLeave,
  cancelRequestLeave,
  getRequestLeavesByEmployee,
  validateDraftRequest,
} from '@/services/RequestLeavesService';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { useState, useMemo, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

const EmployeeRequestLeavesTable = () => {
  const { t } = useTranslation(); // Use the default namespace
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState<string>('');
  const [_, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState<RequestLeave | null>();

  const { user } = useAuth();
  const {
    data: requestLeavesData,
    isLoading: requestLeaveLoading,
    isSuccess,
    refetch: refetchREmployee,
  } = useQuery(
    ['EmployeeRequestLeaves', user?.id, pageIndex, pageSize, search],
    () =>
      getRequestLeavesByEmployee({
        fromvalue: pageIndex,
        takevalue: pageSize,
        search: search,
        employeeId: user?.id,
      }),
    {
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
    }
  );

  /* change states function  */

  const handleCancelRequest = async () => {
    setLoading(true);
    // const reqState: RequestLeaveStateChange = {
    //   requestLeaveId: selectedRow?.id!,
    //   state: RequestLeaveState.Canceled,
    // };
    const data = await cancelRequestLeave(selectedRow?.id!);
    if (data) {
      toast.success(
        t('SuccessMessageChangeRequestLeaveState', {
          name: t('RequestLeaveState.Rejected'),
        })
      );
    }
    refetchREmployee();
    setLoading(false);
    setIsModalVisible(false);
  };
  const handleSendRequestToValidation = async (requestLeave: any) => {
    setLoading(true);
    // const reqState: RequestLeaveStateChange = {
    //   requestLeaveId: selectedRow?.id!,
    //   state: RequestLeaveState.Pending,
    // };
    const data = await validateDraftRequest(requestLeave?.id!);
    if (data) {
      toast.success(
        t('SuccessMessageChangeRequestLeaveState', {
          name: t('AbsensePanel.SendforValidation'),
        })
      );
    }
    refetchREmployee();
    setLoading(false);
    setIsModalVisible(false);
  };

  /* !--change states function--!  */

  const ActionButtonsCell = ({ value }: any) => {
    const [requestLeave, setRequestLeave] = useState<RequestLeave>();

    useEffect(() => {
      if (isSuccess && requestLeavesData) {
        let res = requestLeavesData?.requestLeaves!.find(
          (r) => r.id === value
        )!;
        setRequestLeave(res);
      }
    }, [isSuccess, requestLeavesData, value, selectedRow]);

    return (
      requestLeave &&
      requestLeave.state! == RequestLeaveState.Draft.toString() && (
        <div>
          <DeleteButton
            onClick={() => {
              setSelectedRow(requestLeave);
              setIsModalVisible(true);
            }}
          >
            {t('AbsensePanel.Cancel')}
          </DeleteButton>

          {/* Edit button */}
          <EditButton
            type="button"
            onClick={() => handleSendRequestToValidation(requestLeave)}
          >
            {t('AbsensePanel.SendforValidation')}
          </EditButton>

          {/* <button onClick={() => handleEdit(row.original.id)}>Edit</button> */}
        </div>
      )
    );
  };

  const pageCount = useMemo(() => {
    return Math.ceil((requestLeavesData?.count ?? 0) / pageSize);
  }, [pageSize, requestLeavesData]);

  const nextPage = () => setPageIndex(pageIndex + 1);
  const previousPage = () => setPageIndex(pageIndex - 1);
  const onPageChange = (newPageIndex: number) => setPageIndex(newPageIndex);
  const onPageSizeChange = (newPageSize: number) => {
    setPageIndex(0);
    setPageSize(newPageSize);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Actions',
        accessor: 'id',
        Cell: ({ cell: { value } }: any) => <ActionButtonsCell value={value} />,
      },
      {
        Header: t('Requestleave.vacationName'),
        accessor: 'vacationName',
      },
      {
        Header: t('Requestleave.startColumn'),
        accessor: 'fromDate',
        Cell: ({ cell: { value } }: any) => {
          const formattedValue = moment(value).format('YYYY-MM-DD'); // Customize the format as you need
          return <div>{formattedValue}</div>;
        },
      },
      {
        Header: t('Requestleave.endColumn'),
        accessor: 'toDate',
        Cell: ({ cell: { value } }: any) => {
          const formattedValue = moment(value).format('YYYY-MM-DD'); // Customize the format as you need
          return <div>{formattedValue}</div>;
        },
      },
      {
        Header: t('transferRequestVacation.RequestedDays'),
        accessor: 'days',
      },
      {
        Header: t('Requestleave.notesColumn'),
        accessor: 'note',
      },
      {
        Header: t('Requestleave.statusColumn'),
        accessor: 'state',
        Filter: EnumRequestLeaveStatesFilter,

        Cell: ({ cell: { value } }: any) => {
          const frontendState = RequestLeaveState[value];
          //@ts-ignore
          const bgColor = stateColors[frontendState] || 'bg-gray-300';

          return (
            <div
              className={`   rounded-md text-center ${bgColor} px-2.5  py-2.5 text-sm text-white dark:text-boxdark-2`}
            >
              {frontendState !== null
                ? //@ts-ignore
                  t(`RequestLeaveState.${frontendState}`)
                : ''}
            </div>
          );
        },
      },
    ],
    [t, isSuccess, requestLeavesData]
  );
  return requestLeaveLoading ? (
    <Spinner />
  ) : (
    <div className="flex  flex-col">
      <DynamicTable
        dataCount={requestLeavesData?.count ?? 0}
        data={requestLeavesData?.requestLeaves ?? []}
        columns={columns}
        setSearch={setSearch}
        exportPdf={false}
      />
      {isModalVisible && (
        <DeleteConfirmationModal
          isOpen={isModalVisible}
          onDelete={handleCancelRequest}
          onCancel={() => setIsModalVisible(false)}
          textContent="Requestleave.CancelRequestMSG"
        />
      )}
      <Pagination
        pageIndex={pageCount === 0 ? -1 : pageIndex}
        pageCount={pageCount}
        pageSize={pageSize}
        onNextPage={nextPage}
        onPreviousPage={previousPage}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
      />
    </div>
  );
};

export default EmployeeRequestLeavesTable;
