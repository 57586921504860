import React, { SyntheticEvent, useEffect } from 'react';
import DefaultLayout from '@/layout/DefaultLayout';
import Breadcrumb from '@/components/Breadcrumb';
import Input from '@/components/Input';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Dropdown from '@/components/DropDown';
import DatePickerDefault from '@/components/DatePicker';
import { useParams } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import Spinner from '@/components/Spinner';
import Accordion from '@/components/Accordian';

import { useTranslation } from 'react-i18next';
import { useAuth } from '@/context/AuthContext';
import { RootState } from '@/state';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import {
  State,
  Country,
  CountriesResponse,
  getCountries,
} from '@/services/CountryService';
import { LabelButton } from '@/components/shared/Button';
import toast from 'react-hot-toast';
import { AsUTC } from '@/components/shared/Utils';
import {
  fetchProjectExpenseById,
  saveProjectExpenseAsync,
  updateProjectExpenseValues,
} from '@/state/slices/projectExpensesSlice';
import { ProjectExpenseModel } from '@/models/ProjectExpenseModel';
import { currencyOptions } from '@/enums/Currency';
import ExpensesTable from './components/ExpensesTable';
import { ProjectsResponse, getProjects } from '@/services/ProjectService';
import { formatCurrency } from '@/utils/helpers';
import { UserRoles, UserRolesEnum } from '@/enums/UsersRole';
import FormButtons from '@/components/shared/FormButtons';

const ProjectExpenseForm = () => {
  const { user } = useAuth();

  const { id } = useParams();
  const editForm = id !== 'new';
  const isDevelopment = import.meta.env.MODE === 'development';
  const { t } = useTranslation();
  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch();
  const { projectExpense, status } = useSelector(
    (state: RootState) => state.projectExpenses
  );
  const validationSchema = Yup.object().shape({
    missionStartDate: Yup.date().required(
      'projectExpenses.missionStartDateRequired'
    ),
    missionEndDate: Yup.date()
      .min(Yup.ref('missionStartDate'), 'projectExpenses.missionEndDateMin')
      .required('projectExpenses.missionEndDateRequired'),
    title: Yup.string().required('projectExpenses.titleRequired'),
    currency: Yup.string().required('projectExpenses.currencyRequired'),
    revenu: Yup.string().required('projectExpenses.revenuRequired'),
  });
  const queryClient = useQueryClient();

  const [states, setStates] = React.useState<State[]>([]);
  const [countries, setCountries] = React.useState<Country[]>([]);
  const handleSubmit = async () => {
    const newExpenses = projectExpense.expenseList?.map((exp) => {
      if (exp.id?.includes('newEx'))
        return {
          ...exp,
          id: null,
        };
      return exp;
    });

    // // convert Date to utc
    await dispatch(
      saveProjectExpenseAsync({
        ...projectExpense,
        missionStartDate: AsUTC(projectExpense.missionStartDate),
        missionEndDate: AsUTC(projectExpense.missionEndDate),
        expenseList: newExpenses ?? [],
        employeeId: user?.id!,
      })
    );
    if (status === 'succeeded') {
      toast.success('the expense has been saved ');
    }
  };

  const { data, isLoading: countryLoading } = useQuery<
    CountriesResponse | undefined
  >(['countries'], () => getCountries({ from: 0, take: 0 }), {
    onSuccess: (data) => {
      setCountries(data?.countries!);
    },
    refetchOnWindowFocus: false,
    staleTime: 5 * (60 * 1000), // 5 mins
    cacheTime: 10 * (60 * 1000),
    keepPreviousData: true,
  });
  const { data: projects, isLoading: projectsLoading } = useQuery<
    ProjectsResponse | undefined
  >(['projects'], async () => await getProjects({ from: 0, take: 0 }), {
    refetchOnWindowFocus: false,
    staleTime: 6000,
    keepPreviousData: true,
  });

  useEffect(() => {
    if (editForm && id) {
      //@ts-noCheck
      dispatch(fetchProjectExpenseById(id));
    }
  }, [dispatch, editForm, id]);

  const handleCountryChange = (event: any, handleChange: any) => {
    const selectedCountryName = event.target.value;

    const selectedCountryObj = data?.countries?.find(
      (country) => country.id === selectedCountryName
    );

    if (selectedCountryObj !== null && selectedCountryObj !== undefined) {
      setStates(selectedCountryObj.states!);
    } else {
      setStates([]);
    }
    handleChange({
      target: {
        name: 'countryId',
        value: selectedCountryObj!.id || {},
      },
    });
  };

  const handleChangeProps = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    values: any
  ) => {
    const { id, value } = e.target;
    //console.log('🚀 ~ ProjectExpenseForm ~ e.target:', e.target);

    // Construct a new object with updated values
    const newValues: ProjectExpenseModel = {
      ...values,
      [id]: value,
    };
    //console.warn('🚀 ~ ProjectExpenseForm ~ newValues:', newValues);

    // Dispatch action to update the Redux store
    dispatch(updateProjectExpenseValues({ projectExpense: newValues }));
  };

  const createSyntheticEvent = (name: string, value: any): SyntheticEvent => {
    return {
      // Simulate currentTarget
      currentTarget: {
        // Simulate name
        id: name,
        // Simulate value
        value,
      },
      // Simulate target
      target: {
        // Simulate name
        id: name,
        // Simulate value
        value,
      },
    } as unknown as SyntheticEvent;
  };
  return (
    <DefaultLayout>
      <Breadcrumb pageName={t('projectExpenses.breadcrumb')} />

      {status === 'loading' ||
      user == null ||
      countryLoading ||
      projectsLoading ? (
        <Spinner />
      ) : (
        <Formik
          initialValues={projectExpense}
          onSubmit={handleSubmit}
          enableReinitialize={true}
          validationSchema={validationSchema}
        >
          {({
            values,
            errors,
            handleChange,
            isValid,
            setFieldValue,
            resetForm,
          }) => (
            <form>
              <div>
                <Accordion
                  title={t('projectExpenses.generalInformation.title')}
                  isAlwaysOpen={true}
                >
                  <div className="flex flex-col gap-2 md:grid md:grid-cols-2 md:gap-2">
                    <Input
                      name="projectExpenseTitle"
                      id="projectExpenseTitle"
                      value={values?.projectExpenseTitle}
                      label={t('projectExpenses.title')}
                      labelDir="Above"
                      containerClass="w-full"
                      onChange={(e) => {
                        handleChangeProps(e, values);
                      }}
                      errors={errors?.projectExpenseTitle}
                    />

                    <div className="group relative  mb-6 w-full">
                      <Dropdown
                        labelDir="Above"
                        id="projectId"
                        name="projectId"
                        labelClassName="pl-0 w-[20%]"
                        label={t('projectExpenses.projectTitleId')}
                        onChange={(e) => {
                          handleChangeProps(e, values);
                        }}
                        optionLabel="title"
                        value={values.projectId}
                        error={errors.projectId}
                        optionValue="id"
                        className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                        options={projects?.projects ?? []}
                      />
                    </div>
                    <div className="group relative  mb-6 w-full">
                      <DatePickerDefault
                        labelDir="Above"
                        label={t('projectExpenses.missionStartDate')}
                        id="missionStartDate"
                        name="missionStartDate"
                        value={new Date(values?.missionStartDate)}
                        defaultDate={new Date(values?.missionStartDate)}
                        onChange={(date: any) => {
                          var e = createSyntheticEvent(
                            'missionStartDate',
                            date
                          );
                          handleChangeProps(e, values);
                        }}
                        errors={errors.missionStartDate}
                      />
                    </div>
                    <div className="group relative  mb-6 w-full">
                      <DatePickerDefault
                        label={t('projectExpenses.missionEndDate')}
                        id="missionEndDate"
                        name="missionEndDate"
                        value={new Date(values?.missionEndDate)}
                        defaultDate={new Date(values?.missionEndDate)}
                        onChange={(date: any) => {
                          // handleChange({
                          //   target: {
                          //     name: 'missionEndDate',
                          //     value: date,
                          //   },
                          // });
                          var e = createSyntheticEvent('missionEndDate', date);
                          handleChangeProps(e, values);
                        }}
                        errors={errors.missionEndDate}
                      />
                    </div>

                    <Dropdown
                      labelDir="Above"
                      id="projectExpenseCurrency"
                      name="projectExpenseCurrency"
                      label={t('projectExpenses.currency')}
                      onChange={(e) => {
                        handleChangeProps(e, values);
                      }}
                      value={values.projectExpenseCurrency!}
                      errors={errors.projectExpenseCurrency}
                      optionValue="id"
                      labelClassName="pl-0"
                      options={currencyOptions ?? []}
                    />

                    <Input
                      name="total"
                      id="total"
                      value={formatCurrency(
                        values?.total,
                        values.projectExpenseCurrency
                      )}
                      label={t('projectExpenses.total')}
                      labelDir="Above"
                      containerClass="w-full"
                      readOnly
                      errors={errors?.projectExpenseTitle}
                    />
                    <Input
                      name="revenu"
                      id="revenu"
                      value={values?.revenu}
                      label={t('projectExpenses.revenu')}
                      labelDir="Above"
                      containerClass="w-full"
                      onChange={(e) => {
                        handleChangeProps(e, values);
                      }}
                      errors={errors?.revenu}
                    />
                  </div>
                </Accordion>

                <Accordion title={t('projectExpenses.expenses')}>
                  <ExpensesTable expenses={values?.expenseList ?? []} />
                </Accordion>
              </div>
              <FormButtons resetForm={resetForm} loading={false} />
            </form>
          )}
        </Formik>
      )}
    </DefaultLayout>
  );
};

export default ProjectExpenseForm;
