import DynamicTable, { SelectColumnFilter } from '@/components/DynamicTable';
import Pagination from '@/components/Pagination';
import { useAuth } from '@/context/AuthContext';
import { getVacationBalances } from '@/services/VacationBalanceService';
import {
  getVacations,
  getVacationsByEmployeeId,
} from '@/services/VacationService';
import { useQuery } from '@tanstack/react-query';
import { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const VacationsBalancesTable = () => {
  const { t } = useTranslation(); // Use the default namespace
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState<string>('');

  const { user } = useAuth();
  const { data: vacationBalancesData, isLoading: vacationBalancesLoading } =
    useQuery(
      ['vacationsBalances', user?.id, pageIndex, pageSize, search],
      () =>
        getVacationsByEmployeeId({
          fromvalue: pageIndex,
          takevalue: pageSize,
          search: search,
          employeeId: user?.id!,
        }),
      {
        refetchOnWindowFocus: false,
        staleTime: 6000,
        keepPreviousData: true,
      }
    );

  const pageCount = useMemo(() => {
    return Math.ceil((vacationBalancesData?.count ?? 0) / pageSize);
  }, [pageSize, vacationBalancesData]);

  const nextPage = () => setPageIndex(pageIndex + 1);
  const previousPage = () => setPageIndex(pageIndex - 1);
  const onPageChange = (newPageIndex: number) => setPageIndex(newPageIndex);
  const onPageSizeChange = (newPageSize: number) => {
    setPageIndex(0);
    setPageSize(newPageSize);
  };

  const columns = useMemo(
    () => [
      {
        Header: t('vacationBalances.VacationName'),
        accessor: 'name',
        Filter: SelectColumnFilter,
      },
      {
        Header: t('vacationBalances.VacationTotal'),
        accessor: 'totalDays',
        Cell: ({ cell: { value, row } }: any) => {
          var isNotLimited = row.original['isNotLimited'];
          console.log(
            '🚀 ~ VacationsBalancesTable ~ isNotLimited:',
            isNotLimited
          );

          return (
            <span className="flex w-[100px] items-center justify-center text-center">
              {isNotLimited ? '--' : value}
            </span>
          );
        },
      },
      {
        Header: t('vacationBalances.TokedDays'),
        accessor: 'tokedDays',
        Cell: ({ row }) => {
          const totalDays = row.original.TotalDays || 0;
          const remainingDays = row.original.RemainingDays || 0;
          const tokedDays = totalDays - remainingDays;
          return <span>{tokedDays}</span>;
        },
      },
      {
        Header: t('vacationBalances.RemainingDays'),
        accessor: 'remainingDays',
      },
    ],
    [t]
  );
  return (
    <div className="flex flex-col">
      <DynamicTable
        dataCount={vacationBalancesData?.count ?? 0}
        data={vacationBalancesData?.vacations ?? []}
        columns={columns}
        setSearch={setSearch}
        exportPdf={false}
      />
      <Pagination
        pageIndex={pageCount === 0 ? -1 : pageIndex}
        pageCount={pageCount}
        pageSize={pageSize}
        onNextPage={nextPage}
        onPreviousPage={previousPage}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
      />
    </div>
  );
};
export default VacationsBalancesTable;
