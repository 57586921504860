import React, { useState, useEffect } from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { ArrowCircleLeftIcon } from '@heroicons/react/outline';
import { ArrowCircleRightIcon } from '@heroicons/react/solid';

interface EditorWysiwygProps {
  initialContent?: string;
  onChange: (htmlContent: string) => void;
  toolbarHidden?: boolean;
}

const EditorWysiwyg = ({
  initialContent = '',
  onChange,
  toolbarHidden = false,
}: EditorWysiwygProps) => {
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty()
  );

  useEffect(() => {
    if (initialContent) {
      const blocksFromHtml = htmlToDraft(initialContent);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      setEditorState(EditorState.createWithContent(contentState));
    }
  }, [initialContent]);

  const handleEditorChange = (state: EditorState) => {
    setEditorState(state);
    const htmlContent = draftToHtml(convertToRaw(state.getCurrentContent()));
    onChange(htmlContent);
  };

  const toolbarOptions = {
    options: ['colorPicker', 'fontFamily', 'inline', 'textAlign', 'history'],
    inline: {
      className: undefined,
    },
    textAlign: {
      className: undefined,
    },
    fontFamily: {
      className: undefined,
      dropdownClassName: undefined,
      default: 'Arial',
    },
    // history: {
    //   undo: { icon: ArrowCircleLeftIcon },
    //   redo: { icon: ArrowCircleRightIcon },
    // },
    history: {
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
      options: ['undo', 'redo'],
      undo: { icon: ArrowCircleLeftIcon, className: undefined },
      redo: { icon: ArrowCircleRightIcon, className: undefined },
    },
    colorPicker: {
      inDropdown: true,
      colors: [
        'rgb(97,189,109)',
        'rgb(26,188,156)',
        'rgb(84,172,210)',
        'rgb(44,130,201)',
        'rgb(147,101,184)',
        'rgb(71,85,119)',
        'rgb(204,204,204)',
        'rgb(65,168,95)',
        'rgb(0,168,133)',
        'rgb(61,142,185)',
        'rgb(41,105,176)',
        'rgb(85,57,130)',
        'rgb(40,50,78)',
        'rgb(0,0,0)',
        'rgb(247,218,100)',
        'rgb(251,160,38)',
        'rgb(235,107,86)',
        'rgb(226,80,65)',
        'rgb(163,143,132)',
        'rgb(239,239,239)',
        'rgb(255,255,255)',
        'rgb(250,197,28)',
        'rgb(243,121,52)',
        'rgb(209,72,65)',
        'rgb(184,49,47)',
        'rgb(124,112,107)',
        'rgb(209,213,216)',
      ],
    },
  };

  return (
    <Editor
      editorState={editorState}
      toolbarClassName="toolbarClassName"
      wrapperClassName="wrapperClassName"
      editorClassName="editorClassName"
      onEditorStateChange={handleEditorChange}
      toolbar={toolbarOptions}
      toolbarHidden={toolbarHidden}
      readOnly={toolbarHidden}
    />
  );
};

export default EditorWysiwyg;
