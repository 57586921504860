import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryClient, useQuery, useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import Breadcrumb from '@/components/Breadcrumb';
import DynamicTable, { SelectColumnFilter } from '@/components/DynamicTable';
import Pagination from '@/components/Pagination';
import Spinner from '@/components/Spinner';
import {
  DeleteButton,
  EditButton,
  PrimaryButton,
} from '@/components/shared/Button';
import DeleteConfirmationModal from '@/components/shared/DeleteConfirmationModal';
import DefaultLayout from '@/layout/DefaultLayout';
import moment from 'moment';

import {
  RequestLeaveStateChange,
  RequestLeavesResponse,
  changeRequestLeaveState,
  deleteRequestLeave,
  getRequestLeaves,
} from '@/services/RequestLeavesService';
import { RequestLeaveState, stateColors } from '@/enums/RequestLeaveState';

function RequestLeaves() {
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState('');
  const [selectedRow, setSelectedRow] = useState('');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [commentText, setCommentText] = useState('');

  const { t } = useTranslation();
  const handleCancelReject = () => {
    setIsModalVisible(false);
  };

  const handleRejectConfirmation = async () => {
    // !!!handle reject request
    setLoading(true);
    const reqState: RequestLeaveStateChange = {
      requestLeaveId: selectedRow,
      state: RequestLeaveState.Rejected,
      comment: commentText,
    };
    const data = await changeRequestLeaveState(reqState);
    setLoading(false);
    setIsModalVisible(false);
    refetch();
  };

  const handleApproveRequest = async (id: string) => {
    // !!!handle approve request
    setLoading(true);
    const reqState: RequestLeaveStateChange = {
      requestLeaveId: id,
      state: RequestLeaveState.Approved,
      comment: commentText,
    };
    const data = await changeRequestLeaveState(reqState);
    setLoading(false);
    setIsModalVisible(false);
    refetch();
  };
  const ActionButtonsCell = ({ value, row }: any) => {
    const state = row.original['state'];
    return state == RequestLeaveState.Pending.toString() ? (
      <div className="flex items-end justify-end">
        <EditButton onClick={() => handleApproveRequest(value)}>
          {t('Requestleave.Approve')}
        </EditButton>

        <DeleteButton
          type="button"
          onClick={() => {
            setSelectedRow(value);
            setIsModalVisible(true);
          }}
        >
          {t('Requestleave.Reject')}
        </DeleteButton>
      </div>
    ) : (
      <></>
    );
  };

  const handleCancelDelete = () => {
    setIsModalVisible(false);
  };

  const handleDeleteConfirmation = () => {
    handleDeleteLeaveRequest.mutate(selectedRow);
    setIsModalVisible(false);
  };

  const columns = useMemo(
    () => [
      {
        Header: t('RequestLeaves.actionsColumn'),
        accessor: 'id',
        Cell: ({ row, cell: { value } }: any) => (
          <ActionButtonsCell row={row} value={value} />
        ),
      },

      {
        Header: t('RequestLeaves.fullNameColumn'),
        accessor: 'employeeFullName',
        Filter: SelectColumnFilter,
      },
      // {
      //   Header: t('RequestLeaves.emailColumn'),
      //   accessor: 'email',
      // },
      {
        Header: t('Requestleave.vacationName'),
        accessor: 'vacationName',
      },

      {
        Header: t('Requestleave.startColumn'),
        accessor: 'fromDate',
        Cell: ({ cell: { value } }) => {
          const formattedValue = moment(value).format('YYYY-MM-DD'); // Customize the format as you need
          return <div>{formattedValue}</div>;
        },
      },
      {
        Header: t('Requestleave.endColumn'),
        accessor: 'toDate',
        Cell: ({ cell: { value } }) => {
          const formattedValue = moment(value).format('YYYY-MM-DD'); // Customize the format as you need
          return <div>{formattedValue}</div>;
        },
      },
      {
        Header: t('RequestLeaves.statusColumn'),
        accessor: 'state',
        Cell: ({ cell: { value } }) => {
          const frontendState = RequestLeaveState[value];
          const bgColor = stateColors[frontendState] || 'bg-gray-300';

          return (
            <div
              className={`rounded-md text-center ${bgColor} px-2.5  py-2.5 text-sm text-white`}
            >
              {frontendState !== null
                ? t(`RequestLeaveState.${frontendState}`)
                : ''}
            </div>
          );
        },
      },

      {
        Header: t('RequestLeaves.comment'),
        accessor: 'comment',
        Filter: SelectColumnFilter,
      },
    ],
    [t]
  );

  const queryClient = useQueryClient();

  const {
    isLoading,
    isError,
    data: leaveRequests,
    refetch,
  } = useQuery<RequestLeavesResponse | undefined>(
    ['leaveRequests', pageIndex, pageSize, search],
    () =>
      getRequestLeaves({
        fromvalue: pageIndex,
        takevalue: pageSize,
        search: search,
      }),
    {
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
    }
  );

  const handleDeleteLeaveRequest = useMutation(
    async (id: string) => {
      await deleteRequestLeave(id);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          'leaveRequests',
          pageIndex,
          pageSize,
          search,
        ]);
      },
    }
  );

  let pageCount = useMemo(() => {
    return Math.ceil((leaveRequests?.count ?? 0) / pageSize);
  }, [pageSize, leaveRequests]);

  const nextPage = () => setPageIndex(pageIndex + 1);
  const previousPage = () => setPageIndex(pageIndex - 1);
  const onPageChange = (newPageIndex: number) => setPageIndex(newPageIndex);
  const onPageSizeChange = (newPageSize: number) => {
    setPageIndex(0);
    setPageSize(newPageSize);
  };

  return (
    <div>
      {isLoading ? (
        <div className="flex items-center justify-center">
          <Spinner height="3rem" width="3rem" />
        </div>
      ) : (
        <div className="flex flex-col">
          <DynamicTable
            dataCount={leaveRequests?.count}
            data={leaveRequests?.requestLeaves ?? []}
            columns={columns}
            setSearch={setSearch}
            exportPdf={false}
          />
          {isModalVisible && (
            <DeleteConfirmationModal
              isOpen={isModalVisible}
              onDelete={handleRejectConfirmation}
              onCancel={handleCancelReject}
            />
          )}
          <Pagination
            pageIndex={pageCount === 0 ? -1 : pageIndex}
            pageCount={pageCount}
            pageSize={pageSize}
            onNextPage={nextPage}
            onPreviousPage={previousPage}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
          />
        </div>
      )}
    </div>
  );
}

export default RequestLeaves;
