import React, { useMemo, useState } from 'react';
import DefaultLayout from '@/layout/DefaultLayout';
import Breadcrumb from '@/components/Breadcrumb';
import { useQueryClient, useQuery, useMutation } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import DynamicTable from '@/components/DynamicTable';
import Pagination from '@/components/Pagination';
import Spinner from '@/components/Spinner';
import {
  DeleteButton,
  EditButton,
  PurpleButton,
} from '@/components/shared/Button';
import DeleteConfirmationModal from '@/components/shared/DeleteConfirmationModal';
import {
  OuvrageEntryResponse,
  deleteOuvrageEntry,
  getOuvrageEntriesByProjectId,
} from '@/services/OuvrageEntriesService';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { EyeIcon, UploadIcon } from '@heroicons/react/solid';
import { useAuth } from '@/context/AuthContext';
import { AssignedTask } from '@/enums/AssignedTask';
import { UserRoles } from '@/enums/UsersRole';

const userTimeZone = moment.tz.guess();

function OuvrageEntries() {
  const { id } = useParams();
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState<string>('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState('');
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { user } = useAuth();

  const isAllowed = useMemo(
    () =>
      user?.assignedTask == AssignedTask.Methods ||
      user?.role == UserRoles.Admin,
    [user]
  );

  const ActionButtonsCell = ({ value }: any) => {
    return (
      <div>
        <DeleteButton
          onClick={() => {
            setSelectedRow(value);
            setIsModalVisible(true);
          }}
        >
          {t('OuvrageEntries.deleteButton')}
        </DeleteButton>

        <EditButton
          type="button"
          onClick={() => {
            navigate(`${value}`);
          }}
        >
          {t('OuvrageEntries.editButton')}
        </EditButton>
      </div>
    );
  };

  const handleCancelDelete = () => {
    setIsModalVisible(false);
  };

  const handleDeleteConfirmation = () => {
    handleDeleteOuvrageEntry.mutate(selectedRow);
    setIsModalVisible(false);
  };
  const handlePreviewFile = (fileUrl: string) => {
    if (fileUrl) {
      window.open(fileUrl, '_blank');
    }
  };
  const columns = useMemo(
    () => [
      {
        Header: t('OuvrageEntries.actionsColumn'),
        accessor: 'id',
        Cell: ({ cell: { value } }: any) => (
          <ActionButtonsCell
            value={value}
            handleDelete={handleDeleteOuvrageEntry}
          />
        ),
      },
      {
        Header: t('OuvrageEntries.idRefColumn'),
        accessor: 'iD_REF',
      },
      {
        Header: t('OuvrageEntries.ouvrageColumn'),
        accessor: 'ouvrage',
      },
      {
        Header: t('OuvrageEntries.zoneColumn'),
        accessor: 'zone',
      },
      {
        Header: t('OuvrageEntries.urlColumn'),
        accessor: 'url',
        Cell: ({ cell: { value } }: any) => {
          return (
            <button
              type="button"
              onClick={(e) => handlePreviewFile(value)}
              className="mb-2 flex items-center text-blue-500 hover:text-blue-700"
            >
              <EyeIcon className="mr-1 h-6 w-6" />
            </button>
          );
        },
      },
      {
        Header: t('OuvrageEntries.altColumn'),
        accessor: 'alt',
      },
      {
        Header: t('OuvrageEntries.commentairesColumn'),
        accessor: 'commentaires',
      },
    ],
    [t]
  );

  const queryClient = useQueryClient();

  const {
    isLoading,
    isError,
    data: ouvrageEntries,
  } = useQuery<OuvrageEntryResponse | undefined>(
    ['ouvrageEntries', pageIndex, pageSize, search],
    () =>
      getOuvrageEntriesByProjectId({
        projectId: id!,
        page: pageIndex,
        take: pageSize,
        search: search,
      }),
    {
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
      enabled: id !== null,
    }
  );

  const handleDeleteOuvrageEntry = useMutation(
    async (id: string) => {
      await deleteOuvrageEntry(id);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          'ouvrageEntries',
          pageIndex,
          pageSize,
          search,
        ]);
      },
    }
  );

  const pageCount = useMemo(() => {
    return Math.ceil((ouvrageEntries?.count ?? 0) / pageSize);
  }, [pageSize, ouvrageEntries]);

  const nextPage = () => setPageIndex(pageIndex + 1);
  const previousPage = () => setPageIndex(pageIndex - 1);
  const onPageChange = (newPageIndex: number) => setPageIndex(newPageIndex);
  const onPageSizeChange = (newPageSize: number) => {
    setPageIndex(0);
    setPageSize(newPageSize);
  };

  return (
    <DefaultLayout>
      <Breadcrumb
        pageName={t('OuvrageEntries.pageName')}
        addbtn={isAllowed}
        addRouter="new"
      />
      {isLoading ? (
        <div className="flex items-center justify-center">
          <Spinner height="3rem" width="3rem" />
        </div>
      ) : (
        <div className="flex flex-col">
          <DynamicTable
            dataCount={ouvrageEntries?.count}
            data={ouvrageEntries?.ouvrageEntries ?? []}
            columns={columns}
            setSearch={setSearch}
            exportPdf={false}
          />
          {isModalVisible && (
            <DeleteConfirmationModal
              isOpen={isModalVisible}
              onDelete={handleDeleteConfirmation}
              onCancel={handleCancelDelete}
            />
          )}
          <Pagination
            pageIndex={pageCount === 0 ? -1 : pageIndex}
            pageCount={pageCount}
            pageSize={pageSize}
            onNextPage={nextPage}
            onPreviousPage={previousPage}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
          />
        </div>
      )}
    </DefaultLayout>
  );
}

export default OuvrageEntries;
