import React, { useEffect, useState } from 'react';
import DefaultLayout from '@/layout/DefaultLayout';
import Breadcrumb from '@/components/Breadcrumb';
import { default as DefaultInput } from '@/components/Input';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Spinner from '@/components/Spinner';
import { toast } from 'react-hot-toast';
import { useQuery } from '@tanstack/react-query';
import {
  ProjectMeeting,
  getProjectMeeting,
  saveProjectMeeting,
} from '@/services/ProjectMeetingsService';
import { EmployeesResponse, getEmployees } from '@/services/EmployeeService';
import Dropdown from '@/components/DropDown';
import { MeetingType, MeetingTypeOptions } from '@/enums/MeetingType';
import ReactDatePickerDefault from '@/components/ReactDatePickerDefault';
import Datepicker from 'react-datepicker';
import ReactTimePickerDefault from '@/components/ReactTimePickerDefault';
import MultiSelect from '@/components/MultiSelect';
import moment from 'moment';
import FormButtons from '@/components/shared/FormButtons';

const validationSchema = Yup.object().shape({
  date: Yup.date().required('ProjectMeetingForm.dateRequired'),
  startTime: Yup.string().required('ProjectMeetingForm.startTimeRequired'),
  endTime: Yup.string().required('ProjectMeetingForm.endTimeRequired'),
  meetingType: Yup.string().required('ProjectMeetingForm.meetingTypeRequired'),
  objectives: Yup.string().required('ProjectMeetingForm.objectivesRequired'),
  agenda: Yup.string().required('ProjectMeetingForm.agendaRequired'),
  topics: Yup.string().required('ProjectMeetingForm.topicsRequired'),
  nextMeetingDate: Yup.date().required(
    'ProjectMeetingForm.nextMeetingDateRequired'
  ),
  nextMeetingTime: Yup.string().required(
    'ProjectMeetingForm.nextMeetingTimeRequired'
  ),
  organizerId: Yup.string().required('ProjectMeetingForm.organizerRequired'),
  participantIds: Yup.array()
    .min(1, 'ProjectMeetingForm.participantRequired')
    .required('ProjectMeetingForm.participantRequired'),
});
const userTimeZone = moment.tz.guess();

interface FormValues extends ProjectMeeting {}

function ProjectMeetingForm() {
  const { meetingId, id } = useParams();
  const editForm = meetingId !== 'new';
  const [startDate, setStartDate] = useState(new Date());
  const [selectedParticipantsIds, setSelectedParticipantsIds] = useState<
    string[]
  >([]);

  const navigate = useNavigate();

  const { t } = useTranslation();

  const [formikValuesState, setFormikValuesState] = useState<FormValues>({
    date: new Date(),
    startTime: new Date(),
    endTime: new Date(),
    meetingType: MeetingType.Internal,
    objectives: '',
    agenda: '',
    topics: '',
    nextMeetingDate: new Date(),
    participantIds: [],
    organizerId: '',
    projectId: id!,
    id: '',
  });
  const [loading, setLoading] = useState(false);

  const {
    data: projectMeetingData,
    isLoading,
    refetch: refetchProjectMeeting,
  } = useQuery<ProjectMeeting | undefined>(
    ['projectMeeting', meetingId],
    async () => await getProjectMeeting(meetingId!),
    {
      enabled: editForm,
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
      onSuccess: (projectMeeting) => {
        if (projectMeeting) {
          setFormikValuesState((prev) => ({ ...prev, ...projectMeeting }));
        }
      },
    }
  );

  useEffect(() => {
    if (editForm && projectMeetingData) {
      setFormikValuesState((prev) => ({
        ...prev,
        ...projectMeetingData,

        startTime: moment
          .utc(projectMeetingData.startTime)
          .tz(userTimeZone)
          .toDate(),
        endTime: moment
          .utc(projectMeetingData.endTime)
          .tz(userTimeZone)
          .toDate(),
        nextMeetingTime: moment
          .utc(projectMeetingData.nextMeetingTime)
          .tz(userTimeZone)
          .toDate(),
      }));
    }
  }, [editForm, projectMeetingData]);

  const handleSaveProjectMeeting = async (
    values: FormValues,
    { resetForm, validateForm }: any
  ) => {
    setLoading(true);

    try {
      const projectMeeting: ProjectMeeting = {
        ...values,
      };

      const data = await saveProjectMeeting(projectMeeting);
      if (data) {
        if (editForm) {
          toast.success(
            t('SuccessUpdateMessage', {
              name: t('ToastMsg.ProjectMeeting'),
            })
          );
        } else {
          toast.success(
            t('SuccessMessage', {
              name: t('ToastMsg.ProjectMeeting'),
            })
          );
        }
      }
      if (data && editForm) {
        refetchProjectMeeting();
      }
      navigate(-1);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
      resetForm();
    }
  };

  const { data: employeesData, isLoading: isLoadingEmployees } = useQuery<
    EmployeesResponse | undefined
  >(
    ['employees'],
    async () =>
      await getEmployees({
        fromvalue: 0,
        takevalue: 0,
        search: '',
      }),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  return (
    <DefaultLayout>
      <Breadcrumb
        pageName={
          editForm
            ? t('ProjectMeetingForm.editProjectMeeting')
            : t('ProjectMeetingForm.addProjectMeeting')
        }
      />
      {loading && isLoadingEmployees ? (
        <Spinner />
      ) : (
        <Formik
          initialValues={formikValuesState}
          validationSchema={validationSchema}
          onSubmit={handleSaveProjectMeeting}
          enableReinitialize={editForm}
        >
          {({
            values,
            errors,
            setFieldValue,
            handleChange,
            handleSubmit,
            resetForm,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className=" grid gap-2 md:grid-cols-3">
                <div className="group relative  mb-6 w-full">
                  <ReactDatePickerDefault
                    name="date"
                    id="date"
                    labelClassName="w-[40%]"
                    label={t('ProjectMeetingForm.dateLabel')}
                    value={values.date ?? new Date()}
                    onChange={(date: Date) => {
                      handleChange({
                        target: {
                          name: 'date',
                          value: date,
                        },
                      });
                    }}
                    errors={errors['date']}
                  />
                </div>
                <div className="group relative  mb-6 w-full">
                  <ReactTimePickerDefault
                    name="startTime"
                    id="startTime"
                    labelClassName="w-[40%]"
                    label={t('ProjectMeetingForm.startTimeLabel')}
                    value={values.startTime ?? new Date()}
                    onChange={(startTime: Date) => {
                      handleChange({
                        target: {
                          name: 'startTime',
                          value: startTime,
                        },
                      });
                    }}
                    errors={errors['startTime']}
                  />
                </div>
                <div className="group relative  mb-6 w-full">
                  <ReactTimePickerDefault
                    name="endTime"
                    id="endTime"
                    labelClassName="w-[40%]"
                    label={t('ProjectMeetingForm.endTimeLabel')}
                    value={values.endTime ?? new Date()}
                    onChange={(endTime: Date) => {
                      handleChange({
                        target: {
                          name: 'endTime',
                          value: endTime,
                        },
                      });
                    }}
                    errors={errors['endTime']}
                  />
                </div>
              </div>
              <div className=" grid gap-2 md:grid-cols-3">
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="text"
                    name="topics"
                    id="topics"
                    placeholder=" "
                    onChange={handleChange}
                    errors={errors}
                    value={values.topics}
                    label={t('ProjectMeetingForm.topicsLabel')}
                  />
                </div>
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="text"
                    name="objectives"
                    id="objectives"
                    placeholder=" "
                    onChange={handleChange}
                    errors={errors}
                    value={values.objectives}
                    label={t('ProjectMeetingForm.objectivesLabel')}
                  />
                </div>
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="text"
                    name="agenda"
                    id="agenda"
                    placeholder=" "
                    onChange={handleChange}
                    errors={errors}
                    value={values.agenda}
                    label={t('ProjectMeetingForm.agendaLabel')}
                  />
                </div>
              </div>
              <div className=" grid gap-2 md:grid-cols-3">
                <div className="group relative z-0 mb-6 w-full">
                  <Dropdown
                    name="meetingType"
                    id="meetingType"
                    label={t('ProjectMeetingForm.meetingTypeLabel')}
                    value={values.meetingType}
                    translatedNameSpace="MeetingType"
                    isTranslated={true}
                    optionTransalatedKey="name"
                    optionValue="id"
                    onChange={handleChange}
                    labelClassName="pl-0 w-[50%]"
                    error={errors.meetingType}
                    options={MeetingTypeOptions ?? []}
                  />
                </div>
                <div className="group relative z-0 mb-6 w-full">
                  <ReactDatePickerDefault
                    name="nextMeetingDate"
                    id="nextMeetingDate"
                    labelClassName=" w-[60%]"
                    label={t('ProjectMeetingForm.nextMeetingDateLabel')}
                    value={values.nextMeetingDate ?? new Date()}
                    onChange={(nextMeetingDate: Date) => {
                      handleChange({
                        target: {
                          name: 'nextMeetingDate',
                          value: nextMeetingDate,
                        },
                      });
                    }}
                    errors={errors['date']}
                  />
                </div>
                <div className="group relative z-0 mb-6 w-full">
                  <ReactTimePickerDefault
                    name="nextMeetingTime"
                    id="nextMeetingTime"
                    labelClassName=" w-[60%]"
                    label={t('ProjectMeetingForm.nextMeetingTimeLabel')}
                    value={values.nextMeetingTime ?? new Date()}
                    onChange={(nextMeetingTime: Date) => {
                      handleChange({
                        target: {
                          name: 'nextMeetingTime',
                          value: nextMeetingTime,
                        },
                      });
                    }}
                    errors={errors['nextMeetingTime']}
                  />
                </div>
              </div>
              <div className=" grid gap-2 md:grid-cols-2">
                <div className="group relative z-0 mb-6 w-full">
                  <Dropdown
                    id="organizerId"
                    name="organizerId"
                    optionValue="id"
                    optionLabel="email"
                    onChange={handleChange}
                    options={employeesData?.employees ?? []}
                    label={t('ProjectMeetingForm.organizerLabel')}
                    labelClassName="w-[50%]"
                    error={errors.organizerId}
                    value={values.organizerId}
                  />
                </div>
                <div className="group relative  mb-6 w-full">
                  <MultiSelect
                    id="participantIds"
                    name="participantIds"
                    optionValue="id"
                    optionLabel="email"
                    label={t('ProjectMeetingForm.participantLabel')}
                    options={employeesData?.employees ?? []}
                    selectedValue={values.participantIds}
                    onChange={(selectedOptions) => {
                      setFieldValue('participantIds', selectedOptions);
                    }}
                  />
                </div>
              </div>

              <FormButtons resetForm={resetForm} loading={loading} />
            </form>
          )}
        </Formik>
      )}
    </DefaultLayout>
  );
}

export default ProjectMeetingForm;
