import React, { ChangeEvent } from 'react';

interface Checkbox {
  id: string | number;
  label: string;
}

interface CheckboxGroupProps {
  title: string;
  checkboxes: Checkbox[];
  selectedCheckboxes: any[];
  onChange: (selectedCheckboxes: string[]) => void;
}

const CheckboxGroup: React.FC<CheckboxGroupProps> = ({
  title,
  checkboxes,
  selectedCheckboxes,
  onChange,
}) => {
  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const checkboxId = e.target.id;
    const isChecked = e.target.checked;

    if (isChecked) {
      onChange([...selectedCheckboxes, checkboxId]);
    } else {
      onChange(selectedCheckboxes.filter((id) => id !== checkboxId));
    }
  };

  return (
    <div>
      <h3 className="mb-4 font-semibold text-gray-900 dark:text-white">
        {title}
      </h3>
      <div className="max-h-40 overflow-y-auto">
        {' '}
        <ul className="w-full items-center rounded-lg border border-gray-200 bg-white text-sm font-medium text-gray-900 dark:border-gray-600 dark:bg-gray-700 dark:text-white sm:flex sm:flex-wrap sm:divide-x">
          {checkboxes.map((checkbox) => (
            <li
              key={checkbox.id.toString()}
              className="w-full border-b border-gray-200 dark:border-gray-600 sm:w-1/2 sm:border-b-0 sm:border-r md:w-1/3 lg:w-1/4"
            >
              <div className="flex items-center pl-3">
                <input
                  id={checkbox.id.toString()}
                  type="checkbox"
                  value=""
                  className="relative h-4 w-4 appearance-none rounded border-gray-300 bg-gray-100 checked:border-transparent checked:bg-primary-500 checked:ring-2 checked:ring-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:border-gray-500 dark:bg-gray-600 dark:ring-offset-gray-700 dark:focus:ring-blue-600 dark:focus:ring-offset-gray-700"
                  checked={selectedCheckboxes.includes(checkbox.id.toString())}
                  onChange={handleCheckboxChange}
                />

                <label
                  htmlFor={checkbox.id.toString()}
                  className="ml-2 w-full py-3 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  {checkbox.label}
                </label>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default CheckboxGroup;
