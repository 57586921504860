import React, { useEffect, useMemo, useState } from 'react';
import DefaultLayout from '@/layout/DefaultLayout';
import Breadcrumb from '@/components/Breadcrumb';
import { default as DefaultInput } from '@/components/Input';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Spinner from '@/components/Spinner';
import { toast } from 'react-hot-toast';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  saveOuvrageEntry,
  getOuvrageEntry,
  OuvrageEntry,
} from '@/services/OuvrageEntriesService';
import Dropdown from '@/components/DropDown';
import moment from 'moment';
import ReactDatePickerDefault from '@/components/ReactDatePickerDefault';
import TextArea from '@/components/TextArea';
import FilesUploader from '@/components/FilesUploader';
import FormButtons from '@/components/shared/FormButtons';

const validationSchema = Yup.object().shape({
  iD_REF: Yup.string().required('OuvrageEntryForm.ID_REFRequired'),
  ouvrage: Yup.string().required('OuvrageEntryForm.OuvrageRequired'),
  zone: Yup.string().required('OuvrageEntryForm.ZoneRequired'),
  url: Yup.string().required('OuvrageEntryForm.UrlRequired'),
  //   alt: Yup.string().required('OuvrageEntryForm.AltRequired'),
  //commentaires: Yup.string().required('OuvrageEntryForm.CommentairesRequired'),
});

function OuvrageEntryForm() {
  const { entryId, id } = useParams();
  const editForm = entryId !== 'new';
  const navigate = useNavigate();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [initialValues, setInitialValues] = useState<OuvrageEntry>({
    id: '',
    iD_REF: '',
    ouvrage: '',
    zone: '',
    url: '',
    alt: '',
    commentaires: '',
    projectId: id!,
  });
  const [loading, setLoading] = useState(false);

  const {
    data: ouvrageEntryData,
    isLoading,
    refetch: refetchOuvrageEntry,
  } = useQuery<OuvrageEntry | undefined>(
    ['ouvrageEntry', entryId],
    async () => {
      const response = await getOuvrageEntry(entryId!);
      return response;
    },
    {
      enabled: editForm,

      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    if (editForm && ouvrageEntryData) {
      setInitialValues((prev) => ({
        ...prev,
        ...ouvrageEntryData,
      }));
    }
  }, [editForm, ouvrageEntryData]);

  const handleSaveOuvrageEntry = async (
    values: OuvrageEntry,
    { resetForm }: any
  ) => {
    try {
      setLoading(true);
      const data = await saveOuvrageEntry(values);
      //   toast.success(
      //     t('SuccessMessage', {
      //       name: t('ToastMsg.OuvrageEntry'),
      //     })
      //   );
      if (data) {
        if (editForm) {
          toast.success(
            t('SuccessUpdateMessage', {
              name: t('ToastMsg.OuvrageEntry'),
            })
          );

          queryClient.invalidateQueries(['ouvrageEntries']);
        } else {
          toast.success(
            t('SuccessMessage', {
              name: t('ToastMsg.OuvrageEntry'),
            })
          );
        }
      }
      if (data && editForm) {
        refetchOuvrageEntry();
      }
      navigate(-1);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <DefaultLayout>
      <Breadcrumb
        pageName={
          editForm
            ? t('OuvrageEntryForm.editOuvrageEntry')
            : t('OuvrageEntryForm.addOuvrageEntry')
        }
      />
      {loading ? (
        <Spinner />
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSaveOuvrageEntry}
          enableReinitialize={true}
        >
          {({ values, errors, handleChange, handleSubmit, resetForm }) => (
            console.log('🚀 ~ OuvrageEntryForm ~ errors:', errors),
            (
              <form onSubmit={handleSubmit}>
                <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
                  <div className="mb-4">
                    <DefaultInput
                      type="text"
                      name="iD_REF"
                      id="iD_REF"
                      label={t('OuvrageEntryForm.ID_REFLabel')}
                      value={values.iD_REF}
                      onChange={handleChange}
                      error={errors.iD_REF}
                    />
                  </div>
                  <div className="mb-4">
                    <DefaultInput
                      type="text"
                      name="ouvrage"
                      id="ouvrage"
                      label={t('OuvrageEntryForm.OuvrageLabel')}
                      value={values.ouvrage}
                      onChange={handleChange}
                      error={errors.ouvrage}
                    />
                  </div>
                  <div className="mb-4">
                    <DefaultInput
                      type="text"
                      name="zone"
                      id="zone"
                      label={t('OuvrageEntryForm.ZoneLabel')}
                      value={values.zone}
                      onChange={handleChange}
                      error={errors.zone}
                    />
                  </div>

                  <div className="mb-4">
                    <DefaultInput
                      type="text"
                      name="alt"
                      id="alt"
                      label={t('OuvrageEntryForm.AltLabel')}
                      value={values.alt}
                      onChange={handleChange}
                      error={errors.alt}
                    />
                  </div>
                  <div className="mb-4">
                    <TextArea
                      name="commentaires"
                      id="commentaires"
                      label={t('OuvrageEntryForm.CommentairesLabel')}
                      value={values.commentaires}
                      onChange={handleChange}
                      error={errors.commentaires}
                    />
                  </div>
                </div>
                <div className="group relative z-0 mb-6 w-full">
                  {/* <DefaultInput
                    type="text"
                    name="url"
                    id="url"
                    label={t('OuvrageEntryForm.UrlLabel')}
                    value={values.url}
                    onChange={handleChange}
                    error={errors.url}
                  /> */}

                  <FilesUploader
                    id="url"
                    name="url"
                    fileUrl={values.url}
                    onChange={(url) => {
                      handleChange({
                        target: {
                          name: 'url',
                          value: url,
                        },
                      });
                    }}
                    folderPathName={[
                      'OuvrageEntries',
                      //  values.projectTitle.replace(/\s+/g, '') ?? '',
                    ]}
                  />
                </div>
                <FormButtons resetForm={resetForm} loading={loading} />
              </form>
            )
          )}
        </Formik>
      )}
    </DefaultLayout>
  );
}

export default OuvrageEntryForm;
