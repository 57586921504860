import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';

type UIContextType = {
  shrinkSideBar: boolean;
  setShrinkSideBar?: Dispatch<SetStateAction<boolean>>;
};

const UIContext = createContext<UIContextType>({
  shrinkSideBar: false,
});

export const useUI = () => useContext(UIContext);

export const UIProvider = ({ children }: any) => {
  const [shrinkSideBar, setShrinkSideBar] = useState<boolean>(() => {
    // Initialize state from localStorage, defaulting to false if not found
    return localStorage.getItem('shrinkSideBar') === 'true';
  });

  // Update localStorage whenever shrinkSideBar changes
  useEffect(() => {
    localStorage.setItem('shrinkSideBar', String(shrinkSideBar));
  }, [shrinkSideBar]);

  return (
    <UIContext.Provider value={{ shrinkSideBar, setShrinkSideBar }}>
      {children}
    </UIContext.Provider>
  );
};
